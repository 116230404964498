define("sidekick-portal/components/datetime-picker/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "R7+dnM8S",
    "block": "{\"symbols\":[],\"statements\":[[5,\"ember-flatpickr\",[],[[\"@date\",\"@enableTime\",\"@time_24hr\",\"@onChange\",\"@placeholder\"],[[22,\"dateNative\"],true,true,[28,\"action\",[[23,0,[]],[24,[\"dateChanged\"]]],null],[22,\"placeholder\"]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sidekick-portal/components/datetime-picker/template.hbs"
    }
  });

  _exports.default = _default;
});