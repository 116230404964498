define("sidekick-portal/components/cms/program-catalog-item/component", ["exports", "sidekick-portal/models/constants/cms", "sidekick-portal/models/constants/countries"], function (_exports, _cms, _countries) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  //TODO: Add this locale import and remove availableLocales from constants/cms once CMS support is ready:
  //import { availableLocales } from 'sidekick-portal/models/constants/locales';
  var _default = Ember.Component.extend({
    flashMessages: Ember.inject.service(),
    backend: Ember.inject.service(),
    categories: _cms.categories,
    locales: _cms.availableLocales,
    countries: _countries.availableCountries,
    selectedLocale: Ember._tracked(),
    getUploadedImage: function getUploadedImage(fileValueName, metadata) {
      var _this = this;

      this.backend.getJSONData("admin/contentitems/".concat(this.item.id, "/locales/").concat(this.locale.locale, "/values/").concat(fileValueName, "/asseturl")).then(function (response) {
        var value = _this.locale.contentItemValues.find(function (v) {
          return v.name === fileValueName;
        });

        Ember.set(value, 'value', response.assetUrl);
        Ember.set(value, 'originalFilename', metadata.originalFilename);
      });
    },
    uploadFile: function uploadFile(file, uploadUrl) {
      var _this2 = this;

      if (!Ember.isBlank(uploadUrl)) {
        var options = {
          body: file,
          headers: {
            'Content-Type': file.type
          },
          method: 'PUT'
        };
        return new Promise(function (resolve, reject) {
          fetch(uploadUrl, options).then(function (response) {
            if (response.status === 200) {
              _this2.flashMessages.success('File uploaded successfully.');

              resolve(response);
            } else {
              _this2.flashMessages.danger('Error uploading file. Try refreshing the page.');

              reject(response);
            }
          });
        });
      }
    },
    localesFiltered: Ember.computed('locale', function () {
      var _this3 = this;

      var filteredLocales = _cms.availableLocales.filter(function (l) {
        return l.id !== _this3.locale.locale;
      });

      return filteredLocales;
    }),
    localeHasValue: function localeHasValue(locale) {
      return locale.contentItemValues.any(function (civ) {
        return !Ember.isEmpty(civ.value);
      });
    },
    actions: {
      fileSelected: function fileSelected(file, fileValueName, metadata) {
        var _this4 = this;

        return this.prepareUploadUrl(this.item.id, this.locale.locale, fileValueName, metadata).then(function (response) {
          return _this4.uploadFile(file, response.uploadUrl).then(function () {
            _this4.getUploadedImage(fileValueName, metadata);
          });
        });
      },
      toggleLocaleStatus: function toggleLocaleStatus() {
        this.locale.toggleStatus();
      },
      copyValueFromEnglish: function copyValueFromEnglish(contentItemValue) {
        var enLocale = this.item.contentItemLocales.find(function (x) {
          return x.locale === 'en';
        });

        if (!Ember.isBlank(enLocale)) {
          //TODO: Improve this function to work more dynamically
          var sourceValue = enLocale.contentItemValues.find(function (x) {
            return x.name === contentItemValue.name;
          });
          contentItemValue.value = sourceValue.value;
          contentItemValue.height = sourceValue.height;
          contentItemValue.width = sourceValue.width;
          contentItemValue.duration = sourceValue.duration;
          contentItemValue.originalFilename = sourceValue.originalFilename;
        }
      },
      copyFromLocale: function copyFromLocale(sourceLocaleString) {
        var _this5 = this;

        var source = this.item.contentItemLocales.find(function (x) {
          return x.locale === sourceLocaleString;
        });

        if (!Ember.isBlank(source) && this.localeHasValue(source)) {
          if (source.locale === this.locale.locale) {
            this.flashMessages.danger('Cannot copy from same locale (' + source.locale + ' -> ' + this.locale.locale + ')');
          } else {
            source.contentItemValues.forEach(function (sourceValue) {
              //TODO: Improve this function to work more dynamically
              var targetValue = _this5.locale.contentItemValues.find(function (x) {
                return x.name === sourceValue.name;
              });

              targetValue.value = sourceValue.value;
              targetValue.duration = sourceValue.duration;
              targetValue.height = sourceValue.height;
              targetValue.width = sourceValue.width;
              targetValue.originalFilename = sourceValue.originalFilename;
            });
            this.flashMessages.success('Values copied from ' + this.selectedLocale.name);
          }

          this.selectedLocale = undefined;
        } else {
          this.flashMessages.danger(this.selectedLocale.name + ' has no values to copy');
        }
      }
    }
  });

  _exports.default = _default;
});