define("sidekick-portal/components/form/form-group/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WrUYw7If",
    "block": "{\"symbols\":[\"&attrs\",\"@label\",\"@helpText\",\"&default\"],\"statements\":[[7,\"div\",false],[12,\"class\",\"form-group row\"],[13,1],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-xl-2 col-lg-3 col-12 pr-3\"],[8],[0,\"\\n    \"],[7,\"label\",true],[10,\"class\",\"col-form-label\"],[8],[1,[23,2,[]],false],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"form-text text-muted\"],[8],[1,[23,3,[]],false],[9],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"col-xl-10 col-lg-9 col-12 row right-side\"],[10,\"style\",\"margin-top: calc(0.375rem + 1px);\"],[8],[0,\"\\n    \"],[14,4],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sidekick-portal/components/form/form-group/template.hbs"
    }
  });

  _exports.default = _default;
});