define("sidekick-portal/admin/users/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    session: Ember.inject.service(),
    beforeModel: function beforeModel() {
      this.transitionTo('admin.users.single', this.session.get('data').authenticated.user.id);
    }
  });

  _exports.default = _default;
});