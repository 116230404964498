define("sidekick-portal/components/badge-edit-form/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    selectedBadgeType: Ember.computed('badge.badgeType', function () {
      return this.badgeTypes.findBy('key', this.get('badge.badgeType'));
    }),
    selectedTriggerAction: Ember.computed('badge.triggerAction', function () {
      return this.triggerActions.findBy('key', this.get('badge.triggerAction'));
    }),
    selectedRewardType: Ember.computed('badge.rewardType', function () {
      return this.rewardTypes.findBy('key', this.get('badge.rewardType'));
    }),
    selectedRewardDistribution: Ember.computed('badge.rewardDistribution', function () {
      return this.rewardDistributions.findBy('key', this.get('badge.rewardDistribution'));
    }),
    //badgeTypes: does it allow multiple instances, or just one
    badgeTypes: Object.freeze([{
      key: 1,
      value: 'BADGE',
      description: 'Can be got Multiple times'
    }, {
      key: 2,
      value: 'ACHIEVEMENT',
      description: 'Can be achieved only once'
    }]),
    //TODO: Move this into a constants file and use enum transform on model.
    // triggerActions: what triggers a badge to be created
    triggerActions: Object.freeze([{
      key: 1,
      value: 'KICKVALUE',
      description: 'A certain kick value is achieved'
    }, {
      key: 2,
      value: 'CHALLENGECOMPLETE',
      description: 'The goal of a challenge has been reached, triggered at the end of a challenge'
    }, {
      key: 3,
      value: 'COMPETITIONWON',
      description: 'An individual competition has been won'
    }, {
      key: 4,
      value: 'DAILYGOAL',
      description: 'The daily doze in all categories has been achieved'
    }, {
      key: 5,
      value: 'WATERKICKS',
      description: 'A certain value series of kicks used for Unicef water badges'
    }, {
      key: 6,
      value: 'GROUPCOMPETITIONWON',
      description: 'A group competition has been won'
    }, {
      key: 7,
      value: 'COMMITMENTDOER',
      description: 'Commitment doer when a commitment is completed'
    }, {
      key: 8,
      value: 'COMMITMENTVOUCHER',
      description: 'Commitment voucher when a commitment is completed'
    }, {
      key: 1000,
      value: 'TEST',
      description: 'A very special trigger for testing purposes'
    }]),
    //rewardTypes: what type of reward does a badge of this type give
    rewardTypes: Object.freeze([{
      key: 1,
      value: 'WATER',
      description: 'Water reward'
    }, {
      key: 2,
      value: 'LOTTERY',
      description: 'Lottery reward'
    }]),
    //rewardDistributions: how is the reward distributed between the winners
    rewardDistributions: Object.freeze([{
      key: 1,
      value: 'NOSHARING',
      description: 'The full reward goes to each winner'
    }, {
      key: 2,
      value: 'SHARING',
      descrption: 'The full reward is divided between the winners'
    }]),
    actions: {
      toggleModal: function toggleModal() {
        this.toggleModal();
      },
      saveBadgeTemplate: function saveBadgeTemplate(badgeTemplate) {
        this.saveBadgeTemplate(badgeTemplate);
      },
      deleteBadgeTemplate: function deleteBadgeTemplate(badgeTemplate) {
        this.deleteBadgeTemplate(badgeTemplate);
      },
      addContents: function addContents(badge) {
        this.addContents(badge);
      },
      removeContent: function removeContent(content) {
        this.get('badge.contents').removeObject(content);
      },
      setBadgeType: function setBadgeType(type) {
        this.set('badge.badgeType', type.key);
      },
      setTriggerAction: function setTriggerAction(type) {
        this.set('badge.triggerAction', type.key);
      },
      setRewardType: function setRewardType(type) {
        this.set('badge.rewardType', type.key);
      },
      setRewardDistribution: function setRewardDistribution(type) {
        this.set('badge.rewardDistribution', type.key);
      }
    }
  });

  _exports.default = _default;
});