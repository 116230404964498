define("sidekick-portal/validations/program-single", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    template: (0, _validators.validatePresence)(true),
    partner: (0, _validators.validatePresence)(true),
    group: (0, _validators.validatePresence)(true),
    startDate: (0, _validators.validatePresence)(true),
    subGroupMemberCount: (0, _validators.validateNumber)({
      allowBlank: true,
      integer: true,
      positive: true
    }),
    leagueMemberCount: (0, _validators.validateNumber)({
      allowBlank: true,
      integer: true,
      positive: true
    })
  };
  _exports.default = _default;
});