define("sidekick-portal/models/content-item-locale", ["exports", "ember-data", "ember-data-model-fragments", "sidekick-portal/models/constants/cms", "ember-data-model-fragments/attributes"], function (_exports, _emberData, _emberDataModelFragments, _cms, _attributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* app/models/content-item-locale.js */
  var _default = _emberDataModelFragments.default.Fragment.extend({
    locale: _emberData.default.attr('string'),
    status: _emberData.default.attr('enum', _cms.localeStatus),
    createdDate: _emberData.default.attr('string'),
    modifiedDate: _emberData.default.attr('string'),
    contentItemValues: _emberDataModelFragments.default.fragmentArray('content-item-value'),
    sortKey: Object.freeze(['order']),
    contentItemValuesSorted: Ember.computed.sort('contentItemValues', 'sortKey'),
    isPublished: Ember.computed('status', function () {
      return this.status === _cms.localeStatus.find(function (status) {
        return status.name === 'CONTENTITEMLOCALE_STATUS_PUBLISHED';
      });
    }),
    isValid: Ember.computed('contentItemValues', function () {
      return this.contentItemValues.any(function (civ) {
        return !Ember.isEmpty(civ.value);
      });
    }),
    backend: Ember.inject.service(),
    toggleStatus: function toggleStatus() {
      if (this.status.name !== 'CONTENTITEMLOCALE_STATUS_PUBLISHED') {
        //Publish
        this.changeLocaleStatus(_cms.localeStatus.find(function (x) {
          return x.name === 'CONTENTITEMLOCALE_STATUS_PUBLISHED';
        }));
      } else {
        //Unpublish
        this.changeLocaleStatus(_cms.localeStatus.find(function (x) {
          return x.name === 'CONTENTITEMLOCALE_STATUS_NOTPUBLISHED';
        }));
      }
    },
    contentItem: (0, _attributes.fragmentOwner)(),
    changeLocaleStatus: function changeLocaleStatus(localeStatus) {
      var _this = this;

      var url = "admin/contentitems/".concat(this.contentItem.id, "/locales/").concat(this.locale, "?status=").concat(localeStatus.id);
      this.backend.putJSONData(url, {}).then(function () {
        _this.status = localeStatus;
      }).catch(function (error) {
        console.log('Error updating status for locale.', error);
      });
    }
  });

  _exports.default = _default;
});