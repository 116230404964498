define("sidekick-portal/components/activityreport-activeusers/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "asbJlAh3",
    "block": "{\"symbols\":[\"modal\",\"user\",\"&default\"],\"statements\":[[14,3],[0,\"\\n\"],[4,\"bs-modal\",null,[[\"title\",\"onHide\"],[\"Active users\",[28,\"action\",[[23,0,[]],[24,[\"toggleModal\"]]],null]]],{\"statements\":[[4,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[23,1,[\"body\"]],\"expected `modal.body` to be a contextual component but found a string. Did you mean `(component modal.body)`? ('sidekick-portal/components/activityreport-activeusers/template.hbs' @ L3:C5) \"],null]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"table-responsive\"],[8],[0,\"\\n      \"],[7,\"table\",true],[10,\"class\",\"table table-hover\"],[8],[0,\"\\n        \"],[7,\"thead\",true],[8],[0,\"\\n          \"],[7,\"tr\",true],[8],[0,\"\\n            \"],[7,\"th\",true],[8],[0,\"ID\"],[9],[0,\"\\n            \"],[7,\"th\",true],[8],[0,\"Name\"],[9],[0,\"\\n            \"],[7,\"th\",true],[8],[0,\"Score\"],[9],[0,\"\\n          \"],[9],[0,\"\\n        \"],[9],[0,\"\\n        \"],[7,\"tbody\",true],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"users\"]]],null,{\"statements\":[[0,\"            \"],[7,\"tr\",true],[8],[0,\"\\n              \"],[7,\"td\",true],[8],[1,[23,2,[\"userId\"]],false],[9],[0,\"\\n              \"],[7,\"td\",true],[8],[1,[23,2,[\"name\"]],false],[9],[0,\"\\n              \"],[7,\"td\",true],[8],[1,[23,2,[\"score\"]],false],[9],[0,\"\\n            \"],[9],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sidekick-portal/components/activityreport-activeusers/template.hbs"
    }
  });

  _exports.default = _default;
});