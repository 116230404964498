define("sidekick-portal/models/constants/partner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.VoucherPartnerProfiles = void 0;
  var VoucherPartnerProfiles = [{
    id: 1,
    name: 'VOUCHERPARTNER_PROFILE_SIDEKICK',
    description: 'Sidekick'
  }, {
    id: 2,
    name: 'VOUCHERPARTNER_PROFILE_REVITI',
    description: 'Reviti'
  }, {
    id: 3,
    name: 'VOUCHERPARTNER_PROFILE_ANTHEM',
    description: 'Anthem'
  }];
  _exports.VoucherPartnerProfiles = VoucherPartnerProfiles;
});