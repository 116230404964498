define("sidekick-portal/templates/metadata-keys", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "72vhwYWM",
    "block": "{\"symbols\":[],\"statements\":[[1,[22,\"outlet\"],false],[0,\"\\n\\n\"],[5,\"metadata/key-list\",[],[[\"@keys\"],[[23,0,[\"model\"]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sidekick-portal/templates/metadata-keys.hbs"
    }
  });

  _exports.default = _default;
});