define("sidekick-portal/admin/users/single/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yHABUlyU",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"container px-4\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"col-12 col-md-6\"],[8],[0,\"\\n      \"],[7,\"h5\",true],[10,\"class\",\"mb-3 mt-2\"],[8],[0,\"User administration\"],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"col-12 col-md-6\"],[8],[0,\"\\n      \"],[7,\"br\",true],[8],[9],[0,\"\\n      \"],[7,\"label\",true],[10,\"for\",\"user-search\"],[8],[0,\"Search for user\"],[9],[0,\"\\n      \"],[1,[28,\"user-search\",null,[[\"id\",\"user\",\"userSelected\"],[\"user-search\",[24,[\"model\",\"user\"]],[28,\"action\",[[23,0,[]],\"onUserSelected\"],null]]]],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\\n\"],[0,\"  \"],[1,[28,\"user-profile\",null,[[\"user\",\"refreshUser\",\"missions\"],[[24,[\"model\",\"user\"]],[28,\"action\",[[23,0,[]],\"refreshRoute\"],null],[24,[\"model\",\"missions\"]]]]],false],[0,\"\\n\"],[0,\"\\n  \"],[1,[22,\"outlet\"],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sidekick-portal/admin/users/single/template.hbs"
    }
  });

  _exports.default = _default;
});