define("sidekick-portal/templates/ab-tests/single", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MpTgtJ/0",
    "block": "{\"symbols\":[],\"statements\":[[1,[22,\"outlet\"],false],[0,\"\\n\\n\"],[5,\"ab-tests/test-edit\",[],[[\"@test\",\"@saveTest\",\"@removeTest\",\"@addTestValue\",\"@removeTestValue\",\"@clearStartDate\",\"@clearEndDate\"],[[23,0,[\"model\"]],[23,0,[\"saveTest\"]],[23,0,[\"removeTest\"]],[23,0,[\"addTestValue\"]],[23,0,[\"removeTestValue\"]],[23,0,[\"clearStartDate\"]],[23,0,[\"clearEndDate\"]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sidekick-portal/templates/ab-tests/single.hbs"
    }
  });

  _exports.default = _default;
});