define("sidekick-portal/components/program-template/slot-goals/activities-goal-missions/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XCgm26Ne",
    "block": "{\"symbols\":[\"goalMission\",\"@hideAddGoalMissionModal\",\"@goalMissions\",\"@showAddGoalMissionModal\"],\"statements\":[[4,\"each\",[[23,3,[]]],null,{\"statements\":[[0,\"  \"],[7,\"tr\",true],[8],[0,\"\\n    \"],[7,\"td\",true],[10,\"class\",\"pl-4 py-1\"],[8],[0,\"\\n      \"],[5,\"bs-button\",[[12,\"onClick\",[28,\"action\",[[23,0,[]],[23,0,[\"removeGoalMission\"]],[23,1,[]]],null]],[12,\"title\",\"Remove goal mission\"],[12,\"class\",\"p-0\"]],[[\"@type\"],[\"link\"]],{\"statements\":[[0,\"\\n        \"],[7,\"i\",true],[10,\"class\",\"fas fa-minus-square\"],[8],[9],[0,\"\\n      \"]],\"parameters\":[]}],[0,\"\\n      \"],[1,[23,1,[\"mission\",\"name\"]],false],[0,\"\\n    \"],[9],[0,\"\\n\"],[4,\"if\",[[23,1,[\"durationMinutes\"]]],null,{\"statements\":[[0,\"      \"],[7,\"td\",true],[10,\"class\",\"py-1\"],[8],[0,\"\\n        \"],[7,\"span\",true],[10,\"class\",\"text-muted\"],[8],[1,[23,1,[\"durationMinutes\"]],false],[0,\" minutes\"],[9],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[23,1,[\"count\"]]],null,{\"statements\":[[0,\"      \"],[7,\"td\",true],[10,\"class\",\"py-1\"],[8],[0,\"\\n        \"],[7,\"span\",true],[10,\"class\",\"text-muted\"],[8],[1,[23,1,[\"count\"]],false],[0,\" count\"],[9],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\n\"],[4,\"if\",[[23,4,[]]],null,{\"statements\":[[0,\"  \"],[5,\"program-template/slot-goals/goal-mission-modal\",[],[[\"@allowDuration\",\"@missions\",\"@addGoalMission\",\"@onHidden\"],[true,[23,0,[\"filteredMissions\"]],[28,\"action\",[[23,0,[]],\"addGoalMission\"],null],[23,2,[]]]]],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sidekick-portal/components/program-template/slot-goals/activities-goal-missions/template.hbs"
    }
  });

  _exports.default = _default;
});