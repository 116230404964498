define("sidekick-portal/models/constants/external-user-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.Behaviour = void 0;
  var Behaviour = {
    validatePhoneNumber: 1,
    needsReview: 2,
    enrolmentEnabled: 4,
    cancerProgram: 8
  };
  _exports.Behaviour = Behaviour;
});