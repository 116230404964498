define("sidekick-portal/models/feed-comment", ["exports", "ember-data", "ember-data-model-fragments"], function (_exports, _emberData, _emberDataModelFragments) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* app/models/comment.js */
  var _default = _emberData.default.Model.extend({
    user: _emberDataModelFragments.default.fragment('feed-user'),
    text: _emberData.default.attr('string'),
    commentDate: _emberData.default.attr('string'),
    feed: _emberData.default.belongsTo('feed')
  });

  _exports.default = _default;
});