define("sidekick-portal/models/constants/user-program", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.programTypes = _exports.programStatuses = void 0;
  var programStatuses = Object.freeze([{
    name: 'USERPROGRAM_STATUS_ACTIVE',
    id: 1,
    description: 'Active'
  }, {
    name: 'USERPROGRAM_STATUS_PENDING',
    id: 2,
    description: 'Pending'
  }, {
    name: 'USERPROGRAM_STATUS_FINISHED',
    id: 3,
    description: 'Finished'
  }, {
    name: 'USERPROGRAM_STATUS_PAUSED',
    id: 4,
    description: 'Paused'
  }, {
    name: 'USERPROGRAM_STATUS_REMOVED',
    id: 5,
    description: 'Removed'
  }, {
    name: 'USERPROGRAM_STATUS_ALMOSTFINISHED',
    id: 6,
    description: 'Almost Finished'
  }]);
  _exports.programStatuses = programStatuses;
  var programTypes = Object.freeze([{
    name: 'USERPROGRAM_TYPE_INTRODUCTION',
    id: 1,
    description: 'Introduction program'
  }]);
  _exports.programTypes = programTypes;
});