define("sidekick-portal/helpers/is-dev-environment", ["exports", "sidekick-portal/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.isDevEnvironment = isDevEnvironment;

  function isDevEnvironment() {
    return _environment.default.environment === 'development' || _environment.default.environment === 'staging';
  }

  var _default = Ember.Helper.helper(isDevEnvironment);

  _exports.default = _default;
});