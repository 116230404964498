define("sidekick-portal/templates/program-catalog-items-v10/list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fF5O5QBM",
    "block": "{\"symbols\":[],\"statements\":[[1,[22,\"outlet\"],false],[0,\"\\n\\n\"],[5,\"program-catalog-v10/program-catalog-item/item-list\",[],[[\"@items\",\"@tableFilter\",\"@locationFilter\"],[[23,0,[\"model\"]],[23,0,[\"tableFilter\"]],[23,0,[\"locationFilter\"]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sidekick-portal/templates/program-catalog-items-v10/list.hbs"
    }
  });

  _exports.default = _default;
});