define("sidekick-portal/admin/users/privileged/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "AecCBlI/",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"container-fluid px-4\"],[8],[0,\"\\n  \"],[7,\"h3\",true],[10,\"class\",\"mb-3 mt-2\"],[8],[0,\"Super Admins (privileges: 1000)\"],[9],[0,\"\\n  \"],[1,[28,\"partner-users\",null,[[\"users\"],[[24,[\"model\",\"supers\"]]]]],false],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"container-fluid px-4\"],[8],[0,\"\\n  \"],[7,\"h3\",true],[10,\"class\",\"mb-3 pt-3 border-top\"],[8],[0,\"Content Admins (privileges: 100)\"],[9],[0,\"\\n  \"],[1,[28,\"partner-users\",null,[[\"users\"],[[24,[\"model\",\"admins\"]]]]],false],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"container-fluid px-4\"],[8],[0,\"\\n  \"],[7,\"h3\",true],[10,\"class\",\"mb-3 pt-3 border-top\"],[8],[0,\"Coaches (privileges: 10)\"],[9],[0,\"\\n  \"],[1,[28,\"partner-users\",null,[[\"users\"],[[24,[\"model\",\"coaches\"]]]]],false],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[1,[22,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sidekick-portal/admin/users/privileged/template.hbs"
    }
  });

  _exports.default = _default;
});