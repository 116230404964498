define("sidekick-portal/admin/retention-messages/single/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    flashMessages: Ember.inject.service(),
    actions: {
      saveProfile: function saveProfile() {
        var _this = this;

        if (Ember.isBlank(this.model.messageProfile.name) || Ember.isEmpty(this.model.messageProfile.triggerAction) || Ember.isBlank(this.model.messageProfile.get('contentItem.id'))) {
          this.flashMessages.danger('Error saving message profile. Required field missing');
          return;
        }

        this.model.messageProfile.save().then(function (messageProfile) {
          _this.flashMessages.success('Message profile saved');

          _this.transitionToRoute('admin.retention-messages.single', messageProfile.id);
        }).catch(function (error) {
          console.log(error);

          _this.flashMessages.danger('Error saving message profile. ' + error.errors[0].message);
        });
      },
      deleteProfile: function deleteProfile() {
        this.model.messageProfile.destroyRecord();
        this.flashMessages.info('Message profile deleted');
        this.transitionToRoute('admin.retention-messages');
      }
    }
  });

  _exports.default = _default;
});