define("sidekick-portal/models/constants/pins", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.pinTypes = void 0;

  /* eslint-disable max-len */
  var pinTypes = Object.freeze([{
    name: 'PINTYPE_REGULAR',
    id: 1,
    description: 'Regular'
  }, {
    name: 'PINTYPE_NOFRIENDS',
    id: 2,
    description: 'No Friends'
  }, {
    name: 'PINTYPE_COACH',
    id: 3,
    description: 'Coach'
  }, {
    name: 'PINTYPE_INDIVIDUALPROGRAM',
    id: 4,
    description: 'Individual Program'
  }]);
  /*
  //Regular pins place users in groups and add the user id's to the list of users. All new users are made friends of existing users with the same pin
  public static final int PINTYPE_REGULAR = 1;
  //NOfriends pins do the same as regular pins, except they don't add the user to a list of users and they don't make any friends
  public static final int PINTYPE_NOFRIENDS = 2;
  //Coach pin assigns the user using it as an owner to the group/groups associated with the pin
  public static final int PINTYPE_COACH = 3;
  //The pin has a single partner and extra param that is the programTemplateId
  // The user is added to the partner, a new group is created with a program and the user is added to that group
  public static final int PINTYPE_INDIVIDUALPROGRAM = 4;
  */

  _exports.pinTypes = pinTypes;
});