define("sidekick-portal/models/constants/schedules", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.TriggerTypes = _exports.TriggerEntityTypes = _exports.AffectedEntityTypes = void 0;
  var TriggerTypes = [{
    id: 1,
    name: 'SCHEDULE_TRIGGERTYPE_USERJOINED',
    description: 'User Joined'
  }];
  _exports.TriggerTypes = TriggerTypes;
  var TriggerEntityTypes = [{
    id: 1,
    name: 'SCHEDULE_ENTITYTYPE_PROGRAMTEMPLATE',
    description: 'Program Template'
  }];
  _exports.TriggerEntityTypes = TriggerEntityTypes;
  var AffectedEntityTypes = [{
    id: 1,
    name: 'SCHEDULE_AFFECTEDENTITYTYPE_SURVEY',
    description: 'Survey'
  }];
  _exports.AffectedEntityTypes = AffectedEntityTypes;
});