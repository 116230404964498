define("sidekick-portal/components/table-themes/customTheme", ["exports", "ember-models-table/themes/bootstrap4"], function (_exports, _bootstrap) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* eslint 'ember/avoid-leaking-state-in-ember-objects': 'off' */
  var _default = _bootstrap.default.extend({
    table: 'table table-striped table-lg table-responsive-md bg-white customTable',
    buttonDefault: 'btn-sm tableButton',
    input: 'input tableInput',
    messages: {
      searchLabel: '',
      searchPlaceholder: 'Start typing to search...'
    },
    'sort-asc': 'fas fa-sort-amount-down',
    'sort-desc': 'fas fa-sort-amount-up'
  });

  _exports.default = _default;
});