define("sidekick-portal/components/program-template/list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jHpYmFGr",
    "block": "{\"symbols\":[\"@tableFilter\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"container-fluid px-4\"],[8],[0,\"\\n  \"],[5,\"table-title\",[],[[\"@title\",\"@createNewRoute\"],[\"Program Templates\",\"admin.program-templates.details\"]]],[0,\"\\n  \"],[7,\"label\",true],[8],[0,\"\\n    \"],[1,[28,\"input\",null,[[\"type\",\"checked\"],[\"checkbox\",[23,0,[\"showInactive\"]]]]],false],[0,\"\\n    Show inactive\\n  \"],[9],[0,\"\\n\\n  \"],[5,\"models-table\",[[12,\"class\",\"emtable\"]],[[\"@data\",\"@columns\",\"@filterString\",\"@filteringIgnoreCase\",\"@useFilteringByColumns\",\"@multipleColumnsSorting\",\"@useNumericPagination\",\"@pageSize\",\"@focusGlobalFilter\",\"@themeInstance\"],[[23,0,[\"filteredTemplates\"]],[23,0,[\"columnsForTable\"]],[23,1,[]],true,false,false,true,50,true,[23,0,[\"customThemeInstance\"]]]]],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sidekick-portal/components/program-template/list/template.hbs"
    }
  });

  _exports.default = _default;
});