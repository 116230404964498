define("sidekick-portal/models/constants/program-templates", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.senderType = _exports.sendMethod = _exports.programTemplateSlotPathTypes = _exports.programTemplateSlotGoalTypesIntegers = _exports.programTemplateSlotGoalTypes = _exports.programTemplateSlotBehaviours = _exports.programTemplateBehaviours = void 0;
  var programTemplateSlotBehaviours = Object.freeze([{
    name: 'Food focus',
    id: 1,
    description: 'This slot is focused on food and therefore meal logs'
  }, {
    name: 'Warmup week',
    id: 2,
    description: "This slot is a warmup slot, real competition doesn't start in this slot"
  }]);
  _exports.programTemplateSlotBehaviours = programTemplateSlotBehaviours;
  var programTemplateBehaviours = Object.freeze([{
    id: 2,
    name: 'For sale',
    description: 'Template is public and can be sold'
  }, {
    id: 3,
    name: 'DPP',
    description: 'This is a DPP program template'
  }, {
    id: 4,
    name: 'Corporate program',
    description: 'This is a Corporate program template'
  }, {
    //PROGRAMTEMPLATEBEHAVIOUR_LSHCOVID
    id: 1000,
    name: 'LSH - Covid-19 taskforce',
    description: 'Affects Coach Portal display'
  }]);
  _exports.programTemplateBehaviours = programTemplateBehaviours;
  var senderType = Object.freeze([// {
  //   name: 'PROGRAMTEMPLATESLOT_SENDER_TYPE_USER',
  //   id: 1,
  //   description: 'user'
  // },
  {
    name: 'PROGRAMTEMPLATESLOT_SENDER_TYPE_COACH',
    id: 2,
    description: 'Coach'
  }, {
    name: 'PROGRAMTEMPLATESLOT_SENDER_TYPE_SIDEKICK',
    id: 3,
    description: 'Sidekick'
  }]);
  _exports.senderType = senderType;
  var sendMethod = Object.freeze([{
    name: 'PROGRAMTEMPLATESLOT_SEND_METHOD_NONE',
    id: 0,
    description: 'Video'
  }, {
    name: 'PROGRAMTEMPLATESLOT_SEND_METHOD_MESSAGE',
    id: 1,
    description: 'Message'
  }, // {
  //   name: 'PROGRAMTEMPLATESLOT_SEND_METHOD_FEED',
  //   id: 2,
  //   description: 'Feed'
  // },
  {
    name: 'PROGRAMTEMPLATESLOT_SEND_METHOD_EMAIL',
    id: 4,
    description: 'E-mail'
  }, // {
  //   name: 'PROGRAMTEMPLATESLOT_SEND_METHOD_FEED_AND_EMAIL',
  //   id: 6,
  //   description: 'Feed & E-mail'
  // },
  {
    name: 'PROGRAMTEMPLATESLOT_SEND_METHOD_OPENMISSION',
    id: 8,
    description: 'Open Mission'
  }, {
    name: 'PROGRAMTEMPLATESLOT_SEND_METHOD_BUNDLE',
    id: 16,
    description: 'Bundle'
  }, {
    name: 'PROGRAMTEMPLATESLOT_SEND_METHOD_AUDIO_TEXT',
    id: 32,
    description: 'Audio Text'
  }]);
  _exports.sendMethod = sendMethod;
  var programTemplateSlotGoalTypes = Object.freeze([{
    name: 'PROGRAMTEMPLATESLOTGOAL_TYPE_HEALTHYEATING',
    id: 1,
    description: 'water, veggies, ... count based'
  }, {
    name: 'PROGRAMTEMPLATESLOTGOAL_TYPE_RESISTTEMPTATION',
    id: 2,
    description: 'no candy, no soda, TBD.'
  }, {
    name: 'PROGRAMTEMPLATESLOTGOAL_TYPE_EXERCISE',
    id: 3,
    description: 'squats, pushups, ... count based'
  }, {
    name: 'PROGRAMTEMPLATESLOTGOAL_TYPE_ACTIVITIES',
    id: 4,
    description: 'walking, yoga, crossfit,... duration based'
  }, {
    name: 'PROGRAMTEMPLATESLOTGOAL_TYPE_MEDITATION',
    id: 5,
    description: 'relaxation, guided meditation, ... count based'
  }, {
    name: 'PROGRAMTEMPLATESLOTGOAL_TYPE_MINDFULNESS',
    id: 6,
    description: 'count to ten, mindful walking, ... count based'
  }, {
    name: 'PROGRAMTEMPLATESLOTGOAL_TYPE_SELFASSESSMENT',
    id: 7,
    description: 'stress level, quality of sleep, ... count based'
  }, {
    name: 'PROGRAMTEMPLATESLOTGOAL_TYPE_INSTANCECOUNTMISSIONS',
    id: 8,
    description: 'weight, meal log, ... count based'
  }, {
    name: 'PROGRAMTEMPLATESLOTGOAL_TYPE_STEPCOUNTER',
    id: 9,
    description: 'step counter, step count based'
  }, {
    name: 'PROGRAMTEMPLATESLOTGOAL_TYPE_PROGRAMVIDEO',
    id: 10,
    description: 'Program Video'
  }, {
    name: 'PROGRAMTEMPLATESLOTGOAL_TYPE_PHYSICALACTIVITY',
    id: 11,
    description: 'Physical Activity'
  }]);
  _exports.programTemplateSlotGoalTypes = programTemplateSlotGoalTypes;
  var programTemplateSlotPathTypes = Object.freeze([{
    name: 'PROGRAMTEMPLATESLOTPATH_PATHTYPE_MAIN',
    id: 1,
    description: 'Main'
  }, {
    name: 'PROGRAMTEMPLATESLOTPATH_PATHTYPE_CAREPLAN',
    id: 2,
    description: 'Care Plan'
  }, {
    name: 'PROGRAMTEMPLATESLOTPATH_PATHTYPE_CAREPATH',
    id: 3,
    description: 'Care Path'
  }]);
  _exports.programTemplateSlotPathTypes = programTemplateSlotPathTypes;
  var programTemplateSlotGoalTypesIntegers = Object.freeze({
    healthyEating: 1,
    resistTemptation: 2,
    exercise: 3,
    activities: 4,
    meditation: 5,
    mindfulness: 6,
    selfAssessment: 7,
    instanceCountMissions: 8,
    stepCounter: 9,
    physicalActivity: 11
  });
  _exports.programTemplateSlotGoalTypesIntegers = programTemplateSlotGoalTypesIntegers;
});