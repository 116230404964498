define("sidekick-portal/templates/communities/templates/list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PCLCc9xb",
    "block": "{\"symbols\":[],\"statements\":[[1,[22,\"outlet\"],false],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"container\"],[8],[0,\"\\n  \"],[5,\"community/template-list\",[],[[\"@communityTemplates\",\"@goToTemplate\"],[[23,0,[\"model\"]],[28,\"action\",[[23,0,[]],[23,0,[\"goToTemplate\"]]],null]]]],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sidekick-portal/templates/communities/templates/list.hbs"
    }
  });

  _exports.default = _default;
});