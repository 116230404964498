define("sidekick-portal/components/table-components/program-user-count/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "p3aomNuE",
    "block": "{\"symbols\":[\"@record\"],\"statements\":[[1,[23,1,[\"group\",\"memberCount\"]],false],[0,\" \"],[4,\"if\",[[23,1,[\"group\",\"expectedUserCount\"]]],null,{\"statements\":[[0,\" / \"],[1,[23,1,[\"group\",\"expectedUserCount\"]],false],[0,\" \"]],\"parameters\":[]},null],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sidekick-portal/components/table-components/program-user-count/template.hbs"
    }
  });

  _exports.default = _default;
});