define("sidekick-portal/templates/metadata-keys/single", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hv2i7GE8",
    "block": "{\"symbols\":[],\"statements\":[[1,[22,\"outlet\"],false],[0,\"\\n\\n\"],[5,\"metadata/key-edit\",[],[[\"@key\",\"@saveKey\",\"@removeKey\",\"@backToList\"],[[23,0,[\"model\"]],[23,0,[\"saveKey\"]],[23,0,[\"removeKey\"]],[23,0,[\"backToList\"]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sidekick-portal/templates/metadata-keys/single.hbs"
    }
  });

  _exports.default = _default;
});