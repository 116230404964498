define("sidekick-portal/serializers/feed", ["exports", "sidekick-portal/serializers/application", "ember-data"], function (_exports, _application, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend(_emberData.default.EmbeddedRecordsMixin, {
    primaryKey: 'feedId',
    attrs: {
      comments: {
        embedded: 'always'
      },
      user: 'user.userId'
    }
  });

  _exports.default = _default;
});