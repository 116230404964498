define("sidekick-portal/serializers/application", ["exports", "ember-data", "ember-inflector"], function (_exports, _emberData, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.RESTSerializer.extend({
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      if (payload.kind) {
        delete payload.kind;
      }

      if (payload.etag) {
        delete payload.etag;
      }

      if (payload.items) {
        var pluralizedModelName = _emberInflector.default.inflector.pluralize(primaryModelClass.modelName);

        var items = payload.items;
        payload = {};
        payload[pluralizedModelName] = items;
      }

      return this._super(store, primaryModelClass, payload, id, requestType);
    },
    normalizeSingleResponse: function normalizeSingleResponse(store, primaryModelClass, payload, id, requestType) {
      var newPayload = {};
      newPayload[primaryModelClass.modelName] = payload;
      return this._super(store, primaryModelClass, newPayload, id, requestType);
    },
    serialize: function serialize(snapshot, options) {
      options = options ? options : {};
      options.includeId = true;

      var json = this._super(snapshot, options);

      return json;
    },
    serializeIntoHash: function serializeIntoHash(data, type, record, options) {
      Ember.assign(data, this.serialize(record, options));
      return data;
    }
  });

  _exports.default = _default;
});