define("sidekick-portal/serializers/program-slot", ["exports", "sidekick-portal/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* eslint no-unused-vars: "off" */
  var _default = _application.default.extend({
    serializeAttribute: function serializeAttribute(snapshot, json, key, attributes) {
      if (key === 'status') {
        return null;
      }

      return this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});