define("sidekick-portal/models/constants/program-catalog", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.ProgramCatalogItemV10Behaviour = _exports.ProgramCatalogItemStatus = _exports.ProgramCatalogItemCategory = _exports.ProgramCatalogItemBehaviour = void 0;
  var ProgramCatalogItemStatus = Object.freeze([//public static final int PROGRAMCATALOGITEM_STATUS_PUBLIC = 1;
  //public static final int PROGRAMCATALOGITEM_STATUS_PRIVATE = 2;
  {
    name: 'Public',
    id: 1,
    description: ''
  }, {
    name: 'Private',
    id: 2,
    description: ''
  }]);
  _exports.ProgramCatalogItemStatus = ProgramCatalogItemStatus;
  var ProgramCatalogItemBehaviour = Object.freeze([// public static final int PROGRAMCATALOGITEM_BEHAVIOUR_PROGRAMTEMPLATE = 1;
  // public static final int PROGRAMCATALOGITEM_BEHAVIOUR_PROGRAM = 2;
  // {
  //   name: 'Program Template',
  //   id: 1,
  //   description: ''
  // },
  // {
  //   name: 'Program',
  //   id: 2,
  //   description: ''
  // },
  {
    name: 'Locked',
    id: 4,
    description: 'PROGRAMCATALOGITEM_BEHAVIOUR_LOCKEDPERMANENTLY'
  }, {
    name: 'Requires Electronic ID',
    id: 8,
    description: 'PROGRAMCATALOGITEM_BEHAVIOUR_REQUIRESEID'
  }]);
  _exports.ProgramCatalogItemBehaviour = ProgramCatalogItemBehaviour;
  var ProgramCatalogItemCategory = Object.freeze([// public static final int PROGRAMCATALOGITEM_CATEGORY_GENERAL = 1;
  // public static final int PROGRAMCATALOGITEM_CATEGORY_TREATMENT = 2;
  {
    name: 'General',
    id: 1,
    description: ''
  }, {
    name: 'Treatment',
    id: 2,
    description: ''
  }]);
  _exports.ProgramCatalogItemCategory = ProgramCatalogItemCategory;
  var ProgramCatalogItemV10Behaviour = {
    lockedPermanently: 4,
    requiresElectronicId: 8,
    surveyResultExportEnabled: 16,
    usingBodyTraceScale: 32,
    includeFinishedPrograms: 64,
    smsInvitedByEnabled: 128,
    educationalContentUnlocked: 256,
    remainingDailyTasksReminder: 512,
    unpausable: 1024
  };
  _exports.ProgramCatalogItemV10Behaviour = ProgramCatalogItemV10Behaviour;
});