define("sidekick-portal/components/partner-users/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "r7w39Fde",
    "block": "{\"symbols\":[\"externalType\",\"&default\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"col-md-2 pl-0 mb-2\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"includeExternalSystem\"]]],null,{\"statements\":[[0,\"    \"],[7,\"label\",true],[10,\"for\",\"external-system-filter\"],[8],[0,\"External system\"],[9],[0,\"\\n    \"],[5,\"power-select\",[[12,\"id\",\"external-system-filter\"]],[[\"@options\",\"@selected\",\"@placeholder\",\"@allowClear\",\"@onChange\"],[[23,0,[\"ExternalTypes\"]],[23,0,[\"selectedExternalType\"]],\"Click to select...\",\"true\",[28,\"action\",[[23,0,[]],[28,\"mut\",[[23,0,[\"selectedExternalType\"]]],null]],null]]],{\"statements\":[[0,\"\\n      \"],[1,[23,1,[\"description\"]],false],[0,\"\\n    \"]],\"parameters\":[1]}],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"table-responsive\"],[8],[0,\"\\n  \"],[5,\"models-table\",[[12,\"class\",\"emtable\"]],[[\"@data\",\"@columns\",\"@filteringIgnoreCase\",\"@pageSize\",\"@multipleColumnsSorting\",\"@useFilteringByColumns\",\"@useNumericPagination\",\"@focusGlobalFilter\",\"@themeInstance\"],[[23,0,[\"filteredUsers\"]],[23,0,[\"columns\"]],true,50,false,false,true,true,[23,0,[\"customThemeInstance\"]]]]],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[14,2],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sidekick-portal/components/partner-users/template.hbs"
    }
  });

  _exports.default = _default;
});