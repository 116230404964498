define("sidekick-portal/models/constants/metadata", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.EntityTypes = void 0;
  var EntityTypes = [{
    id: 11,
    name: 'METADATA_ENTITYTYPE_PROGRAMCATALOGITEM',
    description: 'Program Catalog Item'
  }, {
    id: 12,
    name: 'METADATA_ENTITYTYPE_SURVEY',
    description: 'Survey'
  }, {
    id: 13,
    name: 'METADATA_ENTITYTYPE_LISTCOLUMN',
    description: 'List Column'
  }];
  _exports.EntityTypes = EntityTypes;
});