define("sidekick-portal/components/confirm-modal/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      cancel: function cancel() {
        this.cancel();
      },
      confirm: function confirm(entity) {
        this.confirm(entity);
      }
    }
  });

  _exports.default = _default;
});