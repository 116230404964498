define("sidekick-portal/components/user-activity/component", ["exports", "sidekick-portal/components/table-themes/customTheme"], function (_exports, _customTheme) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    customThemeInstance: _customTheme.default.create(),
    columnsForTable: Object.freeze([{
      propertyName: 'scoreId',
      title: 'Score ID',
      isHidden: true
    }, {
      propertyName: 'date',
      title: 'Date',
      component: 'table-components/start-date-format'
    }, {
      propertyName: 'kicks',
      title: 'Points',
      className: 'font-weight-bold'
    }, {
      propertyName: 'cleanValues',
      title: 'Values'
    }, {
      propertyName: 'missionNames',
      title: 'Missions'
    }]),
    activitiesCleanedUp: Ember.computed('activities', {
      get: function get() {
        var _this = this;

        var activities = this.activities; //Create Ember Array object, so the DOM gets updated when it's populated.

        var activitiesClean = Ember.ArrayProxy.create({
          content: Ember.A([])
        });

        if (!Ember.isEmpty(activities)) {
          activities.forEach(function (activity) {
            var missionNames = [];
            var cleanValues = [];
            activity.missionIds.forEach(function (id) {
              var mission = _this.missions.findBy('id', id);

              if (!Ember.isEmpty(mission)) {
                var name = mission.get('name');
                name = Ember.String.capitalize(name.replace('mission.name.', ''));
                missionNames.push(name);
              }
            });
            activity.values.forEach(function (value) {
              if (!isNaN(value)) {
                cleanValues.push(parseFloat(value.toFixed(3)));
              } else {
                cleanValues.push(value);
              }
            });
            activity.cleanValues = cleanValues;
            activity.missionNames = missionNames;
            activitiesClean.addObject(activity);
          });
          return activitiesClean;
        }

        return null;
      }
    })
  });

  _exports.default = _default;
});