define("sidekick-portal/templates/program-catalog-organisations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1516EuX4",
    "block": "{\"symbols\":[],\"statements\":[[1,[22,\"outlet\"],false],[0,\"\\n\\n\"],[5,\"program-catalog-organisations/organisation-list\",[],[[\"@organisations\"],[[23,0,[\"model\"]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sidekick-portal/templates/program-catalog-organisations.hbs"
    }
  });

  _exports.default = _default;
});