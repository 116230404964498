define("sidekick-portal/models/program-slot", ["exports", "ember-data/attr", "ember-data-model-fragments"], function (_exports, _attr, _emberDataModelFragments) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberDataModelFragments.default.Fragment.extend({
    slotNumber: (0, _attr.default)('number'),
    programTemplateSlotNumber: (0, _attr.default)('number'),
    status: (0, _attr.default)('number'),
    startDate: (0, _attr.default)('moment'),
    calculationTime: (0, _attr.default)('date'),
    endProcessingTime: (0, _attr.default)('date'),
    endDate: (0, _attr.default)('date')
  });

  _exports.default = _default;
});