define("sidekick-portal/index/route", ["exports", "ember-simple-auth/mixins/unauthenticated-route-mixin"], function (_exports, _unauthenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_unauthenticatedRouteMixin.default, {
    session: Ember.inject.service(),
    actions: {
      error: function error(e) {
        console.log('Error somewhere deep down.' + e);
        console.log(e);
      }
    }
  });

  _exports.default = _default;
});