define("sidekick-portal/components/cms/content-item-preview/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gSsYwY/u",
    "block": "{\"symbols\":[\"value\",\"@item\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"item-preview\"],[8],[0,\"\\n\"],[4,\"each\",[[23,2,[\"contentItemLocales\",\"firstObject\",\"contentItemValues\"]]],null,{\"statements\":[[4,\"if\",[[28,\"eq\",[[23,1,[\"name\"]],\"body\"],null]],null,{\"statements\":[[0,\"      \"],[7,\"div\",true],[10,\"class\",\"body\"],[8],[0,\"\\n        \"],[1,[23,1,[\"value\"]],false],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[28,\"eq\",[[23,1,[\"name\"]],\"description\"],null]],null,{\"statements\":[[0,\"      \"],[7,\"div\",true],[10,\"class\",\"description\"],[8],[0,\"\\n        \"],[1,[23,1,[\"value\"]],false],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[28,\"eq\",[[23,1,[\"name\"]],\"videoPoster\"],null]],null,{\"statements\":[[0,\"      \"],[7,\"img\",true],[11,\"src\",[23,1,[\"value\"]]],[10,\"alt\",\"poster\"],[8],[9],[0,\"\\n    \"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"parameters\":[]}]],\"parameters\":[1]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sidekick-portal/components/cms/content-item-preview/template.hbs"
    }
  });

  _exports.default = _default;
});