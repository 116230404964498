define("sidekick-portal/components/table-components/edit-entity/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JBU8r/SJ",
    "block": "{\"symbols\":[\"@record\"],\"statements\":[[4,\"link-to\",null,[[\"class\",\"route\",\"model\"],[\"btn btn-xs border px-2\",\"admin.programs.single\",[23,1,[\"id\"]]]],{\"statements\":[[0,\"Edit\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"link-to\",null,[[\"class\",\"route\",\"models\"],[\"btn btn-xs border px-2\",\"admin.partners.single.groups.single.details\",[28,\"array\",[[23,1,[\"partner\",\"id\"]],[23,1,[\"group\",\"id\"]]],null]]],{\"statements\":[[0,\"Screening\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sidekick-portal/components/table-components/edit-entity/template.hbs"
    }
  });

  _exports.default = _default;
});