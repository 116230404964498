define("sidekick-portal/helpers/truncate-text", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.truncateText = truncateText;

  function truncateText(params, hash) {
    var text = params[0];
    var limit = hash.limit ? hash.limit : 50;

    if (text && text.length && text.length > limit) {
      return text.substring(0, limit) + '...';
    }

    return new Ember.String.htmlSafe(text);
  }

  var _default = Ember.Helper.helper(truncateText);

  _exports.default = _default;
});