define("sidekick-portal/admin/users/recent/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    beforeModel: function beforeModel() {
      this.controllerFor('application').set('isFetchingData', true);

      this._super.apply(this, arguments);
    },
    model: function model() {
      return Ember.RSVP.hash({
        users: this.store.query('user', {
          count: 200
        })
      });
    }
  });

  _exports.default = _default;
});