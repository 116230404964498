define("sidekick-portal/models/constants/badges", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.badgeRewardTypes = _exports.badgeRewardDistribution = void 0;
  var badgeRewardTypes = Object.freeze([{
    name: 'BADGEREWARDTYPE_NONE',
    id: 0,
    description: 'No reward'
  }, {
    name: 'BADGEREWARDTYPE_WATER',
    id: 1,
    description: 'Water'
  }, {
    name: 'BADGEREWARDTYPE_LOTTERY',
    id: 2,
    description: 'Lottery'
  }, {
    name: 'BADGEREWARDTYPE_TREESEED',
    id: 3,
    description: 'Tree seed'
  }, {
    name: 'BADGEREWARDTYPE_TREE',
    id: 4,
    description: 'Tree'
  }, {
    name: 'BADGEREWARDTYPE_POLIOPART',
    id: 5,
    description: 'Partial polio vaccination'
  }, {
    name: 'BADGEREWARDTYPE_POLIO',
    id: 6,
    description: 'Polio vaccination'
  }, //A lottery number is allocated in the extraParam field
  {
    name: 'BADGEREWARDTYPE_LOTTERYNUMBER',
    id: 7,
    description: 'Lottery number'
  }]);
  _exports.badgeRewardTypes = badgeRewardTypes;
  var badgeRewardDistribution = Object.freeze([{
    name: 'BADGEREWARDDISTRIBUTION_NOSHARING',
    id: 1,
    description: 'Full reward to each winner'
  }, {
    name: 'BADGEREWARDDISTRIBUTION_SHARING',
    id: 1,
    description: 'Full reward shared between each winner'
  }]);
  _exports.badgeRewardDistribution = badgeRewardDistribution;
});