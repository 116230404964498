define("sidekick-portal/transforms/enum", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Transform.extend({
    deserialize: function deserialize(serialized, options) {
      if (Ember.isBlank(options)) {
        return null;
      }

      return options.find(function (x) {
        return x.id === serialized || x.id === parseInt(serialized);
      });
    },
    serialize: function serialize(deserialized) {
      if (Ember.isBlank(deserialized)) {
        return null;
      }

      return deserialized.id;
    }
  });

  _exports.default = _default;
});