define("sidekick-portal/templates/login", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mJNRD1mx",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"container\"],[8],[0,\"\\n  \"],[7,\"form\",false],[12,\"class\",\"form-signin\"],[3,\"action\",[[23,0,[]],\"authenticate\"],[[\"on\"],[\"submit\"]]],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"loginFailed\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\",true],[10,\"class\",\"alert alert-danger\"],[8],[0,\"\\n        \"],[1,[23,0,[\"errorMessage\"]],false],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[7,\"h2\",true],[10,\"class\",\"form-signin-heading\"],[8],[0,\"\\n      Please sign in\\n    \"],[9],[0,\"\\n    \"],[7,\"label\",true],[10,\"for\",\"username\"],[10,\"class\",\"sr-only\"],[8],[0,\"\\n      Email address\\n    \"],[9],[0,\"\\n    \"],[5,\"input\",[[12,\"id\",\"username\"],[12,\"class\",\"form-control\"],[12,\"placeholder\",\"Email\"],[12,\"autocomplete\",\"email\"],[12,\"type\",\"email\"]],[[\"@value\"],[[23,0,[\"username\"]]]]],[0,\"\\n    \"],[7,\"label\",true],[10,\"for\",\"password\"],[10,\"class\",\"sr-only\"],[8],[0,\"\\n      Password\\n    \"],[9],[0,\"\\n    \"],[5,\"input\",[[12,\"id\",\"password\"],[12,\"class\",\"form-control\"],[12,\"placeholder\",\"Password\"],[12,\"autocomplete\",\"current-password\"],[12,\"type\",\"password\"]],[[\"@value\"],[[23,0,[\"password\"]]]]],[0,\"\\n    \"],[7,\"button\",true],[10,\"id\",\"login-button\"],[10,\"class\",\"btn btn-lg btn-primary btn-block\"],[11,\"disabled\",[22,\"isProcessing\"]],[10,\"type\",\"submit\"],[8],[0,\"\\n      Sign in\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sidekick-portal/templates/login.hbs"
    }
  });

  _exports.default = _default;
});