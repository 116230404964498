define("sidekick-portal/admin/users/privileged/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    beforeModel: function beforeModel() {
      this.controllerFor('application').set('isFetchingData', true);

      this._super.apply(this, arguments);
    },
    model: function model() {
      return Ember.RSVP.hash({
        coaches: this.store.query('user', {
          privilegesLevel: 10
        }).catch(function (e) {
          console.log('Error fetching users by privilegesLevel 10. error object: ' + JSON.stringify(e));
        }),
        admins: this.store.query('user', {
          privilegesLevel: 100
        }).catch(function (e) {
          console.log('Error fetching users by privilegesLevel 100. error object: ' + JSON.stringify(e));
        }),
        supers: this.store.query('user', {
          privilegesLevel: 1000
        }).catch(function (e) {
          console.log('Error fetching users by privilegesLevel 1000. error object: ' + JSON.stringify(e));
        })
      });
    }
  });

  _exports.default = _default;
});