define("sidekick-portal/components/charts/active-users/component", ["exports", "chart.js"], function (_exports, _chart) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    dataReady: Ember.computed('activeUsersLoaded', 'chartData', function () {
      if (this.activeUsersLoaded) {
        this.drawChart();
        return true;
      }

      return false;
    }),
    activeUsersData: Ember.computed.map('chartData', function (day) {
      return day.count;
    }),
    chartLabels: Ember.computed.map('chartData', function (day) {
      return day.date;
    }),
    drawChart: function drawChart() {
      var _this = this;

      if (!document.getElementById('activeUsersChart')) {
        console.log('document not ready, need to reschedule.');
        Ember.run.later(function () {
          _this.drawChart();
        }, 100);
        return;
      }

      var ctx = document.getElementById('activeUsersChart').getContext('2d');

      if (this.chart) {
        this.chart.destroy();
      }

      var data = {
        labels: this.chartLabels,
        datasets: [{
          fill: true,
          lineTension: 0,
          backgroundColor: '#0099FF',
          // backgroundColor: "rgba(182, 88, 249, 0.4)",
          borderColor: 'rgba(182, 88, 249, 1)',
          borderCapStyle: 'butt',
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: 'miter',
          pointBorderColor: 'rgba(182, 88, 249, 1)',
          pointBackgroundColor: '#fff',
          pointBorderWidth: 1,
          pointHoverRadius: 4,
          pointHoverBackgroundColor: 'rgba(182, 88, 249, 1)',
          pointHoverBorderColor: 'rgba(182, 88, 249, 1)',
          pointHoverBorderWidth: 2,
          pointRadius: 2,
          pointHitRadius: 5,
          data: this.activeUsersData,
          spanGaps: false
        }]
      };
      var options = {
        maintainAspectRatio: true,
        responsive: true,
        title: {
          text: 'Daily Active Users',
          display: true,
          position: 'top'
        },
        legend: {
          display: false
        },
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true
            },
            gridLines: {
              display: false // drawBorder: true,
              // drawOnChartArea: false,
              // drawTicks: true

            }
          }],
          xAxes: [{
            barThickness: 15,
            ticks: {
              autoSkip: false
            },
            gridLines: {
              display: false // drawBorder: true,
              // drawOnChartArea: false

            }
          }]
        },
        tooltips: {
          callbacks: {
            title: function title(tip) {
              return tip[0].xLabel;
            },
            label: function label(tip) {
              return "".concat(tip.yLabel, " active users");
            }
          }
        }
      };
      var chart = new _chart.default(ctx, {
        type: 'bar',
        data: data,
        options: options
      });
      this.set('chart', chart);
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments); // this.drawChart();

    }
  });

  _exports.default = _default;
});