define("sidekick-portal/models/constants/program-template-slot-reminders", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.ConditionTypes = _exports.ConditionAgeTypes = void 0;
  var ConditionTypes = [{
    id: 0,
    name: 'PROGRAMTEMPLATESLOTREMINDER_CONDITIONTYPE_SHOWALWAYS',
    description: 'Show Always'
  }, {
    id: 1,
    name: 'PROGRAMTEMPLATESLOTREMINDER_CONDITIONTYPE_SHOWIF',
    description: 'Show If'
  }, {
    id: 2,
    name: 'PROGRAMTEMPLATESLOTREMINDER_CONDITIONTYPE_HIDEIF',
    description: 'Hide If'
  }];
  _exports.ConditionTypes = ConditionTypes;
  var ConditionAgeTypes = [{
    id: 0,
    name: 'PROGRAMTEMPLATESLOTREMINDER_CONDITIONAGETYPE_NONE',
    description: 'None'
  }, {
    id: 1,
    name: 'PROGRAMTEMPLATESLOTREMINDER_CONDITIONAGETYPE_SLOT',
    description: 'Slot'
  }, {
    id: 2,
    name: 'PROGRAMTEMPLATESLOTREMINDER_CONDITIONAGETYPE_ABSOLUTE',
    description: 'Absolute'
  }];
  _exports.ConditionAgeTypes = ConditionAgeTypes;
});