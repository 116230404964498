define("sidekick-portal/models/constants/program", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.programBehaviours = void 0;
  var programBehaviours = Object.freeze([{
    id: 10,
    //PROGRAMBEHAVIOUR_INTRODUCTION
    name: 'Introduction Program',
    description: 'The special introduction program gets this behaviour'
  }, {
    id: 20,
    //PROGRAMBEHAVIOUR_INDIVIDUAL
    name: 'Individual Program',
    description: 'Individual programs are treated differently in various ways'
  }]);
  _exports.programBehaviours = programBehaviours;
});