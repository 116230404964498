define("sidekick-portal/admin/users/recent/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LeoF+yaK",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"container-fluid px-4\"],[8],[0,\"\\n  \"],[7,\"h3\",true],[10,\"class\",\"mb-3 mt-2\"],[8],[0,\"Recently created users\"],[9],[0,\"\\n  \"],[1,[28,\"partner-users\",null,[[\"users\",\"includeLocale\",\"includeExternalSystem\"],[[24,[\"model\",\"users\"]],true,true]]],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sidekick-portal/admin/users/recent/template.hbs"
    }
  });

  _exports.default = _default;
});