define("sidekick-portal/templates/list-views/single", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "z/qPkrxA",
    "block": "{\"symbols\":[],\"statements\":[[1,[22,\"outlet\"],false],[0,\"\\n\\n\"],[5,\"list-views/view-edit\",[],[[\"@view\",\"@saveView\",\"@removeView\",\"@addListColumn\",\"@removeListColumn\",\"@changeListColumnOrder\"],[[23,0,[\"model\"]],[23,0,[\"saveView\"]],[23,0,[\"removeView\"]],[23,0,[\"addListColumn\"]],[23,0,[\"removeListColumn\"]],[23,0,[\"changeListColumnOrder\"]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sidekick-portal/templates/list-views/single.hbs"
    }
  });

  _exports.default = _default;
});