define("sidekick-portal/models/badge-template-content", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    locale: _emberData.default.attr('string'),
    title: _emberData.default.attr('string'),
    detail: _emberData.default.attr('string'),
    footer: _emberData.default.attr('string'),
    imageUrl: _emberData.default.attr('string'),
    badgeTemplate: _emberData.default.belongsTo('badge-template', {
      reverse: 'contents'
    })
  });

  _exports.default = _default;
});