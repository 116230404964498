define("sidekick-portal/adapters/icon", ["exports", "sidekick-portal/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    urlForDeleteRecord: function urlForDeleteRecord(id) {
      return "".concat(this.host, "/admin/contentitems/").concat(id);
    }
  });

  _exports.default = _default;
});