define("sidekick-portal/components/program-template/edit/slot/paths/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "x5y4IQ01",
    "block": "{\"symbols\":[\"path\",\"@slot\",\"@deletePath\",\"&default\",\"@addPath\",\"@paths\"],\"statements\":[[14,4],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"w-100\"],[8],[0,\"\\n  \"],[5,\"bs-button\",[],[[\"@type\",\"@onClick\",\"@size\"],[\"primary\",[23,5,[]],\"sm\"]],{\"statements\":[[0,\"\\n    Add path\\n  \"]],\"parameters\":[]}],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[4,\"each\",[[23,6,[]]],null,{\"statements\":[[0,\"  \"],[5,\"program-template/edit/slot/paths/single\",[],[[\"@slot\",\"@path\",\"@userSettingTypes\",\"@PathTypes\",\"@programTemplates\",\"@deletePath\"],[[23,2,[]],[23,1,[]],[23,0,[\"userSettingTypes\"]],[23,0,[\"PathTypes\"]],[23,0,[\"programTemplates\"]],[23,3,[]]]]],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sidekick-portal/components/program-template/edit/slot/paths/template.hbs"
    }
  });

  _exports.default = _default;
});