define("sidekick-portal/components/community/template-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{yield}}
  
  <h5 class="mb-3 mt-2">Community Templates</h5>
  
  <div class="community-template-list">
    <div class="template-item header">
      <div class="thumbnail"></div>
      <div class="name">Internal Name</div>
      <div class="title">Title</div>
    </div>
    {{#each @communityTemplates as |template|}}
      {{#unless template.isNew}}
        <div class="template-item" role="button" onclick={{action @goToTemplate template.id}}>
          <div class="thumbnail">
            <img src={{template.imageUrl}} alt="">
          </div>
          <div class="name">
            {{template.name}}
          </div>
          <div class="title">
            {{template.title}}
          </div>
        </div>
      {{/unless}}
    {{/each}}
  </div>
  
  <LinkTo @route="communities.templates.single" @model="new" class="mt-2 btn btn-sm btn-primary">Create new Community Template</LinkTo>
  
  */
  {
    id: "+4FJ14u3",
    block: "{\"symbols\":[\"template\",\"@goToTemplate\",\"&default\",\"@communityTemplates\"],\"statements\":[[14,3],[0,\"\\n\\n\"],[7,\"h5\",true],[10,\"class\",\"mb-3 mt-2\"],[8],[0,\"Community Templates\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"community-template-list\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"template-item header\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"thumbnail\"],[8],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"name\"],[8],[0,\"Internal Name\"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"title\"],[8],[0,\"Title\"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[4,\"each\",[[23,4,[]]],null,{\"statements\":[[4,\"unless\",[[23,1,[\"isNew\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\",true],[10,\"class\",\"template-item\"],[10,\"role\",\"button\"],[11,\"onclick\",[28,\"action\",[[23,0,[]],[23,2,[]],[23,1,[\"id\"]]],null]],[8],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"thumbnail\"],[8],[0,\"\\n          \"],[7,\"img\",true],[11,\"src\",[23,1,[\"imageUrl\"]]],[10,\"alt\",\"\"],[8],[9],[0,\"\\n        \"],[9],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"name\"],[8],[0,\"\\n          \"],[1,[23,1,[\"name\"]],false],[0,\"\\n        \"],[9],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"title\"],[8],[0,\"\\n          \"],[1,[23,1,[\"title\"]],false],[0,\"\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null],[9],[0,\"\\n\\n\"],[5,\"link-to\",[[12,\"class\",\"mt-2 btn btn-sm btn-primary\"]],[[\"@route\",\"@model\"],[\"communities.templates.single\",\"new\"]],{\"statements\":[[0,\"Create new Community Template\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    meta: {
      moduleName: "sidekick-portal/components/community/template-list.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});