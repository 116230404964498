define("sidekick-portal/admin/cms/single/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    backend: Ember.inject.service(),
    beforeModel: function beforeModel() {
      this.controllerFor('application').set('isFetchingData', true);
    },
    model: function model(params) {
      var parentModel = this.modelFor('admin/cms');
      var contentItem = null;

      if (params.item_id === 'new') {
        contentItem = this.store.createRecord('content-item');
      } else {
        contentItem = this.store.findRecord('content-item', params.item_id);
      }

      return Ember.RSVP.hash({
        item: contentItem,
        tags: parentModel.tags,
        items: parentModel.items,
        availableContentTypes: parentModel.contentTypes,
        missions: this.store.findAll('mission')
      });
    },
    afterModel: function afterModel() {
      this.controllerFor('application').set('isFetchingData', false);
    }
  });

  _exports.default = _default;
});