define("sidekick-portal/models/feed-post", ["exports", "ember-data", "ember-data-model-fragments"], function (_exports, _emberData, _emberDataModelFragments) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* app/models/post.js */
  var _default = _emberDataModelFragments.default.Fragment.extend({
    text: _emberData.default.attr('string'),
    feed: _emberData.default.belongsTo('feed')
  });

  _exports.default = _default;
});