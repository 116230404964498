define("sidekick-portal/models/constants/countries", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.availableCountries = void 0;
  var availableCountries = Object.freeze([// TODO: United Kingdom/Great Britain 
  {
    name: 'Iceland',
    id: 'is'
  }, {
    name: 'Australia',
    id: 'au'
  }, {
    name: 'Austria',
    id: 'at'
  }, {
    name: 'China',
    id: 'zh'
  }, {
    name: 'Denmark',
    id: 'da'
  }, {
    name: 'Finland',
    id: 'fi'
  }, {
    name: 'France',
    id: 'fr'
  }, {
    name: 'Germany',
    id: 'de'
  }, {
    name: 'Portugal',
    id: 'pt'
  }, {
    name: 'Spain',
    id: 'es'
  }, {
    name: 'Sweden',
    id: 'sv'
  }, {
    name: 'Switzerland',
    id: 'ch'
  }, {
    name: 'United Kingdom',
    id: 'gb'
  }, {
    name: 'United States',
    id: 'us'
  }, {
    name: 'Ireland',
    id: 'ie'
  }, {
    name: 'Netherlands',
    id: 'nl'
  }, {
    name: 'Belgium',
    id: 'be'
  }]);
  _exports.availableCountries = availableCountries;
});