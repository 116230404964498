define("sidekick-portal/components/program-template/edit/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    flashMessages: Ember.inject.service(),
    session: Ember.inject.service(),
    actions: {
      setEditingModeForEntity: function setEditingModeForEntity(entity) {
        entity.toggleProperty('editMode');
      },
      deleteSlot: function deleteSlot(slot) {
        var index = Ember.get(this, 'programTemplate.slots').indexOf(slot);
        Ember.get(this, 'programTemplate.slots').removeObject(slot);
        Ember.set(this, 'selectedSlot', undefined);
        Ember.get(this, 'programTemplate.slots').forEach(function (slot, idx) {
          if (idx >= index) {
            var slotNumber = Ember.get(slot, 'slotNumber');
            Ember.set(slot, 'slotNumber', slotNumber - 1);
            Ember.set(slot, 'isDirty', true);
          }
        });
      },
      handleOverwrite: function handleOverwrite() {
        this.programTemplate.modifiedDate = null;
        this.saveTemplate();
        this.programTemplate.openConflictModal = false;
      }
    }
  });

  _exports.default = _default;
});