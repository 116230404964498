define("sidekick-portal/components/cms/content-list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "F3EPxAy3",
    "block": "{\"symbols\":[\"@items\",\"@tableFilter\",\"@currentPage\",\"&default\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"container-fluid px-4\"],[8],[0,\"\\n  \"],[5,\"table-title\",[],[[\"@title\",\"@createNewRoute\"],[\"Content Items\",\"admin.cms.single\"]]],[0,\"\\n\\n  \"],[5,\"models-table\",[[12,\"class\",\"emtable\"]],[[\"@data\",\"@columns\",\"@filterString\",\"@currentPageNumber\",\"@filteringIgnoreCase\",\"@useFilteringByColumns\",\"@multipleColumnsSorting\",\"@useNumericPagination\",\"@pageSize\",\"@focusGlobalFilter\",\"@themeInstance\"],[[23,1,[]],[23,0,[\"columnsForTable\"]],[23,2,[]],[23,3,[]],true,false,false,true,50,true,[23,0,[\"customThemeInstance\"]]]]],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[14,4],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sidekick-portal/components/cms/content-list/template.hbs"
    }
  });

  _exports.default = _default;
});