define("sidekick-portal/models/constants/locales", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.availableLocales = void 0;
  var availableLocales = Object.freeze([{
    name: 'English',
    id: 'en',
    locale: 'en'
  }, {
    name: 'Icelandic',
    id: 'is',
    locale: 'is'
  },
  /* UNSUPPORTED
  {
    name: 'Chinese',
    id: 'zh',
    locale: 'zh'
  },
  /* UNSUPPORTED
  {
    name: 'Danish',
    id: 'da',
    locale: 'da'
  },
  */
  {
    name: 'Dutch (Belgium)',
    id: 'nl-BE',
    locale: 'nl-BE'
  }, {
    name: 'Dutch (Netherlands)',
    id: 'nl-NL',
    locale: 'nl-NL'
  },
  /* UNSUPPORTED
  {
    name: 'English (Australia)',
    id: 'en-AU',
    locale: 'en-AU'
  },
  */
  {
    name: 'English (Ireland)',
    id: 'en-IE',
    locale: 'en-IE'
  }, {
    name: 'Finnish',
    id: 'fi',
    locale: 'fi'
  }, {
    name: 'French',
    id: 'fr',
    locale: 'fr'
  }, {
    name: 'French (Belgium)',
    id: 'fr-BE',
    locale: 'fr-BE'
  }, {
    name: 'French (Switzerland)',
    id: 'fr-CH',
    locale: 'fr-CH'
  }, {
    name: 'German',
    id: 'de',
    locale: 'de'
  }, {
    name: 'German (Austria)',
    id: 'de-AT',
    locale: 'de-AT'
  }, {
    name: 'German (Switzerland)',
    id: 'de-CH',
    locale: 'de-CH'
  },
  /* UNSUPPORTED
  {
    name: 'Portuguese',
    id: 'pt',
    locale: 'pt'
  },
  */
  {
    name: 'Spanish (Spain)',
    id: 'es-ES',
    locale: 'es-ES'
  }, {
    name: 'Swedish',
    id: 'sv',
    locale: 'sv'
  }]);
  _exports.availableLocales = availableLocales;
});