define("sidekick-portal/admin/program-templates/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    flashMessages: Ember.inject.service(),
    beforeModel: function beforeModel() {
      this.controllerFor('application').set('isFetchingData', true);

      this._super.apply(this, arguments);
    },
    model: function model() {
      return Ember.RSVP.hash({
        missions: this.store.findAll('mission'),
        programTemplates: this.store.findAll('program-template'),
        contentTypes: this.store.findAll('content-type') //Needs to be available for slots

      });
    },
    actions: {
      refresh: function refresh() {
        this.store.unloadAll();
        return this.refresh();
      }
    }
  });

  _exports.default = _default;
});