define("sidekick-portal/models/content-type", ["exports", "ember-data", "ember-data-model-fragments"], function (_exports, _emberData, _emberDataModelFragments) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    contentTypeFields: _emberDataModelFragments.default.fragmentArray('content-type-field'),
    emailTemplate: _emberData.default.attr('string')
  });

  _exports.default = _default;
});