define("sidekick-portal/components/button-delete-with-confirm/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'span',
    attributeBindings: ['disabled', 'small'],
    session: Ember.inject.service(),
    confirmationText: 'Are you sure?',
    hidden: Ember.computed({
      get: function get() {
        return parseInt(this.get('session.data.authenticated.user.privileges')) < 100;
      }
    })
  });

  _exports.default = _default;
});