define("sidekick-portal/models/constants/user-program-group", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.GroupBehaviour = void 0;
  var GroupBehaviour = Object.freeze([{
    name: 'No New Users',
    id: 1,
    description: 'USERPROGRAMGROUP_BEHAVIOUR_NONEWUSERS'
  }]);
  _exports.GroupBehaviour = GroupBehaviour;
});