define("sidekick-portal/models/program-template", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships", "ember-data-model-fragments", "sidekick-portal/models/constants/program-templates"], function (_exports, _model, _attr, _relationships, _emberDataModelFragments, _programTemplates) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    name: (0, _attr.default)('string'),
    description: (0, _attr.default)('string'),
    slotDuration: (0, _attr.default)('number'),
    //Seconds
    delayBetweenSlots: (0, _attr.default)('number'),
    //Delay(in seconds) between each slot
    behaviours: (0, _attr.default)('behaviours', {
      behaviours: _programTemplates.programTemplateBehaviours
    }),
    slots: _emberDataModelFragments.default.fragmentArray('program-template-slot'),
    programs: (0, _relationships.hasMany)('program', {
      inverse: 'template'
    }),
    isActive: (0, _attr.default)('boolean'),
    createdDate: (0, _attr.default)('date'),
    modifiedDate: (0, _attr.default)('date'),
    programCatalogContentItem: (0, _relationships.belongsTo)('content-item'),
    // Potential version conlfict handling
    openConflictModal: (0, _attr.default)('boolean'),
    modifiedByUserName: (0, _attr.default)('string'),
    modifiedByUserDate: (0, _attr.default)('string'),
    isBeingEdited: Ember.computed('nameBeingEdited', 'hasDirtyAttributes', 'slots.hasDirtyAttributes', 'saved', function () {
      var dirtyChildren = false;
      this.get('slots').forEach(function (child) {
        if (child.hasDirtyAttributes) {
          dirtyChildren = true;
        }
      });
      return dirtyChildren || Ember.get(this, 'nameBeingEdited') || Ember.get(this, 'hasDirtyAttributes');
    }),
    slotDurationDays: Ember.computed('slotDuration', {
      get: function get() {
        if (typeof this.slotDuration === 'number') {
          return parseInt(this.slotDuration / 86400);
        } else {
          return 0;
        }
      },
      set: function set(key, value) {
        if (isNaN(value)) {
          this.set('slotDuration', 0);
          return 0;
        } else {
          this.set('slotDuration', parseInt(value * 86400));
          return value;
        }
      }
    }),
    delayBetweenSlotsDays: Ember.computed('delayBetweenSlots', {
      get: function get() {
        if (typeof this.delayBetweenSlots === 'number') {
          return parseInt(this.delayBetweenSlots / 86400);
        } else {
          return 0;
        }
      },
      set: function set(key, value) {
        if (isNaN(value)) {
          this.set('delayBetweenSlots', 0);
          return 0;
        } else {
          this.set('delayBetweenSlots', parseInt(value * 86400));
          return value;
        }
      }
    }),
    duration: Ember.computed('slotDurationDays', 'delayBetweenSlotsDays', {
      get: function get() {
        var days = this.slotDurationDays;
        var delay = this.delayBetweenSlotsDays;
        var slotCount = this.get('slots.length');
        var duration = days * slotCount + delay * (slotCount - 1);
        return duration;
      }
    }),
    save: function save() {
      Ember.get(this, 'slots').forEach(function (slot) {
        if (Ember.get(slot, 'hasDirtyAttributes')) {
          Ember.set(slot, 'isDirty', true);
        }
      });
      return this._super.apply(this, arguments);
    },
    copyGoalsBetweenSlots: function copyGoalsBetweenSlots(sourceSlotNumber, targetSlotNumber) {
      console.info("Copying goals from slot ".concat(sourceSlotNumber, " to ").concat(targetSlotNumber));

      if (Ember.isBlank(sourceSlotNumber) || Ember.isBlank(targetSlotNumber) || sourceSlotNumber < 1 || sourceSlotNumber == targetSlotNumber) {
        console.error('Bad parameters while trying to copy goals between slots');
        return;
      }

      var sourceSlot = this.slots.find(function (x) {
        return x.slotNumber === sourceSlotNumber;
      });
      var targetSlot = this.slots.find(function (x) {
        return x.slotNumber === targetSlotNumber;
      });
      Ember.set(targetSlot, 'goals', sourceSlot.goals.copy());
      return true;
    }
  });

  _exports.default = _default;
});