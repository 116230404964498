define("sidekick-portal/admin/cms/list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "iDiEARmh",
    "block": "{\"symbols\":[],\"statements\":[[5,\"cms/content-list\",[],[[\"@items\",\"@tableFilter\",\"@currentPage\"],[[24,[\"model\",\"items\"]],[23,0,[\"tableFilter\"]],[23,0,[\"currentPage\"]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sidekick-portal/admin/cms/list/template.hbs"
    }
  });

  _exports.default = _default;
});