define("sidekick-portal/models/constants/cms", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.valueTypes = _exports.localeStatus = _exports.displayTypes = _exports.descriptionParts = _exports.contentTypeValues = _exports.contentItemPlacements = _exports.categories = _exports.availableLocales = _exports.ContentTypes = void 0;
  var localeStatus = Object.freeze([{
    name: 'CONTENTITEMLOCALE_STATUS_UNKNOWN',
    id: 0,
    description: 'Unknown'
  }, {
    name: 'CONTENTITEMLOCALE_STATUS_PUBLISHED',
    id: 1,
    description: 'Published'
  }, {
    name: 'CONTENTITEMLOCALE_STATUS_NOTPUBLISHED',
    id: 2,
    description: 'Not Published'
  }]);
  _exports.localeStatus = localeStatus;
  var valueTypes = Object.freeze([{
    name: 'CONTENTTYPE_VALUETYPE_STRING',
    id: 1,
    description: 'String'
  }, {
    name: 'CONTENTTYPE_VALUETYPE_UPLOAD',
    id: 2,
    description: 'Upload'
  }]);
  _exports.valueTypes = valueTypes;
  var categories = Object.freeze([{
    name: 'CATEGORY_FOOD',
    id: 101,
    description: 'Food'
  }, {
    name: 'CATEGORY_MOVE',
    id: 102,
    description: 'Move'
  }, {
    name: 'CATEGORY_ZEN',
    id: 103,
    description: 'Mind'
  }, {
    name: 'CATEGORY_MEDICAL',
    id: 104,
    description: 'Clinic'
  }]);
  _exports.categories = categories;
  var contentTypeValues = Object.freeze([{
    name: 'CONTENTTYPE_VALUETYPE_STRING',
    id: 1,
    description: 'String'
  }, {
    name: 'CONTENTTYPE_VALUETYPE_UPLOAD',
    id: 2,
    description: 'Upload URL'
  }]);
  _exports.contentTypeValues = contentTypeValues;
  var displayTypes = Object.freeze([{
    name: 'CONTENTTYPE_DISPLAYTYPE_DEFAULT',
    id: 1,
    description: 'Default'
  }, {
    name: 'CONTENTTYPE_DISPLAYTYPE_MARKDOWN',
    id: 2,
    description: 'Markdown'
  }]);
  _exports.displayTypes = displayTypes;
  var descriptionParts = Object.freeze([//{ name: 'OPENMISSION_TEXT_TYPE_TEXT', id: 1, description: 'text' },
  {
    name: 'OPENMISSION_TEXT_TYPE_MISSION',
    id: 2,
    description: 'mission'
  }, {
    name: 'OPENMISSION_TEXT_TYPE_SURVEY',
    id: 3,
    description: 'survey'
  }, {
    name: 'OPENMISSION_TEXT_TYPE_VIDEO',
    id: 4,
    description: 'video'
  }, {
    name: 'OPENMISSION_TEXT_TYPE_MAPS',
    id: 5,
    description: 'maps'
  }, {
    name: 'OPENMISSION_TEXT_TYPE_TELEPHONE',
    id: 6,
    description: 'telephone'
  }, {
    name: 'OPENMISSION_TEXT_TYPE_HTTP',
    id: 7,
    description: 'http'
  }, {
    name: 'OPENMISSION_TEXT_TYPE_MEDICATION',
    id: 8,
    description: 'medication'
  }, {
    name: 'OPENMISSION_TEXT_TYPE_MESSAGES',
    id: 9,
    description: 'messages'
  }, {
    name: 'OPENMISSION_TEXT_TYPE_SOURCES',
    id: 10,
    description: 'sources'
  }, {
    name: 'OPENMISSION_TEXT_TYPE_PIN',
    id: 11,
    description: 'pin'
  }, {
    name: 'OPENMISSION_TEXT_TYPE_PROFILE',
    id: 12,
    description: 'profile'
  }, {
    name: 'OPENMISSION_TEXT_TYPE_SCALE',
    id: 13,
    description: 'scale'
  }, {
    name: 'OPENMISSION_TEXT_TYPE_EDUCATION',
    id: 14,
    description: 'education'
  }, {
    name: 'OPENMISSION_TEXT_TYPE_EDUCATION',
    id: 15,
    description: 'food'
  }, {
    name: 'OPENMISSION_TEXT_TYPE_EDUCATION',
    id: 16,
    description: 'move'
  }, {
    name: 'OPENMISSION_TEXT_TYPE_EDUCATION',
    id: 17,
    description: 'mind'
  }]);
  _exports.descriptionParts = descriptionParts;
  var contentItemPlacements = Object.freeze([{
    name: 'CONTENTITEM_PLACEMENT_TOP',
    id: 1,
    description: 'Top'
  }, {
    name: 'CONTENTITEM_PLACEMENT_MIDDLE',
    id: 2,
    description: 'Middle'
  }, {
    name: 'CONTENTITEM_PLACEMENT_BOTTOM',
    id: 3,
    description: 'Bottom'
  }, {
    name: 'CONTENTITEM_PLACEMENT_SPLIT',
    id: 4,
    description: 'Split'
  }]);
  _exports.contentItemPlacements = contentItemPlacements;
  var ContentTypes = {
    CONTENTTYPE_VIDEO: 2,
    CONTENTTYPE_MESSAGE: 4,
    CONTENTTYPE_PROGRAMCATALOGITEM: 5,
    CONTENTTYPE_PROGRAMTEMPLATESLOT: 6,
    CONTENTTYPE_ICON: 7,
    CONTENTTYPE_COMMUNITY: 8,
    CONTENTTYPE_OPENMISSION: 9,
    CONTENTTYPE_STRING: 10,
    CONTENTTYPE_SURVEY: 11,
    CONTENTTYPE_MARKDOWNSTRING: 12,
    CONTENTTYPE_VALUE_PRESELECTION: 13,
    CONTENTTYPE_INTERACTIVE_IMAGE: 14,
    CONTENTTYPE_INTERACTIVE_IMAGE_ITEM: 15,
    CONTENTTYPE_IMAGE_CARD: 16,
    CONTENTTYPE_IMAGE_CARD_ITEM: 17,
    CONTENTTYPE_AUDIO_DELIVERED_TEXT: 18,
    CONTENTTYPE_QUIZ_QUESTION: 19,
    CONTENTTYPE_QUIZ_ANSWER: 20,
    CONTENTTYPE_QUIZ_RATING: 21,
    CONTENTTYPE_LIST_COLUMN: 22,
    CONTENTTYPE_SURVEY_RATING: 23
  }; //Temporary solution in order to use different locales in CMS than in other places

  _exports.ContentTypes = ContentTypes;
  var availableLocales = Object.freeze([{
    name: 'English',
    id: 'en',
    locale: 'en'
  }, {
    name: 'Icelandic',
    id: 'is',
    locale: 'is'
  }, {
    name: 'Dutch (Belgium)',
    id: 'nl-BE',
    locale: 'nl-BE'
  }, {
    // CMS Specific
    name: 'Dutch (Netherlands)',
    id: 'nl',
    locale: 'nl'
  },
  /* UNSUPPORTED
  {
    name: 'English (Australia)',
    id: 'en-AU',
    locale: 'en-AU'
  },
  */
  {
    name: 'English (Ireland)',
    id: 'en-IE',
    locale: 'en-IE'
  }, {
    name: 'Finnish',
    id: 'fi',
    locale: 'fi'
  }, {
    name: 'French',
    id: 'fr',
    locale: 'fr'
  }, {
    name: 'French (Belgium)',
    id: 'fr-BE',
    locale: 'fr-BE'
  }, {
    name: 'French (Switzerland)',
    id: 'fr-CH',
    locale: 'fr-CH'
  }, {
    name: 'German',
    id: 'de',
    locale: 'de'
  }, {
    name: 'German (Austria)',
    id: 'de-AT',
    locale: 'de-AT'
  }, {
    name: 'German (Switzerland)',
    id: 'de-CH',
    locale: 'de-CH'
  }, {
    name: 'Spanish (Spain)',
    id: 'es-ES',
    locale: 'es-ES'
  }, {
    name: 'Swedish',
    id: 'sv',
    locale: 'sv'
  }]);
  _exports.availableLocales = availableLocales;
});