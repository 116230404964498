define("sidekick-portal/admin/cms/single/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "AoyOPniS",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"cms/content-item\",null,[[\"item\",\"tags\",\"items\",\"missions\",\"availableContentTypes\",\"saveItem\",\"getUploadUrl\",\"prepareUploadUrl\",\"deleteItem\"],[[24,[\"model\",\"item\"]],[24,[\"model\",\"tags\"]],[24,[\"model\",\"items\"]],[24,[\"model\",\"missions\"]],[24,[\"model\",\"availableContentTypes\"]],[28,\"action\",[[23,0,[]],\"saveItem\"],null],[28,\"action\",[[23,0,[]],\"getUploadUrl\"],null],[28,\"action\",[[23,0,[]],\"prepareUploadUrl\"],null],[28,\"action\",[[23,0,[]],\"deleteItem\"],null]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sidekick-portal/admin/cms/single/template.hbs"
    }
  });

  _exports.default = _default;
});