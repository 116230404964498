define("sidekick-portal/transforms/id-array", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Transform.extend({
    deserialize: function deserialize(serialized) {
      console.log("transform. deserializing ".concat(serialized));

      if (serialized === null || Ember.isBlank(serialized)) {
        return [];
      }

      return JSON.parse(serialized);
    },
    serialize: function serialize(deserialized) {
      if (deserialized === null) {
        return '';
      }

      return JSON.stringify(deserialized);
    }
  });

  _exports.default = _default;
});