define("sidekick-portal/components/button-save/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'button',
    classNameBindings: ['active', 'hidden', 'large:btn-lg', 'small:btn-sm'],
    classNames: ['btn', 'btn-primary'],
    attributeBindings: ['disabled'],
    session: Ember.inject.service(),
    hidden: Ember.computed({
      get: function get() {
        return parseInt(this.get('session.data.authenticated.user.privileges')) < 100;
      }
    }),
    click: function click() {
      this.saveEntity();
    }
  });

  _exports.default = _default;
});