define("sidekick-portal/components/program-single/component", ["exports", "ember-changeset", "sidekick-portal/validations/program-single", "ember-changeset-validations", "sidekick-portal/models/constants/locales", "sidekick-portal/models/constants/program"], function (_exports, _emberChangeset, _programSingle, _emberChangesetValidations, _locales, _program) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    ProgramSingleValidation: _programSingle.default,
    groupValid: true,
    availableLocales: _locales.availableLocales,
    programBehaviours: _program.programBehaviours,
    init: function init() {
      this._super.apply(this, arguments);

      var program = this.program;
      this.programChangeset = new _emberChangeset.default(program, (0, _emberChangesetValidations.default)(_programSingle.default), _programSingle.default);
      this.maxDate = moment.utc().add(30, 'days');
    },
    flashMessages: Ember.inject.service(),
    groupDropdownDisabled: Ember.computed('programChangeset.partner.groups.isLoaded', function () {
      if (this.get('programChangeset.partner.groups.length') === 0) {
        this.programChangeset.addError('group', 'The selected partner has no groups.');
        return true;
      } else {
        return false;
      }
    }),
    // partnerGroupsLoaded: computed('programChangeset.partner.groups.isLoaded', function() {
    //   if (isEmpty(this.get('programChangeset.partner.groups'))) {
    //     this.programChangeset.addError('group', 'The selected partner has no groups.');
    //     this.set('groupDropdownDisabled', true);
    //   } else {
    //     this.set('groupDropdownDisabled', false);
    //   }
    // }),
    filteredProgramTemplates: Ember.computed.filter('programTemplates', function (template) {
      return template.get('isActive');
    }),
    actions: {
      groupSelected: function groupSelected(group) {
        this.set('groupErrorText', '');
        this.set('programChangeset.group', null);

        if (Ember.isBlank(group)) {
          this.set('programChangeset.group', group);
        } else {
          if (!Ember.isEmpty(group.get('program.id'))) {
            this.programChangeset.addError('group', "".concat(group.get('name'), " already has a defined program."));
          } else {
            this.set('programChangeset.group', group);
          }
        }
      },
      saveProgram: function saveProgram() {
        var _this = this;

        this.programChangeset.validate();

        if (!this.get('programChangeset.isValid')) {
          this.set('saveErrorMessage', 'There are errors in the form, please fix and try again.');
        } else {
          this.set('saveErrorMessage', '');
          this.programChangeset.save().then(function () {
            _this.flashMessages.success('Program saved.');

            _this.send('toggleModal');
          }, function (error) {
            if (error.errors) {
              _this.set('saveErrorMessage', "Error saving program. ".concat(error.errors[0].message));
            } else {
              _this.set('saveErrorMessage', 'Error saving program. Check the form and try again.');
            }
          });
        }
      },
      deleteProgram: function deleteProgram() {
        this.set('showDeleteConfirm', true);
      },
      deleteConfirm: function deleteConfirm() {
        var _this2 = this;

        this.program.destroyRecord().then(function () {
          _this2.flashMessages.warning('Program deleted.');

          _this2.toggleModal();
        });
      },
      deleteCancel: function deleteCancel() {
        this.set('showDeleteConfirm', false);
      },
      toggleModal: function toggleModal() {
        if (this.get('program.isNew')) {
          this.set('program.group.program', null);
          this.programChangeset.destroy();
        } else {
          this.programChangeset.rollback();
        }

        this.toggleModal();
      },
      partnerSelected: function partnerSelected(partner) {
        this.set('groupErrorText', '');
        this.set('programChangeset.group', null);

        if (partner) {
          this.set('programChangeset.partner', partner);
        } else {
          this.set('programChangeset.partner', null);
        }
      }
    }
  });

  _exports.default = _default;
});