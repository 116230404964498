define("sidekick-portal/models/prize", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    name: _emberData.default.attr(),
    kicks: _emberData.default.attr(),
    details: _emberData.default.attr(),
    imageId: _emberData.default.attr()
  });

  _exports.default = _default;
});