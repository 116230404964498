define("sidekick-portal/templates/list-columns/single", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jZ1t2V6+",
    "block": "{\"symbols\":[],\"statements\":[[1,[22,\"outlet\"],false],[0,\"\\n\\n\"],[5,\"list-columns/column-edit\",[],[[\"@column\",\"@saveColumn\",\"@removeColumn\",\"@createContentItem\"],[[23,0,[\"model\"]],[23,0,[\"saveColumn\"]],[23,0,[\"removeColumn\"]],[23,0,[\"createContentItem\"]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sidekick-portal/templates/list-columns/single.hbs"
    }
  });

  _exports.default = _default;
});