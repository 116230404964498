define("sidekick-portal/models/surveys/constants", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.SurveyOptions = _exports.QuestionOptions = _exports.QuestionAnswerTypes = _exports.DisplayTypes = _exports.AnswerTypes = void 0;
  var DisplayTypes = [{
    id: 1,
    name: 'Single-select'
  }, {
    id: 2,
    name: 'Multi-select'
  }, {
    id: 3,
    name: 'Spinner'
  }, {
    id: 4,
    name: 'Weight'
  }, {
    id: 5,
    name: 'Single-select image'
  }, {
    id: 6,
    name: 'Multi-select image'
  }, {
    id: 7,
    name: 'Draggable scale'
  }, {
    id: 8,
    name: 'Text input'
  }, {
    id: 9,
    name: 'Discrete scale'
  }];
  _exports.DisplayTypes = DisplayTypes;
  var QuestionAnswerTypes = [{
    id: 1,
    name: 'Integer'
  }, {
    id: 2,
    name: 'Double'
  }, {
    id: 3,
    name: 'answerName'
  }];
  _exports.QuestionAnswerTypes = QuestionAnswerTypes;
  var AnswerTypes = [{
    id: 1,
    name: 'Simple'
  }, {
    id: 2,
    name: 'Range'
  }];
  _exports.AnswerTypes = AnswerTypes;
  var QuestionOptions = {
    excludeFromResults: 1,
    conditional: 2,
    displayEmbedded: 4
  };
  _exports.QuestionOptions = QuestionOptions;
  var SurveyOptions = {
    enableAnswerSeverity: 1,
    useMaxSeverityAsResult: 2,
    highAlert: 4,
    displayTextView: 8,
    oncePerDay: 16,
    noSubmitScreen: 32,
    quiz: 64,
    calcResultAsPct: 128
  };
  _exports.SurveyOptions = SurveyOptions;
});