define("sidekick-portal/models/constants/list-column", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.ListColumnTypes = void 0;
  var ListColumnTypes = [{
    id: 1,
    name: 'LISTCOLUMN_TYPE_WEIGHT',
    description: 'Weight'
  }, {
    id: 2,
    name: 'LISTCOLUMN_TYPE_USER_NAME',
    description: 'User Name'
  }, {
    id: 3,
    name: 'LISTCOLUMN_TYPE_INTAKE_SURVEYS',
    description: 'Intake Survey(s)'
  }, {
    id: 4,
    name: 'LISTCOLUMN_TYPE_SYMPTOMS',
    description: 'Symptoms'
  }, {
    id: 5,
    name: 'LISTCOLUMN_TYPE_PROGRAM_STATUS',
    description: 'Program Status'
  }];
  _exports.ListColumnTypes = ListColumnTypes;
});