define("sidekick-portal/components/user-search-multiple/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    _queryUsers: function _queryUsers(query, resolve, reject) {
      var filter = {
        filter: query
      };

      if (this.privilegesLevel) {
        filter.privilegesLevel = this.privilegesLevel;
      }

      if (this.coachesOnly) {
        filter.coachesOnly = this.coachesOnly;
      }

      return this.store.query('user', filter).then(function (response) {
        return resolve(response);
      }, reject);
    },
    actions: {
      queryUsers: function queryUsers(query) {
        var _this = this;

        if (Ember.isBlank(query) || query.length < 2) {
          return [];
        }

        return new Ember.RSVP.Promise(function (resolve, reject) {
          Ember.run.debounce(_this, _this._queryUsers, query, resolve, reject, 500);
        });
      }
    }
  });

  _exports.default = _default;
});