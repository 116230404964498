define("sidekick-portal/components/partner-users/component", ["exports", "sidekick-portal/components/table-themes/customTheme", "sidekick-portal/models/constants/user"], function (_exports, _customTheme, _user) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    ExternalTypes: _user.externalTypes.sortBy('description'),
    selectedExternalType: null,
    filteredUsers: Ember.computed('selectedExternalType', 'users.[]', {
      get: function get() {
        if (this.selectedExternalType == null) {
          return this.users;
        } else {
          return this.users.filterBy('externalType.id', this.selectedExternalType.id);
        }
      }
    }),
    customThemeInstance: _customTheme.default.create(),
    columns: Ember.computed(function () {
      var columns = [{
        propertyName: 'id',
        title: 'ID',
        routeName: 'admin.users.single'
      }, {
        propertyName: 'name',
        title: 'Name'
      }, {
        propertyName: 'email',
        title: 'Email'
      }, {
        propertyName: 'createdDateText',
        title: 'Created date',
        sortedBy: 'createdDate',
        sortPrecedence: 1,
        sortDirection: 'desc'
      }];

      if (this.includeScore) {
        columns.push({
          component: 'table-components/user-total-score',
          title: 'Total score'
        });
      }

      if (this.includeLocale) {
        columns.push({
          propertyName: 'locale',
          title: 'Locale'
        });
      }

      if (this.includeExternalSystem) {
        columns.push({
          propertyName: 'externalType.description',
          title: 'External system'
        });
      }

      return columns;
    })
  });

  _exports.default = _default;
});