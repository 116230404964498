define("sidekick-portal/models/feed", ["exports", "ember-data", "ember-data-model-fragments"], function (_exports, _emberData, _emberDataModelFragments) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    createdDate: _emberData.default.attr('date'),
    modifiedDate: _emberData.default.attr('date'),
    user: _emberDataModelFragments.default.fragment('feed-user'),
    type: _emberData.default.attr('number'),
    post: _emberDataModelFragments.default.fragment('feed-post'),
    likes: _emberDataModelFragments.default.fragmentArray('feed-like'),
    comments: _emberData.default.hasMany('feed-comment')
  });

  _exports.default = _default;
});