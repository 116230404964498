define("sidekick-portal/models/feed-like", ["exports", "ember-data", "ember-data-model-fragments"], function (_exports, _emberData, _emberDataModelFragments) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* app/models/like.js */
  var _default = _emberDataModelFragments.default.Fragment.extend({
    user: _emberDataModelFragments.default.fragment('feed-user'),
    likedDate: _emberData.default.attr('string'),
    feed: _emberData.default.belongsTo('feed')
  });

  _exports.default = _default;
});