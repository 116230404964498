define("sidekick-portal/components/program-template/header/component", ["exports", "sidekick-portal/models/constants/program-templates"], function (_exports, _programTemplates) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    templateBehaviours: _programTemplates.programTemplateBehaviours,
    programCatalogContentItems: Ember.computed('contentItems', function () {
      return this.contentItems.filter(function (x) {
        return Ember.get(x, 'contentType.name') === 'programcatalogitem';
      });
    }),
    actions: {
      toggleEdit: function toggleEdit() {
        this.set('programTemplate.nameBeingEdited', true);
      }
    }
  });

  _exports.default = _default;
});