define("sidekick-portal/admin/program-templates/details/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9TEAyscT",
    "block": "{\"symbols\":[],\"statements\":[[1,[22,\"outlet\"],false],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"container-fluid px-4\"],[8],[0,\"\\n  \"],[1,[28,\"program-template/edit\",null,[[\"programTemplate\",\"revertCancel\",\"revertConfirm\",\"saveTemplate\",\"revertChanges\",\"expireTemplate\",\"activateTemplate\",\"showRevertConfirm\",\"setEditingModeForEntity\",\"addReminder\",\"addPath\",\"missions\",\"addSlot\",\"contentItems\",\"icons\",\"addContentItem\"],[[24,[\"model\",\"programTemplate\"]],[28,\"action\",[[23,0,[]],\"revertCancel\"],null],[28,\"action\",[[23,0,[]],\"revertConfirm\"],null],[28,\"action\",[[23,0,[]],\"saveTemplate\",[24,[\"model\",\"programTemplate\"]]],null],[28,\"action\",[[23,0,[]],\"revertChanges\",[24,[\"model\",\"programTemplate\"]]],null],[28,\"action\",[[23,0,[]],\"expireTemplate\"],null],[28,\"action\",[[23,0,[]],\"activateTemplate\"],null],[24,[\"showRevertConfirm\"]],\"setEditingModeForEntity\",[28,\"action\",[[23,0,[]],\"addReminder\"],null],[28,\"action\",[[23,0,[]],\"addPath\"],null],[24,[\"model\",\"missions\"]],[28,\"action\",[[23,0,[]],\"addSlot\"],null],[24,[\"model\",\"contentItems\"]],[24,[\"model\",\"icons\"]],[28,\"action\",[[23,0,[]],\"addContentItem\"],null]]]],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sidekick-portal/admin/program-templates/details/template.hbs"
    }
  });

  _exports.default = _default;
});