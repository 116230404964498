define("sidekick-portal/admin/retention-messages/single/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "l8AgJLUt",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"retention/message-profile\",null,[[\"profile\",\"programTemplates\",\"contentItems\",\"saveProfile\",\"deleteProfile\"],[[24,[\"model\",\"messageProfile\"]],[24,[\"model\",\"programTemplates\"]],[24,[\"model\",\"contentItems\"]],[28,\"action\",[[23,0,[]],\"saveProfile\"],null],[28,\"action\",[[23,0,[]],\"deleteProfile\"],null]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sidekick-portal/admin/retention-messages/single/template.hbs"
    }
  });

  _exports.default = _default;
});