define("sidekick-portal/serializers/system-message", ["exports", "sidekick-portal/serializers/application", "ember-data"], function (_exports, _application, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend(_emberData.default.EmbeddedRecordsMixin, {
    attrs: {
      contents: {
        embedded: 'always'
      },
      recipientPartners: 'recipientPartnerIds'
    }
  });

  _exports.default = _default;
});