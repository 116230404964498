define("sidekick-portal/admin/cms/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    backend: Ember.inject.service('backend'),
    beforeModel: function beforeModel() {
      this.controllerFor('application').set('isFetchingData', true);
      return this.store.findAll('content-type');
    },
    model: function model() {
      return Ember.RSVP.hash({
        items: this.store.findAll('content-item'),
        contentTypes: this.store.findAll('content-type'),
        tags: []
      });
    },
    afterModel: function afterModel(model) {
      this.backend.getJSONData('admin/contenttags').then(function (response) {
        if (response.tags) {
          model.tags.addObjects(response.tags);
        }
      });
      this.controllerFor('application').set('isFetchingData', false);
    }
  });

  _exports.default = _default;
});