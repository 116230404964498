define("sidekick-portal/serializers/retention-message-profile", ["exports", "sidekick-portal/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    attrs: {
      contentItem: 'contentItemId',
      programTemplates: 'programTemplateIds',
      triggerAction: 'trigger'
    }
  });

  _exports.default = _default;
});