define("sidekick-portal/components/table-components/cms-publish-status/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2M93K8kq",
    "block": "{\"symbols\":[\"locale\",\"@record\"],\"statements\":[[4,\"each\",[[23,2,[\"contentItemLocales\"]]],null,{\"statements\":[[0,\"  \"],[7,\"span\",true],[11,\"class\",[29,[\"text-uppercase badge badge-pill \",[28,\"if\",[[28,\"eq\",[[23,1,[\"status\",\"id\"]],1],null],\"badge-success\",\"badge-danger\"],null]]]],[8],[1,[23,1,[\"locale\"]],false],[9],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sidekick-portal/components/table-components/cms-publish-status/template.hbs"
    }
  });

  _exports.default = _default;
});