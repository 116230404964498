define("sidekick-portal/components/table-components/item-count-with-tooltip/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6PcSiA23",
    "block": "{\"symbols\":[\"item\",\"@record\"],\"statements\":[[4,\"if\",[[23,0,[\"columnValue\"]]],null,{\"statements\":[[0,\"  \"],[1,[23,0,[\"columnValue\",\"length\"]],false],[0,\"\\n  \"],[7,\"button\",true],[11,\"id\",[28,\"concat\",[\"info-\",[23,2,[\"id\"]],\"-\",[23,0,[\"columnValue\"]]],null]],[10,\"type\",\"button\"],[8],[0,\"\\n    \"],[7,\"i\",true],[10,\"class\",\"far fa-question-circle\"],[8],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[5,\"ember-tooltip\",[],[[\"@targetId\"],[[28,\"concat\",[\"info-\",[23,2,[\"id\"]],\"-\",[23,0,[\"columnValue\"]]],null]]],{\"statements\":[[0,\"\\n\"],[4,\"each\",[[23,0,[\"columnValue\"]]],null,{\"statements\":[[0,\"      - \"],[1,[23,1,[\"name\"]],false],[0,\" \"],[7,\"br\",true],[8],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  None\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sidekick-portal/components/table-components/item-count-with-tooltip/template.hbs"
    }
  });

  _exports.default = _default;
});