define("sidekick-portal/models/mission", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    name: _emberData.default.attr(),
    categoryId: _emberData.default.attr(),
    instructions: _emberData.default.attr(),
    desiredDuration: _emberData.default.attr('number'),
    units: _emberData.default.attr(),
    imageName: _emberData.default.attr('string'),
    customId: _emberData.default.attr('number'),
    category: Ember.computed({
      get: function get() {
        switch (this.categoryId) {
          case 101:
            return 'food';

          case 102:
            return 'move';

          case 103:
            return 'mind';

          case 104:
            return 'clinic';
        }

        return null;
      }
    })
  }); // public static final Integer CATEGORY_FOOD = 101;
  // public static final Integer CATEGORY_MOVE = 102;
  // public static final Integer CATEGORY_ZEN = 103;
  // public static final Integer CATEGORY_MEDICAL = 104;


  _exports.default = _default;
});