define("sidekick-portal/admin/program-templates/details/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    flashMessages: Ember.inject.service(),
    session: Ember.inject.service(),
    afterModel: function afterModel() {
      var _this = this;

      this.controllerFor('application').set('isFetchingData', false);
      this.store.query('user', {
        filter: 'sidekickhealth@sidekickhealth.com'
      }).then(function (resp) {
        if (!Ember.isBlank(resp)) {
          _this.newMessageUserId = resp.get('firstObject.id');
        } else {
          _this.newMessageUserId = _this.get('session.data').authenticated.user.id;
        }
      });

      this._super.apply(this, arguments);
    },
    model: function model(params) {
      var template = null;

      if (params.template_id === 'new') {
        template = this.store.createRecord('program-template', {
          name: 'New template',
          slotDuration: 604800,
          nameBeingEdited: true
        });
      } else {
        template = this.store.findRecord('program-template', params.template_id, {
          reload: true
        });
      }

      return Ember.RSVP.hash({
        programTemplate: template,
        missions: this.store.findAll('mission'),
        contentItems: this.store.findAll('content-item'),
        icons: this.store.findAll('icon')
      });
    },
    actions: {
      didTransition: function didTransition() {
        this.controllerFor('admin.program-templates').set('showList', false);
      },
      willTransition: function willTransition() {
        this.controllerFor('admin.program-templates').set('showList', true);
      },
      setEditingModeForEntity: function setEditingModeForEntity(entity) {
        entity.toggleProperty('editMode');
      },
      setUserToMessage: function setUserToMessage(slot, message, user) {
        message.set('senderUserId', user.get('id'));
      }
    }
  });

  _exports.default = _default;
});