define("sidekick-portal/components/table-components/program-template-name-with-expired/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qQR6gAaq",
    "block": "{\"symbols\":[\"@record\"],\"statements\":[[4,\"unless\",[[23,1,[\"isActive\"]]],null,{\"statements\":[[0,\"  \"],[7,\"span\",true],[10,\"class\",\"badge badge-danger\"],[8],[0,\"Inactive\"],[9],[0,\" \\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"link-to\",null,[[\"route\",\"model\"],[\"admin.program-templates.details\",[23,1,[\"id\"]]]],{\"statements\":[[0,\"  \"],[1,[23,1,[\"name\"]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sidekick-portal/components/table-components/program-template-name-with-expired/template.hbs"
    }
  });

  _exports.default = _default;
});