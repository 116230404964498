define("sidekick-portal/adapters/feed", ["exports", "sidekick-portal/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    urlForQuery: function urlForQuery(query) {
      if (Ember.isBlank(query.userId)) {
        return null;
      }

      var url = "".concat(this.host, "/users/").concat(query.userId, "/feeds");
      return url;
    },
    query: function query(store, type, _query) {
      var url = this.buildURL(type.modelName, null, null, 'query', _query);
      delete _query['userId'];
      return this.ajax(url, 'GET', {
        data: _query
      });
    }
  });

  _exports.default = _default;
});