define("sidekick-portal/components/cms/content-item/component", ["exports", "sidekick-portal/models/constants/cms"], function (_exports, _cms) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    locales: _cms.availableLocales,
    contentItemPlacements: _cms.contentItemPlacements,
    init: function init() {
      this._super.apply(this, arguments);

      if (!Ember.isEmpty(this.availableContentTypes) && !Ember.isBlank(Ember.get(this, 'item.contentType.id')) && Ember.isBlank(this.selectedItemLocale)) {
        this.selectLocale(this.locales[0]);
      }
    },
    selectLocale: function selectLocale(locale) {
      Ember.set(this, 'selectedLocale', locale);
      Ember.set(this, 'selectedItemLocale', this.item.getContentLocale(locale.locale));
    },
    actions: {
      createTagOnEnterOrSpace: function createTagOnEnterOrSpace(select, e) {
        if ((e.keyCode === 13 || e.keyCode === 19 || e.keyCode === 32) && select.isOpen && !select.highlighted && !Ember.isBlank(select.searchText)) {
          if (this.item.contentTags === undefined) {
            Ember.set(this, 'item.contentTags', []);
          }

          if (!this.item.contentTags.includes(select.searchText)) {
            this.tags.pushObject(select.searchText);
            select.actions.choose(select.searchText);
          }
        }
      },
      selectLocale: function selectLocale(locale) {
        this.selectLocale(locale); //Reload video element after changing locale.

        Ember.run.later(function () {
          var videoElem = document.getElementById('video-preview');

          if (undefined !== videoElem && null !== videoElem) {
            videoElem.load();
          }
        }, 100);
      },
      saveItem: function saveItem(isDraft) {
        var _this = this;

        var selectFirstLocale = this.item.isNew;
        this.saveItem(isDraft).then(function () {
          if (selectFirstLocale) {
            _this.selectLocale(_this.locales[0]);
          }
        });
      }
    }
  });

  _exports.default = _default;
});