define("sidekick-portal/components/user-refresh-tokens/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "M1sttyEW",
    "block": "{\"symbols\":[\"token\",\"&default\"],\"statements\":[[14,2],[0,\"\\n\"],[7,\"table\",true],[10,\"id\",\"tokenTable\"],[10,\"class\",\"table\"],[8],[0,\"\\n  \"],[7,\"thead\",true],[8],[0,\"\\n    \"],[7,\"tr\",true],[8],[0,\"\\n      \"],[7,\"th\",true],[8],[0,\"Id\"],[9],[0,\"\\n      \"],[7,\"th\",true],[8],[0,\"createdDate\"],[9],[0,\"\\n      \"],[7,\"th\",true],[8],[0,\"Installation\"],[9],[0,\"\\n      \"],[7,\"th\",true],[8],[0,\"Device\"],[9],[0,\"\\n      \"],[7,\"th\",true],[8],[0,\"Country\"],[9],[0,\"\\n      \"],[7,\"th\",true],[8],[0,\"User agent\"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"tbody\",true],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"tokensSorted\"]]],null,{\"statements\":[[0,\"      \"],[7,\"tr\",true],[8],[0,\"\\n        \"],[7,\"td\",true],[8],[1,[23,1,[\"id\"]],false],[9],[0,\"\\n        \"],[7,\"td\",true],[8],[1,[23,1,[\"createdDate\"]],false],[9],[0,\"\\n        \"],[7,\"td\",true],[8],[1,[23,1,[\"installation\"]],false],[9],[0,\"\\n        \"],[7,\"td\",true],[8],[1,[23,1,[\"device\"]],false],[9],[0,\"\\n        \"],[7,\"td\",true],[8],[1,[23,1,[\"country\"]],false],[9],[0,\"\\n        \"],[7,\"td\",true],[8],[1,[23,1,[\"userAgent\"]],false],[9],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sidekick-portal/components/user-refresh-tokens/template.hbs"
    }
  });

  _exports.default = _default;
});