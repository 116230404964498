define("sidekick-portal/serializers/content-item", ["exports", "sidekick-portal/serializers/application", "sidekick-portal/models/constants/cms"], function (_exports, _application, _cms) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    attrs: {
      contentType: 'contentTypeId',
      category: 'categoryId'
    },
    normalize: function normalize() {
      var normalized = this._super.apply(this, arguments);

      if (!Ember.isBlank(normalized.data.attributes.contentItemLocales)) {
        normalized.data.attributes.contentItemLocales.forEach(function (locale) {
          locale.contentItemValues.forEach(function (value) {
            if (Ember.isBlank(value.value)) {
              value.value = null;
            } else {
              //Show newline as <br> for String content items
              if (parseInt(normalized.data.relationships.contentType.data.id) === _cms.ContentTypes.CONTENTTYPE_STRING) {
                value.value = value.value.replaceAll('\n', '<br>');
              }
            }
          });
        });
      }

      return normalized;
    },
    serialize: function serialize() {
      var json = this._super.apply(this, arguments);

      if (!Ember.isBlank(json.contentItemLocales)) {
        json.contentItemLocales = json.contentItemLocales.filter(function (locale) {
          return locale.contentItemValues.some(function (x) {
            return !Ember.isBlank(x.value);
          });
        });
      } //Allow <br> in String content items.


      if (parseInt(json.contentTypeId) === _cms.ContentTypes.CONTENTTYPE_STRING) {
        json.contentItemLocales.forEach(function (locale) {
          locale.contentItemValues.forEach(function (value) {
            value.value = value.value.replaceAll('<br>', '\n');
          });
        });
      }

      return json;
    }
  });

  _exports.default = _default;
});