define("sidekick-portal/components/send-message-form/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    backend: Ember.inject.service(),
    session: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    actions: {
      sendMessage: function sendMessage() {
        var _this = this;

        var userId = this.get('session.data').authenticated.user.id,
            message = this.get('newMessage.message'),
            targetIds = this.get('newMessage.targetIds'),
            targetGroupIds = this.get('newMessage.targetGroupIds');
        this.backend.sendMessage(userId, message, targetIds, targetGroupIds).then(function () {
          _this.flashMessages.success('Message sent.');

          _this.toggleModal();

          _this.set('newMessage.message', '');
        }, function () {
          _this.flashMessages.danger('Error sending message');
        });
      }
    }
  });

  _exports.default = _default;
});