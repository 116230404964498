define("sidekick-portal/services/user-scores", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    backend: Ember.inject.service('backend'),
    session: Ember.inject.service(),
    getTotalScore: function getTotalScore(userId) {
      var backendService = this.backend;
      return backendService.getJSONData("admin/users/".concat(userId, "/userScore"));
    },
    listScores: function listScores(userId) {
      var backendService = this.backend;
      return backendService.getJSONData("admin/users/".concat(userId, "/scores"));
    },
    getRefreshTokens: function getRefreshTokens(userId) {
      var backendService = this.backend;
      return backendService.getJSONData("admin/users/".concat(userId, "/refreshtokens"));
    }
  });

  _exports.default = _default;
});