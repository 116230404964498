define("sidekick-portal/admin/users/single/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    session: Ember.inject.service(),
    beforeModel: function beforeModel() {
      this.controllerFor('application').set('isFetchingData', true);

      this._super.apply(this, arguments);
    },
    model: function model(params) {
      if (Ember.isBlank(params.user_id)) {
        params.user_id = this.session.get('data').authenticated.user.id;
      }

      return Ember.RSVP.hash({
        user: this.store.findRecord('user', params.user_id),
        missions: this.store.findAll('mission')
      });
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      Ember.set(controller, 'route', this);
    }
  });

  _exports.default = _default;
});