define("sidekick-portal/models/constants/list-view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.ViewTypes = void 0;
  var ViewTypes = [{
    id: 1,
    name: 'LISTVIEW_VIEWTYPE_PROVIDER',
    description: 'Provider'
  }];
  _exports.ViewTypes = ViewTypes;
});