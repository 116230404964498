define("sidekick-portal/components/user-profile/component", ["exports", "sidekick-portal/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    flashMessages: Ember.inject.service(),
    backend: Ember.inject.service('backend'),
    userScores: Ember.inject.service('user-scores'),
    session: Ember.inject.service('session'),
    showNewMessageModal: false,
    newMessageDefaultData: Ember.ObjectProxy.create({
      content: {
        message: '',
        targetIds: [],
        targetGroupIds: []
      }
    }),
    userActivity: null,
    groupsToDelete: Ember.computed.filterBy('user.groups', 'removeUser', true),
    isDevEnvironment: Ember.computed(function () {
      return _environment.default.environment === 'development' || _environment.default.environment === 'staging';
    }),
    actions: {
      inputChangedHandler: function inputChangedHandler(e) {
        var _this = this;

        console.log(e);
        var file = e.srcElement.files[0];
        e.srcElement.value = '';
        var reader = new FileReader();

        reader.onload = function () {
          var bytes = [].slice.call(new Uint8Array(reader.result));
          var body = {
            imageType: 'image/jpeg',
            image: bytes
          };

          _this.backend.postJSONDataToDefaultService("users/".concat(_this.user.id, "/image"), body).then(function () {
            _this.user.reload();

            _this.flashMessages.success('User image uploaded');
          });
        };

        reader.readAsArrayBuffer(file);
      },
      sendResetPasswordEmail: function sendResetPasswordEmail() {
        var _this2 = this;

        if (confirm('Send a reset password link to this user?', 'yes', 'no')) {
          var payload = {
            email: this.user.email,
            client_id: 'sidekickhealth.com'
          };
          var url = _environment.default.APP.sidekickAuthApi + '/auth/password/reset/code';
          this.backend.makeRequest(url, payload, 'POST').then(function () {
            _this2.flashMessages.success('Reset password e-mail sent.');
          }, function () {
            _this2.flashMessages.warning('Error sending reset password e-mail.');
          });
        }
      },
      updateUser: function updateUser(user) {
        var _this3 = this;

        this.setProperties({
          isProcessing: true
        }); //TODO: Save user settings as well as the user.

        user.save().then(function () {
          _this3.flashMessages.success('User successfully updated.');
        }, function () {
          _this3.flashMessages.warning('Error updating user', {
            closeAfter: 8000
          });
        });
        this.setProperties({
          isProcessing: false
        });
      },
      deleteUser: function deleteUser(user) {
        this.setProperties({
          isProcessing: true
        });

        if (confirm('Are you sure you want to delete this user?')) {
          user.destroyRecord().then(function () {
            this.flashMessages.info('User deleted!');
          }, function () {
            this.flashMessages.warning('Error deleting user', {
              closeAfter: 8000
            });
          });
          this.set('user', null);
        }

        this.setProperties({
          isProcessing: false
        });
      },
      registerUserToPin: function registerUserToPin() {
        var _this4 = this;

        this.setProperties({
          isProcessing: true
        });
        var userId = this.user.id;
        var data = {
          pinCode: this.pinToRegister
        };
        var path = "admin/users/".concat(userId, "/pins");
        var request = this.backend.postJSONData(path, data);
        request.then(function () {
          _this4.flashMessages.success("User registered to pin ".concat(_this4.pinToRegister));

          _this4.refreshUser();

          _this4.set('pinToRegister', '');

          _this4.setProperties({
            isProcessing: false
          });
        }, function () {
          _this4.flashMessages.warning('Error applying pin');

          _this4.setProperties({
            isProcessing: false
          });
        });
      },
      removeUserFromPartner: function removeUserFromPartner(partnerId) {
        var _this5 = this;

        if (confirm('Are you sure you want to remove this user from partner?')) {
          this.set('isProcessing', true);
          var userId = this.user.id;
          var path = "users/".concat(userId, "/partners/").concat(partnerId);
          var request = this.backend.deleteJSONData(path);
          request.then(function () {
            _this5.flashMessages.success('Partner removed from user.');

            _this5.refreshUser();

            _this5.set('isProcessing', false);
          }, function () {
            _this5.flashMessages.warning('Error removing partner connection');

            _this5.set('isProcessing', false);
          });
        }
      },
      removeUserFromGroup: function removeUserFromGroup(groupId, confirmed) {
        var _this6 = this;

        if (confirmed || confirm('Are you sure you want to remove this user from group?')) {
          this.set('isProcessing', true);
          var userId = this.user.id;
          var path = "groups/".concat(groupId, "/members/").concat(userId);
          var request = this.backend.deleteJSONData(path);
          request.then(function () {
            _this6.flashMessages.success('User removed as member from group.');

            _this6.refreshUser();

            _this6.set('isProcessing', false);
          }, function () {
            _this6.flashMessages.warning('Error removing partner connection');

            _this6.set('isProcessing', false);
          });
        }
      },
      removeFromGroups: function removeFromGroups() {
        var _this7 = this;

        if (confirm('Are you sure you want to remove this user from the selected groups?')) {
          var groups = this.groupsToDelete;
          groups.forEach(function (group) {
            console.log('removing user from group: ', group.name);

            _this7.send('removeUserFromGroup', group.id, true);
          });
        }
      },
      toggleNewMessageModal: function toggleNewMessageModal() {
        this.set('newMessageDefaultData.targetIds', [this.get('user.id')]);
        this.toggleProperty('showNewMessageModal');
      },
      getUserActivity: function getUserActivity() {
        var _this8 = this;

        var service = this.userScores;
        service.listScores(this.get('user.id')).then(function (results) {
          _this8.set('userActivity', results['items']);
        });
        this.set('showUserActivity', true);
      },
      getUserRefreshTokens: function getUserRefreshTokens() {
        var _this9 = this;

        var service = this.userScores;
        service.getRefreshTokens(this.get('user.id')).then(function (results) {
          _this9.set('refreshTokens', results['items']);
        });
        this.set('showRefreshTokensModal', true);
      },
      toggleRefreshTokensModal: function toggleRefreshTokensModal() {
        this.toggleProperty('showRefreshTokensModal');
      },
      toggleUserActivityModal: function toggleUserActivityModal() {
        this.toggleProperty('showUserActivity');
      },
      logRandomMissionsShort: function logRandomMissionsShort() {
        var backend = this.backend;
        var missions = this.missions;
        var user = this.user;
        var count = 0; //Randomly skip a date here and there to create off days.

        var mission;
        var newDate = moment.utc(); //Steps

        mission = missions.findBy('name', 'Step Counter');
        backend.logMissionForUser(user, newDate, mission, count++, 7523);
        mission = missions.findBy('name', 'Breathe');
        backend.logMissionForUser(user, newDate, mission, count++, 120);
        mission = missions.findBy('name', 'Stairs');
        backend.logMissionForUser(user, newDate, mission, count++, 5, 5);
        mission = missions.findBy('name', 'Veggies');
        backend.logMissionForUser(user, newDate, mission, count++, 1);
        mission = missions.findBy('name', 'Veggies');
        backend.logMissionForUser(user, newDate, mission, count++, 2);
      },
      logRandomMissions: function logRandomMissions() {
        var backend = this.backend;
        var missions = this.missions;
        var user = this.user;
        var count = 0;

        for (var i = 0; i < 1; i++) {
          //Randomly skip a date here and there to create off days.
          if (Math.random > 0.9) {
            continue;
          }

          var newDate = moment.utc().subtract(i, 'days');
          var mission = void 0,
              randomValue = void 0; // Health education mission (Only log one)

          if (i === 0) {
            mission = missions.findBy('name', 'General Health Education');
            backend.logMissionForUser(user, newDate, mission, count++, 1800);
          } // //Hunger meter
          // mission = missions.findBy('name', 'Hunger Meter');


          randomValue = Math.random() / 2; // newDate = newDate.subtract(randomValue+5, 'seconds');
          // backend.logMissionForUser(user, newDate, mission, count++, [randomValue, randomValue + 0.5]);
          //Move missions

          mission = missions.findBy('name', 'Fishing');
          randomValue += 10;
          newDate = newDate.subtract(randomValue + 5, 'seconds');
          backend.logMissionForUser(user, newDate, mission, count++, randomValue); //Weight mission

          mission = missions.findBy('name', 'Weight');
          randomValue = Math.floor(Math.random() * (105 - 95 + 1) + 95);
          newDate = newDate.subtract(randomValue + 5, 'seconds');
          backend.logMissionForUser(user, newDate, mission, count++, randomValue, 0);
          mission = missions.findBy('name', 'Crossfit');
          randomValue += 10;
          newDate = newDate.subtract(randomValue + 5, 'seconds');
          backend.logMissionForUser(user, newDate, mission, count++, randomValue); //Mind missions

          mission = missions.findBy('name', 'Relaxing Legs');
          randomValue = Math.floor(Math.random() * (20 - 1 + 1) + 1);
          newDate = newDate.subtract(randomValue + 5, 'seconds');
          backend.logMissionForUser(user, newDate, mission, count++, randomValue);
          mission = missions.findBy('name', 'Relaxing Neck & Shoulders');
          randomValue = Math.floor(Math.random() * (20 - 1 + 1) + 1);
          newDate = newDate.subtract(randomValue + 5, 'seconds');
          backend.logMissionForUser(user, newDate, mission, count++, randomValue);
          mission = missions.findBy('name', 'Mindful Walking');
          randomValue = Math.floor(Math.random() * (30 - 5 + 1) + 5);
          newDate = newDate.subtract(randomValue + 5, 'seconds');
          backend.logMissionForUser(user, newDate, mission, count++, randomValue); //Food missions

          mission = missions.findBy('name', 'Water');
          randomValue = Math.floor(Math.random() * 4 + 1);
          newDate = newDate.subtract(randomValue + 5, 'seconds');
          backend.logMissionForUser(user, newDate, mission, count++, randomValue); //Veggies

          mission = missions.findBy('name', 'Veggies');
          randomValue = Math.floor(Math.random() * 4 + 1);
          newDate = newDate.subtract(randomValue + 5, 'seconds');
          backend.logMissionForUser(user, newDate, mission, count++, randomValue);
          mission = missions.findBy('name', 'Fruits');
          randomValue = Math.floor(Math.random() * 3 + 1);
          newDate = newDate.subtract(randomValue + 5, 'seconds');
          backend.logMissionForUser(user, newDate, mission, count++, randomValue);
          mission = missions.findBy('name', 'Nuts and Seeds');
          randomValue = Math.floor(Math.random() * 2 + 1);
          newDate = newDate.subtract(randomValue + 5, 'seconds');
          backend.logMissionForUser(user, newDate, mission, count++, randomValue);

          if (Math.random() > 0.8) {
            //Soda mission
            mission = missions.findBy('name', 'Soda Free');
            randomValue = Math.floor(Math.random() * (105 - 95 + 1) + 95);
            newDate = newDate.subtract(randomValue + 5, 'seconds');
            backend.logMissionForUser(user, newDate, mission, count++, 86400);
          }

          if (Math.random() > 0.8) {
            //Candy mission
            mission = missions.findBy('name', 'Candy Free');
            randomValue = Math.floor(Math.random() * (105 - 95 + 1) + 95);
            newDate = newDate.subtract(randomValue + 5, 'seconds');
            backend.logMissionForUser(user, newDate, mission, count++, 86400);
          }

          if (Math.random() > 0.8) {
            //Snack mission
            mission = missions.findBy('name', 'No Late Snacks');
            randomValue = Math.floor(Math.random() * (105 - 95 + 1) + 95);
            newDate = newDate.subtract(randomValue + 5, 'seconds');
            backend.logMissionForUser(user, newDate, mission, count++, 86400);
          }

          if (Math.random() > 0.8) {
            //Junk mission
            mission = missions.findBy('name', 'No Junk Food');
            randomValue = Math.floor(Math.random() * (105 - 95 + 1) + 95);
            newDate = newDate.subtract(randomValue + 5, 'seconds');
            backend.logMissionForUser(user, newDate, mission, count++, 86400);
          }
        }
      },
      finishPendingUserTasks: function finishPendingUserTasks() {
        this.backend.finishPendingUserTasks(this.user);
      }
    }
  });

  _exports.default = _default;
});