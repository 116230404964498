define("sidekick-portal/models/content-type-field", ["exports", "ember-data", "ember-data-model-fragments", "sidekick-portal/models/constants/cms"], function (_exports, _emberData, _emberDataModelFragments, _cms) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberDataModelFragments.default.Fragment.extend({
    name: _emberData.default.attr('string'),
    recommendedMaxLength: _emberData.default.attr('number'),
    valueType: _emberData.default.attr('enum', _cms.contentTypeValues),
    displayType: _emberData.default.attr('enum', _cms.displayTypes),
    required: _emberData.default.attr('boolean'),
    order: _emberData.default.attr('number'),
    acceptedFileTypes: Ember.computed('valueType', function () {
      if (this.valueType.name === 'CONTENTTYPE_VALUETYPE_UPLOAD') {
        if (this.name === 'video') {
          return '.mp4';
        } else if (['videoPoster', 'image', 'thumbnail', 'video-thumbnail'].indexOf(this.name) >= 0) {
          return '.png,.jpg,.jpeg';
        } else if (['icon', 'iconInactive'].indexOf(this.name) >= 0) {
          return '.png';
        } else if (this.name === 'lottie') {
          return '.json';
        }
      }

      return '';
    })
  });

  _exports.default = _default;
});