define("sidekick-portal/admin/users/single/user-programs/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model(params) {
      this.params = params;
      return Ember.RSVP.hash({
        user: this.store.findRecord('user', params.user_id),
        missions: this.store.findAll('mission'),
        userProgram: {}
      });
    },
    afterModel: function afterModel(resolvedModel, transition) {
      var _this = this;

      resolvedModel.userProgram = resolvedModel.user.userPrograms.find(function (x) {
        return x.programId = _this.params.program_id;
      });
      resolvedModel.user.userPrograms.then(function (x) {
        Ember.set(resolvedModel, 'userProgram', x.find(function (y) {
          return y.get('program.id') === _this.params.program_id;
        }));
      });

      this._super(resolvedModel, transition);
    }
  });

  _exports.default = _default;
});