define("sidekick-portal/components/table-title/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bgwbUpTN",
    "block": "{\"symbols\":[\"@title\",\"@createNewRoute\",\"@disableCreteNewRoute\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"titleContainer\"],[8],[0,\"\\n  \"],[7,\"h3\",true],[8],[1,[23,1,[]],false],[9],[0,\"\\n  \"],[5,\"link-to\",[[12,\"class\",\"btn btn-sm btn-outline-primary\"]],[[\"@route\",\"@model\",\"@disabled\"],[[23,2,[]],\"new\",[23,3,[]]]],{\"statements\":[[0,\"\\n    \"],[7,\"i\",true],[10,\"class\",\"fas fa-plus\"],[8],[9],[0,\" Create new\\n  \"]],\"parameters\":[]}],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"hr\",true],[8],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sidekick-portal/components/table-title/template.hbs"
    }
  });

  _exports.default = _default;
});