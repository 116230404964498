define("sidekick-portal/serializers/program", ["exports", "sidekick-portal/serializers/application", "ember-data"], function (_exports, _application, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend(_emberData.default.EmbeddedRecordsMixin, {
    attrs: {
      template: 'programTemplateId',
      slots: {
        embedded: 'always'
      }
    },
    normalizeSingleResponse: function normalizeSingleResponse(store, primaryModelClass, payload, id, requestType) {
      payload.links = {
        template: "/admin/programtemplates/".concat(payload.programTemplateId)
      };
      return this._super(store, primaryModelClass, payload, id, requestType);
    },
    normalizeArrayResponse: function normalizeArrayResponse(store, primaryModelClass, payload, id, requestType) {
      if (payload.programs !== undefined) {
        payload.programs.forEach(function (program) {
          //For async fetching of hasMany relationship not included in the server response.
          program.links = {
            template: program.programTemplateId ? "/admin/programtemplates/".concat(program.programTemplateId) : null
          };
        });
      }

      return this._super(store, primaryModelClass, payload, id, requestType);
    }
  });

  _exports.default = _default;
});