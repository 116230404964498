define("sidekick-portal/components/program-template/edit/slot/component", ["exports", "sidekick-portal/models/constants/program-templates", "sidekick-portal/models/constants/cms"], function (_exports, _programTemplates, _cms) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    slotBehaviours: _programTemplates.programTemplateSlotBehaviours,
    store: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    showLocalizationModal: false,
    sorting: Object.freeze(['daysAfterSlotStart']),
    actions: {
      setEditingModeForEntity: function setEditingModeForEntity(entity) {
        entity.toggleProperty('editMode');
      },
      deleteContentItem: function deleteContentItem(slot, contentItem) {
        slot.get('contentMessages').removeObject(contentItem);
      },
      deleteReminder: function deleteReminder(slot, reminder) {
        slot.get('reminders').removeObject(reminder);
      },
      deletePath: function deletePath(slot, path) {
        slot.get('paths').removeObject(path);
      },
      createContentItem: function createContentItem() {
        var _this = this;

        var contentItem = this.store.createRecord('content-item', {
          name: "PT_".concat(this.programTemplate.name, "_SLOT#").concat(this.slot.slotNumber),
          contentType: this.store.peekRecord('content-type', _cms.ContentTypes.CONTENTTYPE_PROGRAMTEMPLATESLOT)
        });
        var contentLocale = contentItem.newContentLocale('en');
        var contentItemValue = contentLocale.contentItemValues.find(function (civ) {
          return civ.name === 'name';
        });
        Ember.set(contentItemValue, 'value', this.slot.slotName);
        Ember.set(contentLocale, 'status', _cms.localeStatus.find(function (status) {
          return status.name === 'CONTENTITEMLOCALE_STATUS_PUBLISHED';
        }));
        contentItem.save().then(function (item) {
          _this.flashMessages.success('Content item created');

          var saveTemplateAfterCreatingContentItem = false;

          if (!_this.programTemplate.isBeingEdited) {
            saveTemplateAfterCreatingContentItem = true;
          }

          Ember.set(_this, 'slot.contentItem', item);

          if (saveTemplateAfterCreatingContentItem) {
            _this.programTemplate.save().then(function () {
              _this.flashMessages.success('Program template saved');
            });
          }
        });
      }
    }
  });

  _exports.default = _default;
});