define("sidekick-portal/helpers/one-index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.oneIndex = oneIndex;

  function oneIndex(params) {
    return parseInt(params[0]) + 1;
  }

  var _default = Ember.Helper.helper(oneIndex);

  _exports.default = _default;
});