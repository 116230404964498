define("sidekick-portal/transforms/email-list", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Transform.extend({
    deserialize: function deserialize(serialized) {
      if (Ember.isEmpty(serialized)) {
        return '';
      }

      if (serialized.join !== undefined) {
        return serialized.join(', ');
      }

      return '';
    },
    serialize: function serialize(deserialized) {
      if (Ember.isEmpty(deserialized)) {
        return null;
      }

      if (deserialized.split !== undefined) {
        var arr = deserialized.split(',');
        var trimmedArray = arr.map(function (item) {
          return item.trim();
        });
        return trimmedArray;
      }

      return null;
    }
  });

  _exports.default = _default;
});