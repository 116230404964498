define("sidekick-portal/models/constants/user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.userActions = _exports.externalTypes = void 0;
  var userActions = Object.freeze([{
    name: 'EMAIL_WELCOME_NO_ACCESS_CODE',
    id: 1,
    description: 'Email sent to user with no access code after his/her first insertScore'
  }, {
    name: 'EMAIL_WELCOME_WITH_ACCESS_CODE',
    id: 2,
    description: 'Email sent to user with access code after his/her first insertScore'
  }, {
    name: 'EMAIL_VERIFIED',
    id: 3,
    description: 'User has responded to an email verification email and verified his email. Data field is the new email'
  }, {
    name: 'EMAIL_VERIFICATION_REQUESTED',
    id: 4,
    description: 'The user has been sent an email verification token, Data field is the token'
  }, {
    name: 'USERACTION_JOINPARTNER',
    id: 10,
    description: 'A user is joining a partner'
  }, {
    name: 'USERACTION_LEAVEPARTNER',
    id: 11,
    description: 'A user is leaving a partner'
  }, {
    name: 'USERACTION_JOINGROUP',
    id: 12,
    description: 'A user is joining a group'
  }, {
    name: 'USERACTION_LEAVEGROUP',
    id: 13,
    description: 'A user is leaving a group'
  }, {
    name: 'USERACTION_LICENSEGRANTED',
    id: 20,
    description: 'A user was awarded a license'
  }, {
    name: 'USERACTION_LICENSERENEWED',
    id: 21,
    description: 'A user got his license extended/renewed'
  }, {
    name: 'USERACTION_LICENSEREVOKED',
    id: 22,
    description: 'A license was cancelled'
  }, {
    name: 'USERACTION_JOINPROGRAM',
    id: 30,
    description: 'A user is joining a program'
  }, {
    name: 'USERACTION_LEAVEPROGRAM',
    id: 31,
    description: 'A user is leaving a program'
  }, {
    name: 'USERACTION_PRIVACYPOLICYACCEPT',
    id: 40,
    description: 'A user accepted a privacy policy'
  }, {
    name: 'USERACTION_EMAILCHANGED',
    id: 41,
    description: 'Email changed, data field is the old email'
  }, {
    name: 'USERACTION_PINREGISTERED',
    id: 45,
    description: 'Pin registered'
  }, {
    name: 'USERACTION_DELETED',
    id: 50,
    description: 'The user was deleted'
  }, {
    name: 'USERACTION_LOCALEUPDATED',
    id: 60,
    description: 'Locale updated'
  }]);
  _exports.userActions = userActions;
  var externalTypes = Object.freeze([{
    name: 'EXTERNAL_TYPE_FACEBOOK',
    id: 1,
    description: 'Facebook'
  }, {
    name: 'EXTERNAL_TYPE_GOOGLE',
    id: 2,
    description: 'Google'
  }, {
    name: 'EXTERNAL_TYPE_APPLE',
    id: 4,
    description: 'Apple'
  }, {
    name: 'EXTERNAL_TYPE_ANTHEM',
    id: 5,
    description: 'Anthem'
  }
  /*
  {
    name: 'EXTERNAL_TYPE_SIDEKICK',
    id: 6,
    description: 'Sidekick'
  }
  */
  ]);
  _exports.externalTypes = externalTypes;
});