define("sidekick-portal/admin/program-templates/details/controller", ["exports", "sidekick-portal/models/constants/program-templates"], function (_exports, _programTemplates) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    flashMessages: Ember.inject.service(),
    init: function init() {
      var _this = this;

      this.store.query('user', {
        filter: 'sidekickhealth@sidekickhealth.com'
      }).then(function (resp) {
        if (!Ember.isBlank(resp)) {
          _this.newMessageUserId = resp.get('firstObject.id');
        } else {
          _this.newMessageUserId = _this.get('session.data').authenticated.user.id;
        }
      });

      this._super.apply(this, arguments);
    },
    saveTemplate: function saveTemplate() {
      var _this2 = this;

      var template = this.model.programTemplate;
      var isNew = false;

      if (template.get('isNew')) {
        isNew = true;
      }

      template.set('isProcessing', true);
      template.save().then(function () {
        _this2.flashMessages.success('Template saved.');

        template.set('isProcessing', false);
        template.set('processingError', false);

        if (isNew) {
          _this2.transitionToRoute('admin.program-templates.details', template.get('id'));
        }

        template.set('nameBeingEdited', false);
      }, function (error) {
        template.set('processingError', error);
        template.set('isProcessing', false);
        console.error(error);

        if (error.errors[0].message.includes('85| ProgramTemplate was modified by userId:')) {
          var errorMessage = error.errors[0].message;
          var indexStart = errorMessage.indexOf('userId:') + 'userId: '.length;
          var errorArr = errorMessage.substring(indexStart).split(' ');
          var modifiedByUserId = errorArr[0];
          var modifiedByUserDate = errorArr[1] + ' ' + errorArr[2] + ' at ' + errorArr[3];

          _this2.store.findRecord('user', modifiedByUserId).then(function (response) {
            _this2.model.programTemplate.modifiedByUserName = response.name;
            _this2.model.programTemplate.modifiedByUserDate = modifiedByUserDate;
            _this2.model.programTemplate.openConflictModal = true;
          });
        } else {
          _this2.flashMessages.danger('Error saving Template. ' + error.errors[0].message);
        }
      });
    },
    actions: {
      saveTemplate: function saveTemplate() {
        this.saveTemplate();
      },
      expireTemplate: function expireTemplate() {
        this.model.programTemplate.set('isActive', false);
        this.saveTemplate();
      },
      activateTemplate: function activateTemplate() {
        this.model.programTemplate.set('isActive', true);
        this.saveTemplate();
      },
      revertChanges: function revertChanges(template) {
        if (template.get('hasDirtyAttributes')) {
          this.set('showRevertConfirm', true);
        } else {
          this.send('revertConfirm', template);
        }

        template.set('nameBeingEdited', false);
      },
      revertConfirm: function revertConfirm() {
        this.model.programTemplate.rollbackAttributes();
        this.set('showRevertConfirm', false);
        this.model.programTemplate.set('nameBeingEdited', false);
      },
      revertCancel: function revertCancel() {
        this.set('showRevertConfirm', false);
      },
      addContentItem: function addContentItem(slot) {
        var newContentItem = this.store.createFragment('program-template-slot-content', {
          senderUserId: this.newMessageUserId,
          //this.get('session.data').authenticated.user.id,
          sendMethod: _programTemplates.sendMethod[1],
          senderType: _programTemplates.senderType[1],
          editMode: true,
          daysAfterSlotStart: 0,
          hourOfDay: 12,
          slot: slot
        });
        slot.contentMessages.pushObject(newContentItem);
      },
      addReminder: function addReminder(slot) {
        var newReminder = this.store.createFragment('program-template-slot-reminder', {
          timeOfDay: '12:00',
          days: '1,2,3,4,5,6,7'
        });
        slot.reminders.addObject(newReminder);
      },
      addPath: function addPath(slot) {
        var newPath = this.store.createFragment('program-template-slot-path', {
          programTemplate: this.model.programTemplate,
          pathType: {
            name: 'PROGRAMTEMPLATESLOTPATH_PATHTYPE_CAREPATH',
            id: 3,
            description: 'Care Path'
          }
        });
        slot.paths.addObject(newPath);
      },
      addSlot: function addSlot() {
        var template = this.model.programTemplate;
        var slotNumber = 1;

        if (!Ember.isBlank(template.get('slots'))) {
          slotNumber = template.get('slots.lastObject.slotNumber') + 1;
        }

        template.get('slots').addObject(this.store.createFragment('program-template-slot', {
          slotNumber: slotNumber
        }));
      }
    }
  });

  _exports.default = _default;
});