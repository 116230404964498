define("sidekick-portal/templates/quizzes/single", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YpEEgaCX",
    "block": "{\"symbols\":[],\"statements\":[[1,[22,\"outlet\"],false],[0,\"\\n\\n\"],[5,\"quizzes/quiz-edit\",[],[[\"@quiz\",\"@saveQuiz\",\"@removeQuiz\",\"@addQuestion\",\"@removeQuestion\",\"@addAnswer\",\"@removeAnswer\",\"@addRating\",\"@generateDefaultRatings\",\"@removeRating\",\"@changeOrderOfQuestion\",\"@changeOrderOfAnswer\",\"@createSurveyContentItem\",\"@createQuizRatingContentItem\",\"@createQuizQuestionContentItem\",\"@createQuizAnswerContentItem\"],[[23,0,[\"model\"]],[23,0,[\"saveQuiz\"]],[23,0,[\"removeQuiz\"]],[23,0,[\"addQuestion\"]],[23,0,[\"removeQuestion\"]],[23,0,[\"addAnswer\"]],[23,0,[\"removeAnswer\"]],[23,0,[\"addRating\"]],[22,\"generateDefaultRatings\"],[23,0,[\"removeRating\"]],[23,0,[\"changeOrderOfQuestion\"]],[23,0,[\"changeOrderOfAnswer\"]],[23,0,[\"createSurveyContentItem\"]],[23,0,[\"createQuizRatingContentItem\"]],[23,0,[\"createQuizQuestionContentItem\"]],[23,0,[\"createQuizAnswerContentItem\"]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sidekick-portal/templates/quizzes/single.hbs"
    }
  });

  _exports.default = _default;
});