define("sidekick-portal/components/loading-spinner-inline/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "m3ETb7/W",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"sk-fading-circle\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"sk-circle1 sk-circle\"],[8],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"sk-circle2 sk-circle\"],[8],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"sk-circle3 sk-circle\"],[8],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"sk-circle4 sk-circle\"],[8],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"sk-circle5 sk-circle\"],[8],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"sk-circle6 sk-circle\"],[8],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"sk-circle7 sk-circle\"],[8],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"sk-circle8 sk-circle\"],[8],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"sk-circle9 sk-circle\"],[8],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"sk-circle10 sk-circle\"],[8],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"sk-circle11 sk-circle\"],[8],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"sk-circle12 sk-circle\"],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sidekick-portal/components/loading-spinner-inline/template.hbs"
    }
  });

  _exports.default = _default;
});