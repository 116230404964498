define("sidekick-portal/components/list-columns/column-list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Crfes7cj",
    "block": "{\"symbols\":[\"@tableFilter\",\"&default\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"container-fluid px-4\"],[8],[0,\"\\n  \"],[5,\"table-title\",[],[[\"@title\",\"@createNewRoute\"],[\"List Columns\",\"list-columns.single\"]]],[0,\"\\n\\n  \"],[5,\"models-table\",[[12,\"class\",\"emtable\"]],[[\"@data\",\"@columns\",\"@filterString\",\"@filteringIgnoreCase\",\"@pageSize\",\"@multipleColumnsSorting\",\"@useFilteringByColumns\",\"@useNumericPagination\",\"@focusGlobalFilter\",\"@themeInstance\"],[[23,0,[\"savedColumns\"]],[23,0,[\"columns\"]],[23,1,[]],true,50,false,false,true,true,[23,0,[\"customThemeInstance\"]]]]],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[14,2],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sidekick-portal/components/list-columns/column-list/template.hbs"
    }
  });

  _exports.default = _default;
});