define("sidekick-portal/models/content-item", ["exports", "ember-data", "ember-data-model-fragments", "sidekick-portal/models/constants/cms"], function (_exports, _emberData, _emberDataModelFragments, _cms) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* app/models/content-item.js */
  var _default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    category: _emberData.default.attr('enum', _cms.categories),
    placement: _emberData.default.attr('enum', _cms.contentItemPlacements),
    contentType: _emberData.default.belongsTo('content-type'),
    createdDate: _emberData.default.attr('string'),
    modifiedDate: _emberData.default.attr('string'),
    contentTags: _emberData.default.attr(),
    //Array of strings
    contentItemLocales: _emberDataModelFragments.default.fragmentArray('content-item-locale'),
    deleted: _emberData.default.attr('boolean'),
    validate: function validate(locale) {
      var _this = this;

      var requiredFieldsMissing = [];
      Ember.get(locale, 'contentItemValues').forEach(function (value) {
        var field = Ember.get(_this.contentType, 'contentTypeFields').find(function (x) {
          return x.name === value.name;
        });

        if (Ember.isBlank(value.value) && field.required) {
          requiredFieldsMissing.push(field);
        }
      });
      return requiredFieldsMissing;
    },
    validatePublishedItem: function validatePublishedItem() {
      var _this2 = this;

      var requiredFieldsMissing = [];
      Ember.get(this, 'contentItemLocales').forEach(function (locale) {
        Ember.get(locale, 'contentItemValues').forEach(function (value) {
          var field = Ember.get(_this2.contentType, 'contentTypeFields').find(function (x) {
            return x.name === value.name;
          });

          if (Ember.isBlank(value.value) && field.required && locale.status && locale.status.id === 1) {
            requiredFieldsMissing.push(field);
          }
        });
      });
      return requiredFieldsMissing;
    },
    save: function save(isDraft) {
      var _this3 = this;

      Ember.get(this, 'contentItemLocales').forEach(function (locale) {
        if (Ember.isBlank(Ember.get(locale, 'status')) && Ember.get(locale, 'isValid') && Ember.get(locale, 'isNew')) {
          var requiredFieldsMissing = _this3.validate(locale);

          if (Ember.isEmpty(requiredFieldsMissing) && !isDraft) {
            Ember.set(locale, 'status', _cms.localeStatus.find(function (x) {
              return x.name === 'CONTENTITEMLOCALE_STATUS_PUBLISHED';
            }));
          } else {
            Ember.set(locale, 'status', _cms.localeStatus.find(function (x) {
              return x.name === 'CONTENTITEMLOCALE_STATUS_NOTPUBLISHED';
            }));
          }
        }
      });
      return this._super.apply(this, arguments);
    },
    getContentLocale: function getContentLocale(locale) {
      var contentLocale = this.contentItemLocales.find(function (x) {
        return x.locale === locale;
      });

      if (Ember.isBlank(contentLocale)) {
        return this.newContentLocale(locale);
      }

      if (contentLocale.contentItemValues.length < Ember.get(this, 'contentType.contentTypeFields.length')) {
        //If server doesn't return all fields for the item's content type, create them.
        Ember.get(this, 'contentType.contentTypeFields').forEach(function (field) {
          if (!contentLocale.contentItemValues.any(function (x) {
            return x.name === field.name;
          })) {
            contentLocale.contentItemValues.pushObject({
              name: field.name,
              value: ''
            });
          }
        });
      }

      return contentLocale;
    },
    newContentLocale: function newContentLocale(locale) {
      if (Ember.isBlank(Ember.get(this, 'contentType.contentTypeFields'))) {
        console.log('Trying to create contentItemLocale with no fields provided.');
        return;
      }

      var values = [];
      Ember.get(this, 'contentType.contentTypeFields').forEach(function (field) {
        values.pushObject({
          name: field.name,
          value: null
        });
      });
      var newLocale = this.store.createFragment('content-item-locale', {
        locale: locale,
        contentItemValues: values
      });
      this.contentItemLocales.addObject(newLocale);
      return newLocale;
    },
    isValid: Ember.computed('name', 'contentType', function () {
      return !Ember.isBlank(this.name) && !Ember.isBlank(Ember.get(this, 'contentType.id'));
    }),
    thumbnail: Ember.computed('contentItemLocales.[].contentItemValues', function () {
      if (Ember.isBlank(this.contentItemLocales)) {
        return false;
      }

      if (Ember.isBlank(this.contentItemLocales.firstObject.contentItemValues)) {
        return false;
      }

      var thumbnailValue = this.contentItemLocales.firstObject.contentItemValues.find(function (x) {
        return x.name === 'thumbnail';
      });

      if (Ember.isBlank(thumbnailValue)) {
        return false;
      }

      return thumbnailValue.value;
    }),
    imageUrl: Ember.computed('contentItemLocales.[].contentItemValues', function () {
      if (Ember.isBlank(this.contentItemLocales)) {
        return false;
      }

      if (Ember.isBlank(this.contentItemLocales.firstObject.contentItemValues)) {
        return false;
      }

      var imageValue = this.contentItemLocales.firstObject.contentItemValues.find(function (x) {
        return x.name === 'image';
      });

      if (Ember.isBlank(imageValue)) {
        return false;
      }

      return imageValue.value;
    }),
    videoPoster: Ember.computed('contentItemLocales.[].contentItemValues', function () {
      if (Ember.isBlank(this.contentItemLocales)) {
        return false;
      }

      if (Ember.isBlank(this.contentItemLocales.firstObject.contentItemValues)) {
        return false;
      }

      var imageValue = this.contentItemLocales.firstObject.contentItemValues.find(function (x) {
        return x.name === 'videoPoster';
      });

      if (Ember.isBlank(imageValue)) {
        return false;
      }

      return imageValue.value;
    }),
    defaultText: Ember.computed('contentItemLocales.[].contentItemValues', function () {
      if (Ember.isBlank(this.contentItemLocales)) {
        return false;
      }

      if (Ember.isBlank(this.contentItemLocales.firstObject.contentItemValues)) {
        return false;
      }

      return this.bodyText || this.titleText || this.descriptionText;
    }),
    bodyText: Ember.computed('contentItemLocales.[].contentItemValues', function () {
      var bodyValue = this.contentItemLocales.firstObject.contentItemValues.find(function (x) {
        return x.name === 'body';
      });
      return Ember.isBlank(bodyValue) ? null : bodyValue.value;
    }),
    titleText: Ember.computed('contentItemLocales.[].contentItemValues', function () {
      var titleValue = this.contentItemLocales.firstObject.contentItemValues.find(function (x) {
        return x.name === 'title';
      });
      return Ember.isBlank(titleValue) ? null : titleValue.value;
    }),
    descriptionText: Ember.computed('contentItemLocales.[].contentItemValues', function () {
      var descriptionValue = this.contentItemLocales.firstObject.contentItemValues.find(function (x) {
        return x.name === 'description';
      });
      return Ember.isBlank(descriptionValue) ? null : descriptionValue.value;
    })
  });

  _exports.default = _default;
});