define("sidekick-portal/components/user-profile/user-actions/component", ["exports", "sidekick-portal/components/table-themes/customTheme", "sidekick-portal/models/constants/user"], function (_exports, _customTheme, _user) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    customThemeInstance: _customTheme.default.create(),
    columnsForTable: Object.freeze([{
      propertyName: 'actionDate',
      title: 'Date',
      component: 'table-components/start-date-format'
    }, {
      propertyName: 'actionName',
      title: 'Action name',
      isHidden: 'true'
    }, {
      propertyName: 'actionDescription',
      title: 'Action'
    }, {
      propertyName: 'data',
      title: 'Data'
    }]),
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this.user.actions().then(function (x) {
        x.items.forEach(function (a) {
          a.actionName = _user.userActions.find(function (ua) {
            return ua.id === a.actionId;
          }).name;
          a.actionDescription = _user.userActions.find(function (ua) {
            return ua.id === a.actionId;
          }).description;
        });
        console.log(x.items);
        Ember.set(_this, 'userActions', x.items);
      });
    }
  }); // private Long id;
  // private Long userId;
  // private Integer actionId;
  // private Date actionDate;
  // private String data;


  _exports.default = _default;
});