define("sidekick-portal/admin/cms/single/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    backend: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    actions: {
      saveItem: function saveItem(isDraft) {
        var _this = this;

        var transitionAfterSave = this.model.item.isNew;
        var requiredPublishedFieldsMissing = this.model.item.validatePublishedItem();

        if (!Ember.isEmpty(requiredPublishedFieldsMissing)) {
          Ember.get(this.model.item, 'contentItemLocales').forEach(function (locale) {
            var requiredLocaleFieldsMissing = _this.model.item.validate(locale);

            console.log(requiredLocaleFieldsMissing.length);

            if (!Ember.isEmpty(requiredLocaleFieldsMissing) && locale.status.id === 1) {
              requiredLocaleFieldsMissing.forEach(function (rlfm) {
                _this.flashMessages.danger('Error saving published locale: ' + locale.locale + '. Required field missing: ' + rlfm.name);
              });
            }
          });
          return new Promise(function (resolve) {
            return resolve(requiredPublishedFieldsMissing);
          });
        } else {
          return this.model.item.save(isDraft).then(function (response) {
            if (transitionAfterSave) {
              _this.transitionToRoute('admin.cms.single', response.id);
            }

            _this.flashMessages.success('Content Item Saved!');
          });
        }
      },
      getUploadUrl: function getUploadUrl(itemId, locale, valueType, contentType, fileName) {
        var url = "admin/contentitems/".concat(itemId, "/locales/").concat(locale, "/values/").concat(valueType);
        var params = "?contentType=".concat(contentType, "&fileName=").concat(fileName);
        return this.backend.getJSONData(url + params).then(function (response) {
          return response;
        });
      },
      prepareUploadUrl: function prepareUploadUrl(itemId, locale, valueType, metadata) {
        var url = "admin/contentitems/".concat(itemId, "/locales/").concat(locale, "/values/").concat(valueType, "/upload");
        return this.backend.postJSONData(url, metadata).then(function (response) {
          return response;
        });
      },
      deleteItem: function deleteItem() {
        var _this2 = this;

        this.model.item.destroyRecord().then(function () {
          _this2.transitionToRoute('admin.cms.list');

          _this2.flashMessages.info('Item deleted.');
        }).catch(function (error) {
          console.log('Error deleting item.', error);

          _this2.flashMessages.danger('Error deleting item');
        });
      }
    }
  });

  _exports.default = _default;
});