define("sidekick-portal/flash/object", ["exports", "ember-cli-flash/flash/object"], function (_exports, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _object.default;
    }
  });
});