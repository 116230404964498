define("sidekick-portal/components/pins/pin-edit/component", ["exports", "sidekick-portal/models/constants/pins"], function (_exports, _pins) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    pinTypes: _pins.pinTypes,
    store: Ember.inject.service(),
    programTemplates: Ember.computed(function () {
      return this.store.findAll('program-template');
    }),
    selectedProgramTemplate: Ember.computed('pin.extraParam', {
      set: function set(key, value) {
        Ember.set(this.pin, 'extraParam', value.id);
        return this.store.findRecord('program-template', this.pin.extraParam);
      },
      get: function get() {
        if (!Ember.isBlank(this.pin.extraParam)) {
          return this.store.findRecord('program-template', this.pin.extraParam);
        }

        return null;
      }
    }),
    actions: {
      generateUrl: function generateUrl() {
        return this.pin.generateUrl();
      }
    }
  });

  _exports.default = _default;
});