define("sidekick-portal/components/table-components/process-message-profile/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QShJtCZp",
    "block": "{\"symbols\":[],\"statements\":[[7,\"button\",true],[11,\"id\",[28,\"concat\",[\"process-message-\",[24,[\"record\",\"id\"]]],null]],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"process\",[24,[\"record\"]]],null]],[10,\"class\",\"btn btn-sm btn-link\"],[10,\"type\",\"button\"],[8],[0,\"\\n  Process\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sidekick-portal/components/table-components/process-message-profile/template.hbs"
    }
  });

  _exports.default = _default;
});