define("sidekick-portal/components/user-search/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wd9e9K6K",
    "block": "{\"symbols\":[\"user\",\"user\",\"&default\"],\"statements\":[[4,\"if\",[[23,0,[\"multiple\"]]],null,{\"statements\":[[0,\"  \"],[5,\"power-select-multiple\",[],[[\"@placeholder\",\"@search\",\"@searchField\",\"@selected\",\"@searchEnabled\",\"@allowClear\",\"@onChange\"],[\"Search for users by e-mail\",[28,\"action\",[[23,0,[]],\"queryUsers\"],null],\"email\",[23,0,[\"users\"]],\"true\",\"true\",[28,\"action\",[[23,0,[]],[28,\"mut\",[[23,0,[\"users\"]]],null]],null]]],{\"statements\":[[0,\"\\n    \"],[1,[28,\"or\",[[23,2,[\"name\"]],[23,2,[\"displayName\"]]],null],false],[0,\"\\n\"],[4,\"if\",[[23,2,[\"email\"]]],null,{\"statements\":[[0,\"      | \"],[1,[23,2,[\"email\"]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"]],\"parameters\":[2]}],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[5,\"power-select\",[],[[\"@placeholder\",\"@search\",\"@searchEnabled\",\"@selected\",\"@onChange\",\"@allowClear\"],[\"Search for user by name or e-mail\",[28,\"action\",[[23,0,[]],\"queryUsers\"],null],\"true\",[23,0,[\"user\"]],[28,\"action\",[[23,0,[]],\"selectUser\"],null],\"true\"]],{\"statements\":[[0,\"\\n    \"],[1,[28,\"or\",[[23,1,[\"name\"]],[23,1,[\"displayName\"]]],null],false],[0,\"\\n\"],[4,\"if\",[[23,1,[\"email\"]]],null,{\"statements\":[[0,\"      | \"],[1,[23,1,[\"email\"]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"]],\"parameters\":[1]}],[0,\"\\n\"]],\"parameters\":[]}],[14,3],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sidekick-portal/components/user-search/template.hbs"
    }
  });

  _exports.default = _default;
});