define("sidekick-portal/templates/communities/templates/single", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bs2G6Y/R",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"container\"],[8],[0,\"\\n\"],[4,\"link-to\",null,[[\"class\",\"route\"],[\"text-dark\",\"communities.templates.list\"]],{\"statements\":[[0,\"    \"],[7,\"i\",true],[10,\"class\",\"fas fa-arrow-left\"],[8],[9],[0,\" Community Templates\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"container border px-4 mt-3\"],[8],[0,\"\\n  \"],[5,\"community/template-single\",[],[[\"@communityTemplate\",\"@saveTemplate\",\"@saveCommunityTemplatePost\"],[[23,0,[\"model\"]],[28,\"action\",[[23,0,[]],[23,0,[\"saveCommunityTemplate\"]]],null],[28,\"action\",[[23,0,[]],[23,0,[\"saveCommunityTemplatePost\"]]],null]]]],[0,\"\\n  \"],[7,\"hr\",true],[8],[9],[0,\"\\n  \"],[5,\"community/template-posts\",[],[[\"@addScheduledPostToCommunityTemplate\",\"@addOnStartPostToCommunityTemplate\",\"@changeOrderOfPost\",\"@communityTemplate\",\"@saveTemplatePost\",\"@removePost\",\"@setPostDayOfWeekAndSave\"],[[28,\"action\",[[23,0,[]],[23,0,[\"addScheduledPostToCommunityTemplate\"]]],null],[28,\"action\",[[23,0,[]],[23,0,[\"addOnStartPostToCommunityTemplate\"]]],null],[28,\"action\",[[23,0,[]],[23,0,[\"changeOrderOfPost\"]]],null],[23,0,[\"model\"]],[28,\"action\",[[23,0,[]],[23,0,[\"saveCommunityTemplatePost\"]]],null],[28,\"action\",[[23,0,[]],[23,0,[\"deleteCommunityTemplatePost\"]]],null],[28,\"action\",[[23,0,[]],[23,0,[\"setPostDayOfWeekAndSave\"]]],null]]]],[0,\"\\n\"],[9],[0,\"\\n\"],[1,[22,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sidekick-portal/templates/communities/templates/single.hbs"
    }
  });

  _exports.default = _default;
});