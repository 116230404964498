define("sidekick-portal/components/loading-spinner-inline/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'span',
    didInsertElement: function didInsertElement() {
      var _this = this;

      this.set('timedOut', false);
      this.set('taskId', Ember.run.later(function () {
        _this.set('timedOut', true);
      }, 8000));
    },
    willDestroy: function willDestroy() {
      Ember.run.cancel(this.taskId);
    }
  });

  _exports.default = _default;
});