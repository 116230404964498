define("sidekick-portal/models/constants/retention", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.retentionSenderTypes = _exports.retentionMessageProfileTriggers = void 0;
  //Constants.SENDER_COACH == 2 / SENDER_SYSTEM == 3
  //trigger: UserInactivitySeconds
  var retentionSenderTypes = Object.freeze([{
    name: 'Coach',
    id: 2,
    description: 'Send message as coach'
  }, {
    name: 'Sidekick',
    id: 3,
    description: 'Send message as Sidekick'
  }]);
  _exports.retentionSenderTypes = retentionSenderTypes;
  var retentionMessageProfileTriggers = Object.freeze([{
    name: 'User Inactivity',
    id: 'UserInactivitySeconds',
    description: 'How many days of user inactivity should pass before this message is sent?',
    label: 'Days inactive*'
  }, {
    name: 'Days since scale used',
    id: 'UserScaleInactivitySeconds',
    description: 'How many days since the scale was last used should pass before this message is sent?',
    label: 'Days since scale used*'
  }]);
  _exports.retentionMessageProfileTriggers = retentionMessageProfileTriggers;
});