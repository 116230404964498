define("sidekick-portal/templates/content-bundles/single", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GMR2LpFL",
    "block": "{\"symbols\":[],\"statements\":[[1,[22,\"outlet\"],false],[0,\"\\n\\n\"],[5,\"content-bundles/content-bundle-edit\",[],[[\"@bundle\",\"@saveBundle\",\"@removeBundle\",\"@createBundleContentItem\",\"@removeItemContentItem\",\"@addExistingContentItem\",\"@addNewContentItem\",\"@changeOrderOfBundleItem\"],[[23,0,[\"model\"]],[23,0,[\"saveBundle\"]],[23,0,[\"removeBundle\"]],[23,0,[\"createBundleContentItem\"]],[23,0,[\"removeItemContentItem\"]],[23,0,[\"addExistingContentItem\"]],[23,0,[\"addNewContentItem\"]],[23,0,[\"changeOrderOfBundleItem\"]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sidekick-portal/templates/content-bundles/single.hbs"
    }
  });

  _exports.default = _default;
});