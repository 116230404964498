define("sidekick-portal/components/user-program/daily-task/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "aFy06+xH",
    "block": "{\"symbols\":[\"@task\",\"&default\"],\"statements\":[[7,\"div\",true],[11,\"class\",[29,[\"mission-box \",[23,0,[\"category\"]]]]],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"mission-name\"],[8],[0,\"\\n    \"],[1,[23,1,[\"name\"]],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[0,\"  \"],[7,\"div\",true],[10,\"class\",\"progress\"],[8],[0,\"\\n    \"],[7,\"div\",true],[11,\"style\",[28,\"html-safe\",[[28,\"concat\",[\"width: \",[23,0,[\"progress\"]],\"%;\"],null]],null]],[10,\"class\",\"progress-bar\"],[10,\"role\",\"progressbar\"],[11,\"aria-valuenow\",[23,1,[\"currentValue\"]]],[10,\"aria-valuemin\",\"0\"],[11,\"aria-valuemax\",[23,1,[\"targetValue\"]]],[8],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"progress-value\"],[8],[0,\"\\n    \"],[1,[23,1,[\"currentValue\"]],false],[0,\"/\"],[1,[23,1,[\"targetValue\"]],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[14,2],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sidekick-portal/components/user-program/daily-task/template.hbs"
    }
  });

  _exports.default = _default;
});