define("sidekick-portal/components/form/input/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "e4W3O+i3",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"form-group\"],[8],[0,\"\\n  \"],[7,\"label\",true],[11,\"for\",[28,\"concat\",[\"input-\",[23,0,[\"elementId\"]]],null]],[8],[1,[22,\"label\"],false],[9],[0,\"\\n  \"],[5,\"input\",[[12,\"id\",[28,\"concat\",[\"input-\",[23,0,[\"elementId\"]]],null]],[12,\"class\",\"form-control\"],[12,\"aria-describedby\",[28,\"concat\",[[23,0,[\"elementId\"]],\"-help-block\"],null]],[12,\"type\",[28,\"if\",[[23,0,[\"type\"]],[23,0,[\"type\"]],\"text\"],null]]],[[\"@value\"],[[23,0,[\"value\"]]]]],[0,\"\\n\"],[4,\"if\",[[23,0,[\"helperText\"]]],null,{\"statements\":[[0,\"    \"],[7,\"small\",true],[11,\"id\",[28,\"concat\",[[23,0,[\"elementId\"]],\"-help-block\"],null]],[10,\"class\",\"form-text text-muted\"],[8],[0,\"\\n      \"],[1,[23,0,[\"helperText\"]],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[23,0,[\"invalidFeedback\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"invalid-feedback\"],[8],[0,\"\\n      \"],[1,[23,0,[\"invalidFeedback\"]],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sidekick-portal/components/form/input/template.hbs"
    }
  });

  _exports.default = _default;
});