define("sidekick-portal/serializers/group", ["exports", "sidekick-portal/serializers/application", "ember-data"], function (_exports, _application, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend(_emberData.default.EmbeddedRecordsMixin, {
    attrs: {
      owners: 'ownerIds',
      members: 'memberIds',
      partner: 'partnerId',
      parentGroup: 'parentGroupId',
      groupLabels: 'groupLabelIds',
      subGroupLabels: 'subGroupLabelIds',
      program: 'programId',
      coach: 'coachUserId',
      memberCount: {
        serialize: false
      },
      hasProgram: {
        serialize: false
      },
      hasParentGroup: {
        serialize: false
      }
    },
    normalizeSingleResponse: function normalizeSingleResponse(store, primaryModelClass, payload, id, requestType) {
      payload.links = {
        parentGroup: payload.parentGroupId ? "/admin/groups/".concat(payload.parentGroupId) : null,
        partner: payload.partnerId ? "/admin/partners/".concat(payload.partnerId) : null,
        program: payload.programId ? "/admin/programs/".concat(payload.programId) : null
      };
      payload.memberCount = payload.memberIds ? payload.memberIds.length : 0;
      payload.hasProgram = payload.programId ? true : false;
      payload.hasParentGroup = payload.parentGroupId ? true : false;
      return this._super(store, primaryModelClass, payload, id, requestType);
    },
    normalizeArrayResponse: function normalizeArrayResponse(store, primaryModelClass, payload, id, requestType) {
      if (requestType === 'pinurl') {
        //Ember custom actions strangeness - payload normalized as array.
        //Related issue: https://github.com/Exelord/ember-custom-actions/issues/52
        payload = {
          groups: [payload]
        };
      }

      if (payload.groups !== undefined) {
        payload.groups.forEach(function (group) {
          group.links = {
            parentGroup: group.parentGroupId ? "/admin/groups/".concat(group.parentGroupId) : null,
            partner: group.partnerId ? "/admin/partners/".concat(group.partnerId) : null,
            program: group.programId ? "/admin/programs/".concat(group.programId) : null
          };
          group.memberCount = group.memberIds ? group.memberIds.length : 0;
          group.hasProgram = group.programId ? true : false;
          group.hasParentGroup = group.parentGroupId ? true : false;
        });
      }

      return this._super(store, primaryModelClass, payload, id, requestType);
    }
  });

  _exports.default = _default;
});