define("sidekick-portal/serializers/user", ["exports", "sidekick-portal/serializers/application", "ember-data"], function (_exports, _application, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend(_emberData.default.EmbeddedRecordsMixin, {
    extractId: function extractId(modelClass, resourceHash) {
      if (Ember.isBlank(resourceHash['id'])) {
        resourceHash['id'] = resourceHash['userId'];
      }

      return this._super(modelClass, resourceHash);
    },
    normalizeArrayResponse: function normalizeArrayResponse(store, primaryModelClass, payload, id, requestType) {
      if (payload.users !== undefined) {
        payload.users.forEach(function (user) {
          //For async fetching of hasMany relationship not included in the server response.
          user.links = {
            userPrograms: "/admin/users/".concat(user.id, "/programs")
          };
          delete user.kind;
          delete user.etag;
        });
      }

      if (payload.kind) {
        delete payload.kind;
      }

      if (payload.etag) {
        delete payload.etag;
      }

      return this._super(store, primaryModelClass, payload, id, requestType);
    },
    normalizeSingleResponse: function normalizeSingleResponse(store, primaryModelClass, payload, id, requestType) {
      payload.links = {
        userPrograms: "/admin/users/".concat(payload.id, "/programs")
      };
      return this._super(store, primaryModelClass, payload, id, requestType);
    }
  });

  _exports.default = _default;
});