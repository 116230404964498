define("sidekick-portal/models/program", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships", "ember-data-model-fragments", "sidekick-portal/models/constants/locales", "sidekick-portal/models/constants/program"], function (_exports, _model, _attr, _relationships, _emberDataModelFragments, _locales, _program) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    startDate: (0, _attr.default)('moment'),
    //When does the program start, this date determines the timing of the entire program
    subGroupMemberCount: (0, _attr.default)('number'),
    //How many people should at minimum be in auto generated sub groups
    leagueMemberCount: (0, _attr.default)('number'),
    //How many people are in a league competition
    isActive: (0, _attr.default)('boolean'),
    licenseQuantity: (0, _attr.default)('number'),
    expectedUserCount: (0, _attr.default)('number'),
    behaviours: (0, _attr.default)('behaviours', {
      behaviours: _program.programBehaviours
    }),
    template: (0, _relationships.belongsTo)('program-template', {
      inverse: 'programs'
    }),
    //Which program template is applied
    slots: _emberDataModelFragments.default.fragmentArray('program-slot'),
    //hasMany('program-slot', {inverse: 'program'})
    emailReportRecipients: (0, _attr.default)('email-list'),
    locale: (0, _attr.default)('enum', _locales.availableLocales),
    tier: (0, _attr.default)('number'),
    //Deprecated, but keeping in to avoid writing over previous value.
    partnerId: (0, _attr.default)('number'),
    groupId: (0, _attr.default)('number'),
    endDate: Ember.computed('template', {
      get: function get() {
        var startDate = moment.utc(this.startDate);
        return startDate.add(this.get('template.duration'), 'days');
      }
    }),
    startDateText: Ember.computed('startDate', {
      get: function get() {
        return moment.utc(this.startDate).format('LL');
      }
    }),
    startDateForSorting: Ember.computed('startDate', {
      get: function get() {
        return this.startDate.toDate();
      }
    }),
    endDateText: Ember.computed('endDate', {
      get: function get() {
        return moment.utc(this.endDate).format('LL');
      }
    }),
    startDateMilliseconds: Ember.computed('startDate', {
      get: function get() {
        return moment.utc(this.startDate).valueOf();
      }
    }),
    endDateMilliseconds: Ember.computed('template', function () {
      return moment.utc(this.get('slots.lastObject.endDate')).valueOf();
    })
  });

  _exports.default = _default;
});