define("sidekick-portal/components/program-template/edit/slot/reminders/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "uxwvJpKB",
    "block": "{\"symbols\":[\"reminder\",\"@slot\",\"@deleteReminder\",\"&default\",\"@addReminder\",\"@reminders\"],\"statements\":[[14,4],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"w-100\"],[8],[0,\"\\n  \"],[5,\"bs-button\",[],[[\"@type\",\"@onClick\",\"@size\"],[\"primary\",[23,5,[]],\"sm\"]],{\"statements\":[[0,\"\\n    Add reminder\\n  \"]],\"parameters\":[]}],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[4,\"each\",[[23,6,[]]],null,{\"statements\":[[0,\"  \"],[5,\"program-template/edit/slot/reminders/reminder\",[],[[\"@slot\",\"@reminder\",\"@missionsFiltered\",\"@userSettingTypes\",\"@allSurveys\",\"@deleteReminder\"],[[23,2,[]],[23,1,[]],[23,0,[\"missionsFiltered\"]],[23,0,[\"userSettingTypes\"]],[23,0,[\"allSurveys\"]],[23,3,[]]]]],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sidekick-portal/components/program-template/edit/slot/reminders/template.hbs"
    }
  });

  _exports.default = _default;
});