define("sidekick-portal/admin/users/single/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    actions: {
      onUserSelected: function onUserSelected(user) {
        this.transitionToRoute('admin.users.single', user.id);
      },
      refreshRoute: function refreshRoute() {
        console.log('Re-fetching user profile after update.');
        this.model.user.reload();
      }
    }
  });

  _exports.default = _default;
});