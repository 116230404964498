define("sidekick-portal/components/charts/new-users/component", ["exports", "chart.js"], function (_exports, _chart) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    backend: Ember.inject.service(),
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      var periodStart = moment.utc().add(-40, 'days');
      this.backend.getJSONData("admin/kpi/createdusers?periodStart=".concat(periodStart.toISOString())).then(function (response) {
        if (!_this.isDestroyed) {
          var newUsers = response.items.sortBy('date');

          _this.set('newUsers', newUsers);

          _this.drawChart();

          _this.set('dataReady', true);
        }
      });
    },
    newUsersCount: Ember.computed.map('newUsers', function (day) {
      return day.count;
    }),
    chartLabels: Ember.computed.map('newUsers', function (day) {
      return moment.utc(day.date).format('YYYY-MM-DD');
    }),
    drawChart: function drawChart() {
      var _this2 = this;

      if (!document.getElementById('newUsersChart')) {
        console.log('document not ready, need to reschedule.');
        Ember.run.later(function () {
          _this2.drawChart();
        }, 100);
        return;
      }

      var ctx = document.getElementById('newUsersChart').getContext('2d');

      if (this.chart) {
        this.chart.destroy();
      }

      var data = {
        labels: this.chartLabels,
        datasets: [{
          data: this.newUsersCount,
          fill: true,
          backgroundColor: 'rgba(255, 120, 0, 0.2)',
          lineTension: 0,
          borderColor: 'rgba(255, 120, 0, 0.7)' // borderCapStyle: 'butt',
          // borderDash: [],
          // borderDashOffset: 0.0,
          // borderJoinStyle: 'miter',
          // pointBorderColor: "rgba(182, 88, 249, 1)",
          // pointBackgroundColor: "#fff",
          // pointBorderWidth: 1,
          // pointHoverRadius: 4,
          // pointHoverBackgroundColor: "rgba(182, 88, 249, 1)",
          // pointHoverBorderColor: "rgba(182, 88, 249, 1)",
          // pointHoverBorderWidth: 2,
          // pointRadius: 2,
          // pointHitRadius: 5,
          // spanGaps: true,

        }]
      };
      var options = {
        // maintainAspectRatio: true,
        // responsive: true,
        title: {
          // text: 'New users',
          display: false,
          position: 'top'
        },
        legend: {
          display: false
        },
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true
            },
            gridLines: {
              display: true,
              drawBorder: true,
              drawOnChartArea: true,
              drawTicks: true
            }
          }],
          xAxes: [{
            barThickness: 15,
            ticks: {
              autoSkip: false
            },
            gridLines: {
              display: true,
              drawBorder: true,
              drawOnChartArea: false
            }
          }]
        },
        tooltips: {
          callbacks: {
            title: function title(tip) {
              return tip[0].xLabel;
            },
            label: function label(tip) {
              return "".concat(tip.yLabel, " users created");
            }
          }
        }
      };
      var chart = new _chart.default(ctx, {
        type: 'line',
        data: data,
        options: options
      });
      this.set('chart', chart);
    },
    drawChartOld: function drawChartOld() {
      var _this3 = this;

      if (!document.getElementById('activeUsersChart')) {
        console.log('document not ready, need to reschedule.');
        Ember.run.later(function () {
          _this3.drawChart();
        }, 100);
        return;
      }

      var ctx = document.getElementById('activeUsersChart').getContext('2d');

      if (this.chart) {
        this.chart.destroy();
      }

      var data = {
        labels: this.chartLabels,
        datasets: [{
          fill: true,
          lineTension: 0,
          backgroundColor: '#0099FF',
          // backgroundColor: "rgba(182, 88, 249, 0.4)",
          borderColor: 'rgba(182, 88, 249, 1)',
          borderCapStyle: 'butt',
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: 'miter',
          pointBorderColor: 'rgba(182, 88, 249, 1)',
          pointBackgroundColor: '#fff',
          pointBorderWidth: 1,
          pointHoverRadius: 4,
          pointHoverBackgroundColor: 'rgba(182, 88, 249, 1)',
          pointHoverBorderColor: 'rgba(182, 88, 249, 1)',
          pointHoverBorderWidth: 2,
          pointRadius: 2,
          pointHitRadius: 5,
          data: this.activeUsersData,
          spanGaps: false
        }]
      };
      var options = {
        maintainAspectRatio: true,
        responsive: true,
        title: {
          text: 'Daily Active Users',
          display: true,
          position: 'top'
        },
        legend: {
          display: false
        },
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true
            },
            gridLines: {
              display: false // drawBorder: true,
              // drawOnChartArea: false,
              // drawTicks: true

            }
          }],
          xAxes: [{
            barThickness: 15,
            ticks: {
              autoSkip: false
            },
            gridLines: {
              display: false // drawBorder: true,
              // drawOnChartArea: false

            }
          }]
        },
        tooltips: {
          callbacks: {
            title: function title(tip) {
              return tip[0].xLabel;
            },
            label: function label(tip) {
              return "".concat(tip.yLabel, " active users");
            }
          }
        }
      };
      var chart = new _chart.default(ctx, {
        type: 'bar',
        data: data,
        options: options
      });
      this.set('chart', chart);
    }
  });

  _exports.default = _default;
});