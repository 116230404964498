define("sidekick-portal/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cA0ItMJ0",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-lg-12\"],[8],[0,\"\\n    \"],[7,\"h1\",true],[8],[0,\"Welcome to Sidekick portal\"],[9],[0,\"\\n    \"],[7,\"p\",true],[8],[0,\"Select an option from the top menu to view/use the different features of the portal.\"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[1,[22,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sidekick-portal/index/template.hbs"
    }
  });

  _exports.default = _default;
});