define("sidekick-portal/components/cms/icon-upload/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZQVbJpSa",
    "block": "{\"symbols\":[],\"statements\":[[4,\"cms/file-upload\",null,[[\"supportedFileTypes\",\"fileSelected\",\"fileContext\",\"inputLabel\",\"mediaType\",\"text\",\"contentItemValue\",\"locale\"],[\".png\",[28,\"action\",[[23,0,[]],\"fileSelected\"],null],\"icon\",\"Active state\",\"image\",\"Select file...\",[24,[\"iconActive\"]],[24,[\"locale\",\"locale\"]]]],{\"statements\":[],\"parameters\":[]},null],[0,\"\\n\"],[4,\"cms/file-upload\",null,[[\"supportedFileTypes\",\"fileSelected\",\"fileContext\",\"inputLabel\",\"mediaType\",\"text\",\"class\",\"contentItemValue\",\"locale\"],[\".png\",[28,\"action\",[[23,0,[]],\"fileSelected\"],null],\"inactiveIcon\",\"Inactive state\",\"image\",\"Select file...\",\"ml-3\",[24,[\"iconInactive\"]],[24,[\"locale\",\"locale\"]]]],{\"statements\":[],\"parameters\":[]},null],[0,\"\\n\"],[5,\"input\",[[12,\"placeholder\",\"Icon name\"],[12,\"class\",\"form-control col-3 mb-2\"]],[[\"@value\"],[[23,0,[\"newIconName\"]]]]],[0,\"\\n\\n\"],[5,\"bs-button\",[[12,\"onClick\",[28,\"action\",[[23,0,[]],\"saveNewIcon\"],null]],[12,\"class\",\"mb-2\"],[12,\"disabled\",[23,0,[\"formInvalid\"]]]],[[],[]],{\"statements\":[[0,\"Upload\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sidekick-portal/components/cms/icon-upload/template.hbs"
    }
  });

  _exports.default = _default;
});