define("sidekick-portal/models/program-template-slot-content", ["exports", "ember-data", "ember-data-model-fragments", "sidekick-portal/models/constants/program-templates"], function (_exports, _emberData, _emberDataModelFragments, _programTemplates) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberDataModelFragments.default.Fragment.extend({
    daysAfterSlotStart: _emberData.default.attr('number'),
    hourOfDay: _emberData.default.attr('number'),
    senderUserId: _emberData.default.attr('number'),
    senderType: _emberData.default.attr('enum', _programTemplates.senderType),
    sendMethod: _emberData.default.attr('enum', _programTemplates.sendMethod),
    contentItemId: _emberData.default.attr('number'),
    contentBundleId: _emberData.default.attr('number'),
    tier: _emberData.default.attr('number'),
    mustFinish: _emberData.default.attr('boolean'),
    contentItem: Ember.computed('contentItemId', {
      get: function get() {
        if (!Ember.isBlank(this.contentItemId)) {
          return this.store.findRecord('content-item', this.contentItemId);
        }

        return null;
      },
      set: function set(key, value) {
        this.set('contentItemId', value.get('id'));
        return value;
      }
    }),
    contentBundle: Ember.computed('contentBundleId', {
      get: function get() {
        if (!Ember.isBlank(this.contentBundleId)) {
          return this.store.findRecord('content-bundle', this.contentBundleId);
        }

        return null;
      },
      set: function set(key, value) {
        this.set('contentBundleId', value.get('id'));
        return value;
      }
    }),
    sender: Ember.computed('senderUserId', {
      get: function get() {
        if (!Ember.isBlank(this.senderUserId)) {
          return this.store.findRecord('user', this.senderUserId);
        }

        return null;
      },
      set: function set(key, value) {
        this.set('senderUserId', value.get('id'));
        return value;
      }
    }),
    daysAfterSlotStartPlusOne: Ember.computed('daysAfterSlotStart', {
      get: function get() {
        return Number.parseInt(this.daysAfterSlotStart) + 1;
      },
      set: function set(key, value) {
        value = Number.parseInt(value);

        if (value > 0) {
          this.set('daysAfterSlotStart', value - 1);
        } else {
          this.set('daysAfterSlotStart', 0);
        }

        return value;
      }
    })
  });

  _exports.default = _default;
});