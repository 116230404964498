define("sidekick-portal/components/table-components/program-template-name/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9uDl4GD8",
    "block": "{\"symbols\":[\"@record\"],\"statements\":[[4,\"link-to\",null,[[\"route\",\"model\"],[\"admin.program-templates.details\",[23,1,[\"template\",\"id\"]]]],{\"statements\":[[0,\"  \"],[1,[23,1,[\"template\",\"name\"]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sidekick-portal/components/table-components/program-template-name/template.hbs"
    }
  });

  _exports.default = _default;
});