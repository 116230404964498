define("sidekick-portal/components/user-refresh-tokens/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    sorting: Object.freeze(['createdDate:desc']),
    tokensSorted: Ember.computed.sort('refreshTokens', 'sorting')
  });

  _exports.default = _default;
});