define("sidekick-portal/helpers/lte", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.lte = lte;

  function lte(params
  /*, hash*/
  ) {
    return params[0] <= params[1];
  }

  var _default = Ember.Helper.helper(lte);

  _exports.default = _default;
});