define("sidekick-portal/models/program-template-slot", ["exports", "ember-data/attr", "ember-data-model-fragments", "sidekick-portal/models/constants/program-templates"], function (_exports, _attr, _emberDataModelFragments, _programTemplates) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberDataModelFragments.default.Fragment.extend({
    slotId: (0, _attr.default)('number'),
    slotNumber: (0, _attr.default)('number'),
    slotName: (0, _attr.default)('string'),
    goals: _emberDataModelFragments.default.fragmentArray('program-template-slot-goal'),
    isDirty: (0, _attr.default)('boolean'),
    foodGoal: (0, _attr.default)('number'),
    //Deprecated, remove after it's been removed from the backend
    moveGoal: (0, _attr.default)('number'),
    //Deprecated, remove after it's been removed from the backend
    mindGoal: (0, _attr.default)('number'),
    //Deprecated, remove after it's been removed from the backend
    behaviours: (0, _attr.default)('behaviours', {
      behaviours: _programTemplates.programTemplateSlotBehaviours
    }),
    contentMessages: _emberDataModelFragments.default.fragmentArray('program-template-slot-content'),
    contentItemId: (0, _attr.default)('number'),
    iconContentItemId: (0, _attr.default)('number'),
    reminders: _emberDataModelFragments.default.fragmentArray('program-template-slot-reminder'),
    paths: _emberDataModelFragments.default.fragmentArray('program-template-slot-path'),
    contentMessagesSorted: Ember.computed('contentMessages.@each.[]', {
      get: function get() {
        return this.contentMessages.sortBy('daysAfterSlotStart', 'hourOfDay');
      },
      set: function set(key, value) {
        this.set('contentMessages', value);
        return value;
      }
    }),
    setGoalValue: function setGoalValue(goalTypeId, valueType, value) {
      if (!Ember.isBlank(goalTypeId) && !Ember.isBlank(valueType)) {
        return this.setGoalByTypeId(goalTypeId, valueType, value);
      }

      return null;
    },
    activitiesGoalMissions: Ember.computed('goals', 'goals.[].goalMissions', function () {
      var activitiesGoal = this.goalByTypeId(_programTemplates.programTemplateSlotGoalTypesIntegers.activities);

      if (Ember.isBlank(activitiesGoal)) {
        return [];
      }

      return activitiesGoal.goalMissions || [];
    }),
    addActivitiesGoalMission: function addActivitiesGoalMission(mission, durationMinutes, count) {
      var activitiesGoal = this.goalByTypeId(_programTemplates.programTemplateSlotGoalTypesIntegers.activities);

      if (!this.goals.includes(activitiesGoal)) {
        this.goals.addObject(activitiesGoal);
      }

      var fragment = this.store.createFragment('program-template-slot-goal-mission', {
        mission: mission,
        durationMinutes: !Ember.isBlank(durationMinutes) ? durationMinutes : null,
        count: !Ember.isBlank(count) ? count : null
      });
      activitiesGoal.goalMissions.addObject(fragment);
      return fragment;
    },
    store: Ember.inject.service(),
    missionsForInstanceCountGoals: Ember.computed('goals', function () {
      var missionCustomIds = [1002, //Weight
      1106, //Meal log
      1001, //Blood pressure
      1020, //Measurements
      1211, //Stairs
      1203 //Fitness test
      ];
      return this.store.peekAll('mission').filter(function (mission) {
        return missionCustomIds.includes(mission.customId);
      });
    }),
    instanceCountGoalMissions: Ember.computed('goals', 'goals.arrayContentDidChange', function () {
      var _this = this;

      var instanceCountGoal = this.goalByTypeId(_programTemplates.programTemplateSlotGoalTypesIntegers.instanceCountMissions);
      var goalMissions = [];
      this.missionsForInstanceCountGoals.forEach(function (mission) {
        var goalMission = instanceCountGoal.goalMissions.find(function (gm) {
          return gm.missionId === parseInt(mission.id);
        });

        if (Ember.isBlank(goalMission)) {
          //If there is not a defined GoalMission for this mission, create a new fragment
          var goalMissionFragment = _this.store.createFragment('program-template-slot-goal-mission', {
            mission: mission,
            mustFinish: false,
            days: '',
            count: ''
          });

          goalMissions.push(goalMissionFragment);
        } else {
          goalMissions.push(goalMission);
        }
      });
      return goalMissions;
    }),
    setInstanceCountGoalMission: function setInstanceCountGoalMission(goalMission) {
      var gmIsEmpty = Ember.isBlank(goalMission.count) && Ember.isBlank(goalMission.days);
      var instanceCountGoal = this.goalByTypeId(_programTemplates.programTemplateSlotGoalTypesIntegers.instanceCountMissions);

      if (!this.goals.includes(instanceCountGoal)) {
        this.goals.addObject(instanceCountGoal);
      }

      var instanceCountGoalMission = instanceCountGoal.goalMissions.find(function (gm) {
        return gm.missionId === goalMission.missionId;
      });

      if (Ember.isBlank(instanceCountGoalMission) && gmIsEmpty) {
        //GoalMission didn't exist and input is empty - do nothing.
        return;
      } else if (!Ember.isBlank(instanceCountGoalMission) && gmIsEmpty) {
        //Remove goalMission
        instanceCountGoal.goalMissions.removeObject(instanceCountGoalMission);
        return;
      } //Create or update goalMission


      if (Ember.isBlank(instanceCountGoalMission)) {
        var newFragment = this.store.createFragment('program-template-slot-goal-mission', {
          mission: goalMission.mission,
          mustFinish: goalMission.mustFinish,
          days: goalMission.days,
          count: goalMission.count
        });
        instanceCountGoal.goalMissions.addObject(newFragment);
        return newFragment;
      } else {
        Ember.set(instanceCountGoalMission, 'mission', goalMission.mission);
        Ember.set(instanceCountGoalMission, 'mustFinish', goalMission.mustFinish);
        Ember.set(instanceCountGoalMission, 'days', goalMission.days);
        Ember.set(instanceCountGoalMission, 'count', goalMission.count);
        return instanceCountGoalMission;
      }
    },
    goalByType: function goalByType(goalTypeName) {
      var goal = this.goals.find(function (x) {
        return x.goalType.name === goalTypeName;
      });

      if (Ember.isBlank(goal)) {
        goal = this.store.createFragment('program-template-slot-goal', {
          goalType: _programTemplates.programTemplateSlotGoalTypes.find(function (x) {
            return x.name === goalTypeName;
          })
        });
      }

      return goal;
    },
    goalByTypeShortName: function goalByTypeShortName(name) {
      return this.goalByTypeId(_programTemplates.programTemplateSlotGoalTypesIntegers[name]);
    },
    goalByTypeId: function goalByTypeId(goalTypeId) {
      var goal = this.goals.find(function (x) {
        return x.goalType.id === goalTypeId;
      });

      if (Ember.isBlank(goal)) {
        goal = this.store.createFragment('program-template-slot-goal', {
          goalType: _programTemplates.programTemplateSlotGoalTypes.find(function (x) {
            return x.id === goalTypeId;
          })
        });
      }

      return goal;
    },
    setGoalByTypeId: function setGoalByTypeId(goalTypeId, valueType, value) {
      var goal = this.goals.find(function (x) {
        return x.goalType.id === goalTypeId;
      });

      if (Ember.isBlank(goal)) {
        goal = this.store.createFragment('program-template-slot-goal', {
          goalType: _programTemplates.programTemplateSlotGoalTypes.find(function (x) {
            return x.id === goalTypeId;
          })
        });
        this.goals.addObject(goal);
      }

      if (!Ember.isBlank(valueType) && !Ember.isBlank(value)) {
        Ember.set(goal, valueType, value);
      }

      return goal;
    },
    contentItem: Ember.computed('contentItemId', {
      get: function get() {
        if (Ember.isBlank(this.contentItemId)) {
          return null;
        }

        return this.store.findRecord('content-item', this.contentItemId);
      },
      set: function set(key, item) {
        if (Ember.isBlank(item)) {
          Ember.set(this, 'contentItemId', null);
        } else {
          Ember.set(this, 'contentItemId', item.id);
        }

        return item;
      }
    }),
    icon: Ember.computed('iconContentItemId', 'slotNumber', {
      get: function get() {
        if (Ember.isEmpty(this.iconContentItemId)) {
          return new Promise(function (resolve) {
            resolve(null);
          });
        }

        return this.store.peekRecord('icon', this.iconContentItemId);
      },
      set: function set(key, item) {
        if (Ember.isEmpty(item)) {
          Ember.set(this, 'iconContentItemId', null);
        } else {
          Ember.set(this, 'iconContentItemId', item.id);
        }

        return item;
      }
    }),
    foodGoalMissions: Ember.computed('goals', 'goals.[].goalMissions', function () {
      var foodGoal = this.goalByTypeId(_programTemplates.programTemplateSlotGoalTypesIntegers.healthyEating);

      if (Ember.isBlank(foodGoal)) {
        return [];
      }

      return foodGoal.goalMissions || [];
    }),
    getFoodGoalMission: function getFoodGoalMission(missionId) {
      var goalMission = this.foodGoalMissions.find(function (x) {
        return x.missionId === missionId;
      });
      return goalMission || null;
    },
    setFoodGoalMissionValue: function setFoodGoalMissionValue(missionId, valueType, value) {
      var goalMission = this.getFoodGoalMission(missionId);
      var foodGoal = this.goalByTypeId(_programTemplates.programTemplateSlotGoalTypesIntegers.healthyEating);

      if (!this.goals.includes(foodGoal)) {
        this.goals.addObject(foodGoal);
      }

      if (Ember.isBlank(goalMission)) {
        goalMission = this.store.createFragment('program-template-slot-goal-mission', {
          missionId: missionId
        });
        foodGoal.goalMissions.addObject(goalMission);
      }

      goalMission[valueType] = value;
      return value;
    }
  });

  _exports.default = _default;
});