define("sidekick-portal/transforms/behaviours", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Transform.extend({
    deserialize: function deserialize(serialized, options) {
      if (Ember.isEmpty(serialized)) {
        return [];
      }

      var behaviours = serialized || [];

      if (!Ember.isEmpty(options.behaviours)) {
        return behaviours.map(function (x) {
          return options.behaviours.find(function (b) {
            return b.id === x;
          });
        });
      }

      return [];
    },
    serialize: function serialize(deserialized) {
      if (Ember.isEmpty(deserialized)) {
        return null;
      }

      if (Ember.isArray(deserialized)) {
        return deserialized.map(function (x) {
          return x.id;
        });
      }

      return null;
    }
  });

  _exports.default = _default;
});