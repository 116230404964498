define("sidekick-portal/models/community/constants", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.WeekDays = _exports.UserCommunityStatuses = _exports.CommunityTemplateBehaviours = void 0;
  var CommunityTemplateBehaviours = [{
    id: 1,
    name: 'COMMUNITYTEMPLATE_BEHAVIOUR_ONSTART',
    description: 'On Start'
  }, {
    id: 2,
    name: 'COMMUNITYTEMPLATE_BEHAVIOUR_ONSCHEDULE',
    description: 'On Schedule'
  }];
  _exports.CommunityTemplateBehaviours = CommunityTemplateBehaviours;
  var UserCommunityStatuses = [{
    id: 1,
    name: 'USERCOMMUNITY_STATUS_PENDING',
    description: 'Pending'
  }, {
    id: 2,
    name: 'USERCOMMUNITY_STATUS_JOINED',
    description: 'Joined'
  }];
  _exports.UserCommunityStatuses = UserCommunityStatuses;
  var WeekDays = [{
    id: 1,
    name: 'Sunday'
  }, {
    id: 2,
    name: 'Monday'
  }, {
    id: 3,
    name: 'Tuesday'
  }, {
    id: 4,
    name: 'Wednesday'
  }, {
    id: 5,
    name: 'Thursday'
  }, {
    id: 6,
    name: 'Friday'
  }, {
    id: 7,
    name: 'Saturday'
  }];
  _exports.WeekDays = WeekDays;
});