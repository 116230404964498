define("sidekick-portal/components/program-template/content-item/component", ["exports", "sidekick-portal/models/constants/program-templates"], function (_exports, _programTemplates) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    senderType: _programTemplates.senderType,
    sendMethod: _programTemplates.sendMethod,
    contentItemsFiltered: Ember.computed('contentItems', 'slotContentItem.sendMethod', function () {
      if (this.slotContentItem.sendMethod.name === 'PROGRAMTEMPLATESLOT_SEND_METHOD_OPENMISSION') {
        return this.contentItems.filter(function (x) {
          return x.contentType.get('name') === 'open-mission';
        });
      } else if (this.slotContentItem.sendMethod.name === 'PROGRAMTEMPLATESLOT_SEND_METHOD_NONE') {
        return this.contentItems.filter(function (x) {
          return x.contentType.get('name') === 'video';
        });
      } else if (this.slotContentItem.sendMethod.name === 'PROGRAMTEMPLATESLOT_SEND_METHOD_MESSAGE' || this.slotContentItem.sendMethod.name === 'PROGRAMTEMPLATESLOT_SEND_METHOD_EMAIL') {
        return this.contentItems.filter(function (x) {
          return x.contentType.get('name') === 'message';
        });
      } else if (this.slotContentItem.sendMethod.name === 'PROGRAMTEMPLATESLOT_SEND_METHOD_AUDIO_TEXT' || this.slotContentItem.sendMethod.name === 'PROGRAMTEMPLATESLOT_SEND_METHOD_EMAIL') {
        return this.contentItems.filter(function (x) {
          return x.contentType.get('name') === 'audioDeliveredText';
        });
      }

      return null;
    }),
    getBundles: Ember.computed({
      get: function get() {
        return this.store.findAll('content-bundle');
      }
    }),
    isBeingEdited: Ember.computed('slotContentItem.editMode', 'internalEditMode', {
      get: function get() {
        return this.get('slotContentItem.editMode') || this.internalEditMode;
      }
    }),
    powerselectMatcher: function powerselectMatcher(item, term) {
      if (!Ember.isBlank(item.contentTags)) {
        return "".concat(item.name, " ").concat(item.contentTags.join(), " ").toLowerCase().indexOf(term.toLowerCase());
      }

      var itemName = item.name || 'err-invalid-name';
      return itemName.toLowerCase().indexOf(term.toLowerCase());
    },
    actions: {
      selectUser: function selectUser(user) {
        this.set('slotContentItem.sender', user);
      }
    }
  });

  _exports.default = _default;
});