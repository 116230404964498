define("sidekick-portal/templates/surveys/single", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ylBk8sr3",
    "block": "{\"symbols\":[],\"statements\":[[1,[22,\"outlet\"],false],[0,\"\\n\\n\"],[5,\"surveys/survey-edit\",[],[[\"@survey\",\"@saveSurvey\",\"@removeSurvey\",\"@createSurveyContentItem\",\"@addQuestion\",\"@removeQuestion\",\"@createQuestionContentItem\",\"@createUnitContentItem\",\"@changeOrderOfQuestion\",\"@addAnswer\",\"@removeAnswer\",\"@createAnswerContentItem\",\"@changeOrderOfAnswer\",\"@addRating\",\"@removeRating\",\"@createRatingContentItem\",\"@createRatingMessageContentItem\",\"@addResultSetting\",\"@removeResultSetting\",\"@changeOrderOfResultSetting\",\"@generateNewQuestionInfo\",\"@generateNewAnswerInfo\",\"@addAnswerSeverity\",\"@removeAnswerSeverity\",\"@changeOrderOfAnswerSeverity\"],[[23,0,[\"model\"]],[28,\"action\",[[23,0,[]],[23,0,[\"saveSurvey\"]]],null],[23,0,[\"removeSurvey\"]],[23,0,[\"createSurveyContentItem\"]],[23,0,[\"addQuestion\"]],[23,0,[\"removeQuestion\"]],[23,0,[\"createQuestionContentItem\"]],[23,0,[\"createUnitContentItem\"]],[23,0,[\"changeOrderOfQuestion\"]],[23,0,[\"addAnswer\"]],[23,0,[\"removeAnswer\"]],[23,0,[\"createAnswerContentItem\"]],[23,0,[\"changeOrderOfAnswer\"]],[23,0,[\"addRating\"]],[23,0,[\"removeRating\"]],[23,0,[\"createRatingContentItem\"]],[23,0,[\"createRatingMessageContentItem\"]],[23,0,[\"addResultSetting\"]],[23,0,[\"removeResultSetting\"]],[23,0,[\"changeOrderOfResultSetting\"]],[23,0,[\"generateNewQuestionInfo\"]],[23,0,[\"generateNewAnswerInfo\"]],[23,0,[\"addAnswerSeverity\"]],[23,0,[\"removeAnswerSeverity\"]],[23,0,[\"changeOrderOfAnswerSeverity\"]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sidekick-portal/templates/surveys/single.hbs"
    }
  });

  _exports.default = _default;
});