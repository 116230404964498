define("sidekick-portal/components/table-components/remove-user-from-group/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "l8aZsu+A",
    "block": "{\"symbols\":[\"@record\",\"@removeUserFromGroup\"],\"statements\":[[7,\"button\",false],[12,\"class\",\"btn btn-xs btn-danger\"],[12,\"type\",\"button\"],[3,\"action\",[[23,0,[]],[23,2,[]],[23,1,[]]]],[8],[0,\"\\n  Remove from group\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sidekick-portal/components/table-components/remove-user-from-group/template.hbs"
    }
  });

  _exports.default = _default;
});