define("sidekick-portal/components/charts/new-users/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kB8iWePE",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"card\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"card-header\"],[8],[0,\"New users\"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"card-body\"],[8],[0,\"\\n\"],[4,\"unless\",[[24,[\"dataReady\"]]],null,{\"statements\":[[0,\"      Loading KPI\\n\"]],\"parameters\":[]},null],[0,\"    \"],[7,\"canvas\",true],[10,\"id\",\"newUsersChart\"],[10,\"width\",\"400\"],[10,\"height\",\"140\"],[8],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sidekick-portal/components/charts/new-users/template.hbs"
    }
  });

  _exports.default = _default;
});