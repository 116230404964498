define("sidekick-portal/components/cms/icon-library/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6RI3qPto",
    "block": "{\"symbols\":[\"icon\"],\"statements\":[[5,\"cms/icon-upload\",[],[[\"@onIconAdded\"],[[28,\"action\",[[23,0,[]],\"iconAdded\"],null]]]],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"icon-list\"],[8],[0,\"\\n\"],[4,\"each\",[[23,0,[\"iconsSorted\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"icon\"],[8],[0,\"\\n\"],[4,\"if\",[[23,1,[\"waitingForLoad\"]]],null,{\"statements\":[[0,\"        \"],[1,[22,\"loading-spinner-inline\"],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[7,\"img\",true],[11,\"src\",[23,1,[\"activeUrl\"]]],[11,\"alt\",[28,\"concat\",[\"icon-\",[23,1,[\"name\"]]],null]],[8],[9],[0,\"\\n\"],[4,\"if\",[[23,1,[\"inactiveUrl\"]]],null,{\"statements\":[[0,\"          \"],[7,\"img\",true],[11,\"src\",[23,1,[\"inactiveUrl\"]]],[11,\"alt\",[28,\"concat\",[\"iconinactive-\",[23,1,[\"name\"]]],null]],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}],[0,\"      \"],[7,\"div\",true],[10,\"class\",\"icon-name\"],[8],[1,[23,1,[\"name\"]],false],[9],[0,\"\\n\\n      \"],[5,\"bs-button\",[[12,\"onClick\",[28,\"action\",[[23,0,[]],\"deleteIcon\",[23,1,[]]],null]],[12,\"class\",\"delete-button\"]],[[\"@type\"],[\"link\"]],{\"statements\":[[7,\"i\",true],[10,\"class\",\"fas fa-trash-alt\"],[8],[9]],\"parameters\":[]}],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sidekick-portal/components/cms/icon-library/template.hbs"
    }
  });

  _exports.default = _default;
});