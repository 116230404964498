define("sidekick-portal/admin/retention-messages/single/route", ["exports", "sidekick-portal/models/constants/retention"], function (_exports, _retention) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model(params) {
      var messageProfile = null;

      if (params.profile_id === 'new') {
        messageProfile = this.store.createRecord('retention-message-profile', {
          isActive: true,
          senderType: _retention.retentionSenderTypes[0],
          triggerAction: _retention.retentionMessageProfileTriggers[0] //Default to the only available trigger.

        });
      } else {
        messageProfile = this.store.findRecord('retention-message-profile', params.profile_id);
      }

      return Ember.RSVP.hash({
        messageProfile: messageProfile,
        programTemplates: this.store.findAll('program-template'),
        contentItems: this.store.findAll('content-item'),
        contentTypes: this.store.findAll('content-type')
      });
    }
  });

  _exports.default = _default;
});