define("sidekick-portal/models/badge-template", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    colour: _emberData.default.attr('string'),
    badgeType: _emberData.default.attr('number'),
    createdDate: _emberData.default.attr('moment'),
    triggerAction: _emberData.default.attr('number'),
    triggerValue: _emberData.default.attr('string'),
    rewardType: _emberData.default.attr('number'),
    rewardAmount: _emberData.default.attr('number'),
    rewardDistribution: _emberData.default.attr('number'),
    partner: _emberData.default.belongsTo('partner'),
    contents: _emberData.default.hasMany('badge-template-content', {
      reverse: 'badgeTemplate'
    })
  });

  _exports.default = _default;
});