define("sidekick-portal/admin/retention-messages/list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JZBPtveO",
    "block": "{\"symbols\":[],\"statements\":[[1,[22,\"outlet\"],false],[0,\"\\n\\n\"],[5,\"retention/message-profile-list\",[],[[\"@messageProfiles\",\"@tableFilter\"],[[23,0,[\"model\"]],[23,0,[\"tableFilter\"]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sidekick-portal/admin/retention-messages/list/template.hbs"
    }
  });

  _exports.default = _default;
});