define("sidekick-portal/models/content-item-value", ["exports", "ember-data", "ember-data-model-fragments", "ember-data-model-fragments/attributes"], function (_exports, _emberData, _emberDataModelFragments, _attributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* app/models/content-item-value.js */
  var _default = _emberDataModelFragments.default.Fragment.extend({
    name: _emberData.default.attr('string'),
    value: _emberData.default.attr('string'),
    modifiedDate: _emberData.default.attr('string'),
    duration: _emberData.default.attr('number'),
    height: _emberData.default.attr('number'),
    width: _emberData.default.attr('number'),
    order: _emberData.default.attr('number'),
    originalFilename: _emberData.default.attr('string'),
    previewValue: Ember.computed('value', function () {
      if (Ember.isBlank(this.value)) {
        return this.value;
      }

      return this.value.replace('] (', ']\n(');
    }),
    contentType: _attributes.fragmentOwner.contentType
  });

  _exports.default = _default;
});