define("sidekick-portal/components/prize-list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ckpbP39l",
    "block": "{\"symbols\":[\"prize\",\"&default\"],\"statements\":[[4,\"each\",[[24,[\"prizes\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[8],[1,[23,1,[\"name\"]],false],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[14,2],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sidekick-portal/components/prize-list/template.hbs"
    }
  });

  _exports.default = _default;
});