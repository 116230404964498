define("sidekick-portal/models/constants/user-setting-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.DataTypes = void 0;
  var DataTypes = [{
    id: 0,
    name: 'String'
  }, {
    id: 1,
    name: 'Integer'
  }, {
    id: 2,
    name: 'Double'
  }];
  _exports.DataTypes = DataTypes;
});