define("sidekick-portal/components/table-components/multiple-partner-names/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "F95XRThh",
    "block": "{\"symbols\":[\"partner\",\"@record\"],\"statements\":[[4,\"each\",[[23,2,[\"partners\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[8],[4,\"link-to\",null,[[\"route\",\"model\"],[\"admin.partners.single\",[23,1,[\"id\"]]]],{\"statements\":[[1,[23,1,[\"name\"]],false]],\"parameters\":[]},null],[9],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sidekick-portal/components/table-components/multiple-partner-names/template.hbs"
    }
  });

  _exports.default = _default;
});