define("sidekick-portal/components/user-activity/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jV5izgHa",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1],[0,\"\\n\\n\"],[1,[28,\"models-table\",null,[[\"data\",\"columns\",\"filteringIgnoreCase\",\"useFilteringByColumns\",\"multipleColumnsSorting\",\"useNumericPagination\",\"pageSize\",\"class\",\"themeInstance\"],[[24,[\"activitiesCleanedUp\"]],[24,[\"columnsForTable\"]],true,false,false,true,50,\"emtable\",[24,[\"customThemeInstance\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sidekick-portal/components/user-activity/template.hbs"
    }
  });

  _exports.default = _default;
});