define("sidekick-portal/templates/program-catalog-items-v10/single", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tfHC/0Wb",
    "block": "{\"symbols\":[],\"statements\":[[1,[22,\"outlet\"],false],[0,\"\\n\\n\"],[5,\"program-catalog-v10/program-catalog-item/item-edit\",[],[[\"@item\",\"@pins\",\"@saveItem\",\"@removeItem\",\"@createContentItem\",\"@addLocale\",\"@addGroup\",\"@removeLocale\",\"@removeGroup\",\"@programCatalogItems\",\"@clearGoLiveDate\",\"@createInvitationContentItem\"],[[23,0,[\"model\",\"programCatalogItem\"]],[23,0,[\"model\",\"pins\"]],[23,0,[\"saveItem\"]],[23,0,[\"removeItem\"]],[23,0,[\"createContentItem\"]],[23,0,[\"addLocale\"]],[23,0,[\"addGroup\"]],[23,0,[\"removeLocale\"]],[23,0,[\"removeGroup\"]],[23,0,[\"model\",\"programCatalogItems\"]],[22,\"clearGoLiveDate\"],[22,\"createInvitationContentItem\"]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sidekick-portal/templates/program-catalog-items-v10/single.hbs"
    }
  });

  _exports.default = _default;
});