define("sidekick-portal/models/retention-message-profile", ["exports", "ember-data", "ember-custom-actions", "sidekick-portal/models/constants/retention"], function (_exports, _emberData, _emberCustomActions, _retention) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    contentItem: _emberData.default.belongsTo('content-item'),
    programTemplates: _emberData.default.hasMany('program-template'),
    senderType: _emberData.default.attr('enum', _retention.retentionSenderTypes),
    //Constants.SENDER_COACH == 2 / SENDER_SYSTEM == 3
    triggerAction: _emberData.default.attr('enum', _retention.retentionMessageProfileTriggers),
    //retentionMessageProfileTriggers),
    triggerParameter: _emberData.default.attr('string'),
    timeElapsedOfProgramMin: _emberData.default.attr('number'),
    timeElapsedOfProgramMax: _emberData.default.attr('number'),
    createdDate: _emberData.default.attr('date'),
    modifiedDate: _emberData.default.attr('date'),
    isActive: _emberData.default.attr('boolean'),
    tier: _emberData.default.attr('number'),
    process: (0, _emberCustomActions.modelAction)('process', {
      method: 'POST',
      pushToStore: false
    }),
    timeElapsedOfProgramMinDays: Ember.computed('timeElapsedOfProgramMin', {
      get: function get() {
        var secondsElapsed = this.timeElapsedOfProgramMin;

        if (!isNaN(secondsElapsed) && secondsElapsed !== 0) {
          return secondsElapsed / 86400;
        } else if (secondsElapsed === 0) {
          return 0;
        } else {
          return;
        }
      },
      set: function set(key, value) {
        if (!isNaN(value)) {
          this.set('timeElapsedOfProgramMin', value * 86400);
        }

        return value;
      }
    }),
    timeElapsedOfProgramMaxDays: Ember.computed('timeElapsedOfProgramMax', {
      get: function get() {
        var secondsElapsed = this.timeElapsedOfProgramMax;

        if (!isNaN(secondsElapsed) && secondsElapsed !== 0) {
          return secondsElapsed / 86400;
        } else if (secondsElapsed === 0) {
          return 0;
        } else {
          return;
        }
      },
      set: function set(key, value) {
        if (!isNaN(value)) {
          this.set('timeElapsedOfProgramMax', value * 86400);
        }

        return value;
      }
    }),
    triggerParameterDays: Ember.computed('triggerParameter', {
      get: function get() {
        var triggerSeconds = this.triggerParameter;

        if (!isNaN(triggerSeconds) && triggerSeconds !== 0) {
          return triggerSeconds / 86400;
        } else if (triggerSeconds === 0) {
          return 0;
        } else {
          return;
        }
      },
      set: function set(key, value) {
        if (!isNaN(value)) {
          this.set('triggerParameter', Math.round(value * 86400));
        }

        return value;
      }
    }),
    isProfileActive: Ember.computed('isActive', {
      get: function get() {
        if (this.isActive) {
          return 'Yes';
        }

        return 'No';
      }
    })
  });

  _exports.default = _default;
});