define("sidekick-portal/admin/missions/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    model: function model() {
      return Ember.RSVP.hash({
        missions: this.store.findAll('mission')
      });
    }
  });

  _exports.default = _default;
});