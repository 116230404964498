define("sidekick-portal/components/button-delete-with-confirm/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7Vh2RFAb",
    "block": "{\"symbols\":[\"@link\",\"@size\",\"&default\"],\"statements\":[[4,\"unless\",[[24,[\"hidden\"]]],null,{\"statements\":[[4,\"if\",[[24,[\"deleting\"]]],null,{\"statements\":[[0,\"    \"],[7,\"span\",true],[8],[0,\"\\n      \"],[1,[22,\"confirmationText\"],false],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"button\",false],[12,\"class\",[29,[\"btn btn-light border \",[28,\"if\",[[28,\"eq\",[[23,2,[]],\"small\"],null],\"btn-sm px-2 py-1\"],null]]]],[12,\"type\",\"button\"],[3,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"deleting\"]]],null],false]],[8],[0,\"\\n      No\\n    \"],[9],[0,\"\\n    \"],[7,\"button\",false],[12,\"class\",[29,[\"btn btn-danger \",[28,\"if\",[[28,\"eq\",[[23,2,[]],\"small\"],null],\"btn-sm px-2 py-1\"],null]]]],[12,\"type\",\"button\"],[3,\"action\",[[23,0,[]],[24,[\"deleteEntity\"]]]],[8],[0,\"\\n      Yes\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"button\",false],[12,\"disabled\",[22,\"disabled\"]],[12,\"class\",[29,[\"btn \",[28,\"if\",[[28,\"eq\",[[23,2,[]],\"small\"],null],\"btn-sm\"],null],\" \",[28,\"if\",[[23,1,[]],\"btn-link text-danger\",\"btn-danger\"],null]]]],[12,\"type\",\"button\"],[3,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"deleting\"]]],null],true]],[8],[0,\"\\n      \"],[14,3],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sidekick-portal/components/button-delete-with-confirm/template.hbs"
    }
  });

  _exports.default = _default;
});