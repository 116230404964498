define("sidekick-portal/components/table-components/comma-separated-array/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/x3B2LQw",
    "block": "{\"symbols\":[\"tag\",\"@record\"],\"statements\":[[4,\"each\",[[23,2,[\"contentTags\"]]],null,{\"statements\":[[0,\"  \"],[7,\"span\",true],[10,\"class\",\"badge badge-pill badge-secondary\"],[8],[1,[23,1,[]],false],[9],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sidekick-portal/components/table-components/comma-separated-array/template.hbs"
    }
  });

  _exports.default = _default;
});