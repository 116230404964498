define("sidekick-portal/models/pin", ["exports", "ember-data", "sidekick-portal/models/constants/pins", "ember-custom-actions", "sidekick-portal/config/environment"], function (_exports, _emberData, _pins, _emberCustomActions, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    code: _emberData.default.attr('string'),
    extraParam: _emberData.default.attr('string'),
    capacity: _emberData.default.attr('number'),
    type: _emberData.default.attr('enum', _pins.pinTypes),
    expiredDate: _emberData.default.attr('date'),
    users: _emberData.default.hasMany('user'),
    //Deprecated
    userCount: _emberData.default.attr('number'),
    voucherBatchId: _emberData.default.attr('string'),
    pinUrl: _emberData.default.attr('string'),
    createdDate: _emberData.default.attr('date'),
    programTemplate: _emberData.default.belongsTo('program-template', {
      inverse: null
    }),
    community: _emberData.default.belongsTo('community.community', {
      inverse: null
    }),
    tier: _emberData.default.attr('number'),
    programCatalogItem: _emberData.default.belongsTo('program-catalog-item-v10', {
      inverse: null
    }),
    //Deprecated but keeping in for backwards compatibility when saving
    partnerIds: _emberData.default.attr(),
    groupIds: _emberData.default.attr(),
    groupId: _emberData.default.attr(),
    communityGroup: Ember.computed('groups', {
      get: function get() {
        return this.groups.firstObject;
      },
      set: function set(key, value) {
        Ember.set(this, 'groups', []);
        this.groups.pushObject(value);
        return this.groups.firstObject;
      }
    }),
    isValid: Ember.computed('code', 'partnerIds', 'type', 'groupIds', 'extraParam', function () {
      var valid = !Ember.isBlank(this.code) && !Ember.isBlank(this.partnerIds) && !Ember.isBlank(this.type);

      if (!valid) {
        return false;
      }

      if (this.type.name === 'PINTYPE_INDIVIDUALPROGRAM') {
        valid = valid && !Ember.isBlank(this.programTemplate);
      } else if (this.type.name === 'PINTYPE_NOFRIENDS') {
        valid = valid && !Ember.isBlank(this.groupIds);
      }

      return valid;
    }),
    getUrl: (0, _emberCustomActions.modelAction)('url', {
      method: 'GET',
      pushToStore: false,
      responseType: 'null'
    }),
    generateUrl: function generateUrl() {
      var _this = this;

      var platform = _environment.default.APP.sidekickPlatform;
      var appId = platform.startsWith('anthem') ? 3 : 1; //Sidekick = 1, Anthem = 3.

      return this.getUrl({
        appId: appId
      }).then(function (response) {
        _this.pinUrl = response.pinUrl;
        return response;
      });
    }
  });
  /*
  
  Pin types:
    Community Pin (UC):
      - code
      - partnerId
      - groupId - main group
      - groupIds - community group.
      - type: no-friends (2)
  
    Individual Program (reviti):
      - code
      - partnerId
      - groupIds - community group.
      - extraParam - program template Id
      - type: individual (4)
  
  
  
  Voucher Partner
  
  */


  _exports.default = _default;
});