define("sidekick-portal/components/user-search-multiple/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bY1e/Zmh",
    "block": "{\"symbols\":[\"user\",\"&default\"],\"statements\":[[5,\"power-select-multiple\",[],[[\"@placeholder\",\"@search\",\"@searchField\",\"@selected\",\"@searchEnabled\",\"@allowClear\",\"@onChange\"],[\"Search for users by e-mail\",[28,\"action\",[[23,0,[]],\"queryUsers\"],null],\"email\",[23,0,[\"users\"]],\"true\",\"true\",[28,\"action\",[[23,0,[]],[28,\"mut\",[[23,0,[\"users\"]]],null]],null]]],{\"statements\":[[0,\"\\n  \"],[1,[23,1,[\"name\"]],false],[0,\" | \"],[1,[23,1,[\"email\"]],false],[0,\"\\n\"]],\"parameters\":[1]}],[0,\"\\n\\n\"],[14,2],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sidekick-portal/components/user-search-multiple/template.hbs"
    }
  });

  _exports.default = _default;
});