define("sidekick-portal/models/constants/content-bundles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.ContentBundleTypes = _exports.ContentBundleOptions = void 0;
  var ContentBundleTypes = Object.freeze([{
    name: 'CONTENTBUNDLE_VALUE_PRESELECTION',
    id: 1,
    description: 'Value Pre-selection'
  }, {
    name: 'CONTENTBUNDLE_INTERACTIVE_IMAGE',
    id: 2,
    description: 'Interactive Image'
  }, {
    name: 'CONTENTBUNDLE_IMAGE_CARD',
    id: 3,
    description: 'Content Card'
  }]);
  _exports.ContentBundleTypes = ContentBundleTypes;
  var ContentBundleOptions = {
    skipNarrowStep: 1
  };
  _exports.ContentBundleOptions = ContentBundleOptions;
});